import React, { useState } from 'react';
import { material } from '../../library/material';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getInviteDetails } from '../../services/OrganisationService';
import AddClinic from './AddClinic';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import moment from 'moment';
import Snackbar from '../toastrmessage/Snackbar';

function AddOrganizationByInviteCode(props) {

    const { register, handleSubmit, reset, control, setValue, watch, getValues, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
        defaultValues: {
            defaultConfig: true
        }
    });
    const [isEnableFormField, setIsEnableFormField] = useState(true);
    const [isNextFlow, setIsNextFlow] = useState({ action: false, orgData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const checkInviteCode = async (event) => {
        let obj = { inviteCode: event.target.value };
        await getInviteDetails(obj)
            .then((res) => {
                setIsEnableFormField(false)
                reset({ email: res.data.email, role: res.data.role })
            }).catch((error) => {
                setIsEnableFormField(true)
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const nextFlow = (formData) => {
        setIsNextFlow({ action: true, orgData: formData })
    };

    return (
        <div className='container-fluid'>
            <form >
                <material.Paper className='p-4 mt-2' elevation={1}>
                    <div className="row">
                        <material.Typography>Organization Details</material.Typography>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.inviteCode?.type === "required"}
                                {...register("inviteCode")}
                                required
                                label="Enter Invite Code"
                                id="inviteCode"
                                variant="standard"
                                type="text"
                                onChange={checkInviteCode}
                                size="small"
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.inviteCode?.type === "required" && 'This field is required'}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.orgName?.type === "required"}
                                    {...register("orgName", { required: true })}
                                    required
                                    margin="dense"
                                    label="Organization Name"
                                    id="orgName"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    disabled={isEnableFormField}
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.orgName?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.acn?.type === "required"}
                                    {...register("acn", { minLength: 9, maxLength: 9 })}
                                    margin="dense"
                                    label="ACN"
                                    id="acn"
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    disabled={isEnableFormField}
                                    fullWidth
                                />
                                {(errors?.acn?.type === "minLength" || errors?.acn?.type === "maxLength") && (
                                    <p className='text-danger'>This field only contain 9 digits</p>
                                )}
                                <p className='form-text text-danger'>{errors.acn?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.abn?.type === "required"}
                                    {...register("abn", { minLength: 11, maxLength: 11 })}
                                    margin="dense"
                                    label="ABN"
                                    id="abn"
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    disabled={isEnableFormField}
                                    fullWidth
                                />
                                {(errors?.abn?.type === "minLength" || errors?.abn?.type === "maxLength") && (
                                    <p className='text-danger'>This field only contain 11 digits</p>
                                )}
                                <p className='form-text text-danger'>{errors.abn?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.registeredOfficeAddress?.type === "required"}
                                    {...register("registeredOfficeAddress")}
                                    margin="dense"
                                    label="Address"
                                    id="registeredOfficeAddress"
                                    variant="standard"
                                    size="small"
                                    type='text'
                                    disabled={isEnableFormField}
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.registeredOfficeAddress?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director1Id?.type === "required"}
                                    {...register("director1Id")}
                                    margin="dense"
                                    label="Director_1 ID"
                                    id="director1Id"
                                    variant="standard"
                                    size="small"
                                    type='text'
                                    disabled={isEnableFormField}
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.director1Id?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director1?.type === "required"}
                                    {...register("director1")}
                                    margin="dense"
                                    label="Director_1 Name"
                                    id="director1"
                                    variant="standard"
                                    size="small"
                                    type="text"
                                    disabled={isEnableFormField}
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.director1?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Controller
                                    name="director1Phone"
                                    control={control}
                                    defaultValue=""
                                    // rules={{ required: true }}
                                    render={({ field }) => (
                                        <PhoneInput
                                            {...field}
                                            // required
                                            placeholder="Director_1 Ph."
                                            defaultCountry="AU"
                                            enableSearch={true}
                                            value={field.value || ""}
                                            international
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director1Mail?.type === "required"}
                                    {...register("director1Mail", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    margin="dense"
                                    label="Director_1 Email"
                                    id="director1Mail"
                                    variant="standard"
                                    size="small"
                                    type="email"
                                    disabled={isEnableFormField}
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.director1Mail?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director2Id?.type === "required"}
                                    margin="dense"
                                    {...register("director2Id")}
                                    label="Director_2 ID"
                                    id="director2Id"
                                    variant="standard"
                                    size="small"
                                    type="text"
                                    disabled={isEnableFormField}
                                    fullWidth
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director2?.type === "required"}
                                    {...register("director2")}
                                    margin="dense"
                                    label="Director_2 Name"
                                    id="director2"
                                    variant="standard"
                                    size="small"
                                    type="text"
                                    disabled={isEnableFormField}
                                    fullWidth
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Controller
                                    name="director2Phone"
                                    control={control}
                                    defaultValue=""
                                    // rules={{ required: true }}
                                    render={({ field }) => (
                                        <PhoneInput
                                            {...field}
                                            required
                                            placeholder="Director_2 Ph."
                                            defaultCountry="AU"
                                            enableSearch={true}
                                            value={field.value || ""}
                                            international
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director2Mail?.type === "required"}
                                    {...register("director2Mail", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    margin="dense"
                                    label="Director_2 Email"
                                    id="director2Mail"
                                    variant="standard"
                                    disabled={isEnableFormField}
                                    size="small"
                                    type="email"
                                    fullWidth
                                />
                                {(errors?.director2Mail?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                                <p className='form-text text-danger'>{errors.director2Mail?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact1Name?.type === "required"}
                                    {...register("contact1Name")}
                                    margin="dense"
                                    label="Contact_1 Name"
                                    id="contact1Name"
                                    variant="standard"
                                    disabled={isEnableFormField}
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.contact1Name?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact1Mail?.type === "required"}
                                    {...register("contact1Mail", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    margin="dense"
                                    label="Contact_1 Email"
                                    id="contact1Mail"
                                    variant="standard"
                                    disabled={isEnableFormField}
                                    size="small"
                                    type="email"
                                    fullWidth
                                />
                                {(errors?.contact1Mail?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                                <p className='form-text text-danger'>{errors.contact1Mail?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact1Address?.type === "required"}
                                    {...register("contact1Address")}
                                    margin="dense"
                                    label="Contact_1 Address"
                                    id="contact1Address"
                                    variant="standard"
                                    disabled={isEnableFormField}
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.contact1Address?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Controller
                                    name="contact1Phone"
                                    control={control}
                                    defaultValue=""
                                    // rules={{ required: true }}
                                    render={({ field }) => (
                                        <PhoneInput
                                            {...field}
                                            required
                                            placeholder="Contact_1 Ph."
                                            defaultCountry="AU"
                                            enableSearch={true}
                                            value={field.value || ""}
                                            international
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact2Name?.type === "required"}
                                    {...register("contact2Name")}
                                    margin="dense"
                                    label="Contact_2 Name"
                                    id="contact2Name"
                                    variant="standard"
                                    disabled={isEnableFormField}
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact2Mail?.type === "required"}
                                    {...register("contact2Mail", {
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    })}
                                    margin="dense"
                                    label="Contact_2 Email"
                                    id="contact2Mail"
                                    variant="standard"
                                    disabled={isEnableFormField}
                                    size="small"
                                    type="email"
                                    fullWidth
                                />
                                {errors?.contact2Mail?.type === "pattern" && (
                                    <p className='text-danger'>This is not a valid email</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact2Address?.type === "required"}
                                    {...register("contact2Address")}
                                    margin="dense"
                                    label="Contact_2 Address"
                                    id="contact2Address"
                                    variant="standard"
                                    disabled={isEnableFormField}
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Controller
                                    name="contact2Phone"
                                    control={control}
                                    defaultValue=""
                                    // rules={{ required: true }}
                                    render={({ field }) => (
                                        <PhoneInput
                                            {...field}
                                            required
                                            placeholder="Contact_2 Ph."
                                            defaultCountry="AU"
                                            enableSearch={true}
                                            value={field.value || ""}
                                            international
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.role?.type === "required"}
                                {...register("role", { required: true })}
                                required
                                margin="dense"
                                label="Role"
                                id="role"
                                variant="standard"
                                size="small"
                                type='text'
                                disabled={isEnableFormField}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.password?.type === "required"}
                                {...register("password", { required: true })}
                                required
                                margin="dense"
                                label="Password"
                                id="password"
                                variant="standard"
                                size="small"
                                type='password'
                                disabled={isEnableFormField}
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.password?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.email?.type === "required"}
                                {...register("email", { required: true })}
                                required
                                margin="dense"
                                label="User Id"
                                id="email"
                                variant="standard"
                                size="small"
                                type='text'
                                disabled={isEnableFormField}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.firstName?.type === "required"}
                                {...register("firstName", { required: true })}
                                required
                                margin="dense"
                                label="First Name"
                                id="firstName"
                                variant="standard"
                                size="small"
                                type='text'
                                disabled={isEnableFormField}
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.firstName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.lastName?.type === "required"}
                                {...register("lastName", { required: true })}
                                required
                                margin="dense"
                                label="Last Name"
                                id="lastName"
                                variant="standard"
                                size="small"
                                type='text'
                                disabled={isEnableFormField}
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.lastName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="dateOfBirth"
                                    control={control}
                                    defaultValue={null}
                                    // rules={{ required: true }}
                                    render={({ field }) => (
                                        <material.DatePicker
                                            label="Date of Birth *"
                                            format="DD-MM-YYYY"
                                            value={field.value || null}
                                            onChange={(newValue) => {
                                                field.onChange(moment(newValue?.$d).format("YYYY-MM-DD"));
                                            }}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    color="success"
                                                />
                                            )}
                                            disableFuture
                                            sx={{ width: '100%' }}
                                            slotProps={{ textField: { variant: 'standard' } }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="medium" onClick={handleSubmit(nextFlow)} disabled={isNextFlow.action}>Next</material.Button>
                                {/* <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(addOrganization)}>Submit</material.Button> */}
                            </span>
                        </div>
                    </div>
                </material.Paper>
                {isNextFlow.action ? (
                    <AddClinic
                        register={register}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        control={control}
                        reset={reset}
                        isNextFlow={isNextFlow}
                        setValue={setValue}
                        watch={watch}
                        getValues={getValues}
                    />
                ) : null}
            </form>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddOrganizationByInviteCode;