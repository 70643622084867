import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { useNavigate } from 'react-router-dom';
import { getGrsByToProposedTreatmentPlan } from '../../../../services/GRSService';
import { approveProposedTreatmentPlan, deleteProposedTreatmentPlan, getProposedTreatmentPlanList } from '../../../../services/TreatmentPlanService';
import Snackbar from '../../../toastrmessage/Snackbar';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { StyledTableCell } from '../../../../shared/TableHeaderStyle';
import moment from 'moment';
import RadarChart from '../GRS/RadarChart';
import { rootApiUrl } from '../../../../environment/Environment';
import { connect } from 'react-redux';
import AddProposedTreatmentPlan from './AddProposedTreatmentPlan';
import DeletePermissionDialog from './DeletePermissionDialog';

const Arrow = ({ height }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ width: '12px', height: `${height}px`, backgroundColor: 'black' }}></div>
        <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderTop: '20px solid black' }}></div>
    </div>
);

function ProposedTreatmentPlan(props) {

    const { userData, clinicData, patientData, patientIds } = props
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const allPatientIds = patientIds.patientIdReducer.patientIds;
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(true);
    const [proposedTreatmentData, setProposedTreatmentData] = useState({})
    const [page, setPage] = useState(0)
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [openDeletePermissionDialog, setOpenDeletePermissionDialog] = useState({ action: false, rowData: null });
    const [getGrsData, setGetGrsData] = useState([]);
    const [openAddProposedTreatmentPlan, setOpenAddProposedTreatmentPlan] = useState({ action: false, patientData: null, rowData: null });
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
        getProposedTreatmentPlansByClinicIdAndOrgId()
    }, []);

    const getProposedTreatmentPlansByClinicIdAndOrgId = (pageSize) => {
        setisLoading(true);
        let payload = {
            patientId: patientData.patientId,
            limit: pageSize ? pageSize.limit : 10,
            page: pageSize ? pageSize.page : 0
        }
        getProposedTreatmentPlanList(payload)
            .then((resp) => {
                setProposedTreatmentData(resp.data.results);
                setPage(Number(resp.data.currentPage));
                setRowsPerPage(resp.data.limit);
                setTotalElements(resp.data.totalItems);
                setisLoading(false);
                // if (Object.keys(res.data.treatmentPlans).length) {
                //     getGrsDataByProposedTreatmentPlan(Object.keys(res.data.treatmentPlans));
                // } else {
                //     setGetGrsData([])
                // }
            }).catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const getGrsDataByProposedTreatmentPlan = (proposedTreatmentPlanId) => {
        getGrsByToProposedTreatmentPlan(proposedTreatmentPlanId[0])
            .then((resp) => {
                setGetGrsData(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };


    const addProposedTreatmentPlan = () => {
        setOpenAddProposedTreatmentPlan({ action: true, patientData: patientData, rowData: null })
    };

    const rowHeight = 300;
    const arrowHeight = getGrsData.length * rowHeight;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getProposedTreatmentPlansByClinicIdAndOrgId(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getProposedTreatmentPlansByClinicIdAndOrgId(payload)
    };

    const ViewTreatmentPlans = (rowData) => {
        setOpenAddProposedTreatmentPlan({ action: true, patientData: patientData, rowData: rowData })
    };

    const approveTreatmentPlan = (rowData) => {
        approveProposedTreatmentPlan(rowData.id)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data,
                })
                getProposedTreatmentPlansByClinicIdAndOrgId();
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const deleteTreatmentPlan = (rowData) => {
        setOpenDeletePermissionDialog({ action: true, rowData: rowData })
    };

    const getDeletePermissionData = (data) => {
        deleteProposedTreatmentPlan(data.id)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data,
                })
                getProposedTreatmentPlansByClinicIdAndOrgId();
                setOpenDeletePermissionDialog({ action: false, rowData: null })
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <span className="float-end">
                        <material.Button variant="contained" sx={{ textTransform: "none", mb: 2, ml: 1 }} size="small" color="primary" onClick={addProposedTreatmentPlan}>
                            Add Proposed Treatment Plan
                        </material.Button>
                    </span>
                </div>
                <div className="col-12">
                    <material.Paper elevation={7} sx={{ width: '100%', overflow: 'hidden', p: 3, }}>
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Plan Id</StyledTableCell>
                                        <StyledTableCell>Plan Name</StyledTableCell>
                                        <StyledTableCell>Notes</StyledTableCell>
                                        <StyledTableCell>Progress</StyledTableCell>
                                        <StyledTableCell>Updated At</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={12}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={12} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {proposedTreatmentData?.length ? proposedTreatmentData?.map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                >
                                                    <material.TableCell>{row.id}</material.TableCell>
                                                    <material.TableCell>{row.planFileName}</material.TableCell>
                                                    <material.TableCell>{(row.notes === "" || row.notes === null) ? "N/A" : row.notes}</material.TableCell>
                                                    <material.TableCell>{row.progress}</material.TableCell>
                                                    <material.TableCell>{row.updatedAt ? moment(row.updatedAt).format("DD-MM-YYYY") : "N/A"}</material.TableCell>
                                                    <material.TableCell sx={{ fontSize: 18 }}>{row.planStatus === "NOT_APPROVED" ? <span className='badge bg-primary'>Not Approved</span> : <span className='badge bg-success'>Approved</span>}</material.TableCell>
                                                    <material.TableCell>
                                                        <span className='d-flex flex-column'>
                                                            <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='info' size="small" startIcon={<material.VisibilityIcon />} onClick={() => ViewTreatmentPlans(row)}>View</material.Button>
                                                            <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='success' size="small" startIcon={<material.DoneIcon />} onClick={() => approveTreatmentPlan(row)} hidden={row.planStatus === "APPROVED"}>Approve</material.Button>
                                                            <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='error' size="small" startIcon={<material.DeleteIcon />} onClick={() => deleteTreatmentPlan(row)} hidden={row.planStatus === "APPROVED"}>Delete</material.Button>
                                                        </span>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={12}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>)}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <span className='mt-2'>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={totalElements}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </span>
                        <div className='mt-5'>
                            {isLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '10%' }}>
                                    <material.CircularProgress color='secondary' />
                                </div>
                            ) : (
                                <>
                                    {getGrsData.length > 0 ? (
                                        <div className="row">
                                            <div className="col-1" style={{ marginTop: '2%', }}>
                                                <Arrow height={arrowHeight} />
                                            </div>
                                            <div className="col-11">
                                                {getGrsData?.map((row, i) => {
                                                    const chartData = [
                                                        row.static,
                                                        row.dynamic,
                                                        row.volume_loss,
                                                        row.sagging,
                                                        row.asymmetry,
                                                        row.imbalance,
                                                        row.loss_Of_Elasticity,
                                                        row.surface_roughness,
                                                        row.dehydration,
                                                    ];
                                                    return (
                                                        <div className='row' key={i}>
                                                            {i === 0 ? null : (
                                                                <div className='row'>
                                                                    <div className='col-12' style={{ width: '100%', height: `2px`, backgroundColor: 'black', marginTop: '-1%' }}></div>
                                                                </div>
                                                            )}

                                                            <div className="col-4 mb-4">
                                                                <material.Paper elevation={7} sx={{ p: 1, mt: 2 }}>
                                                                    <div style={{ marginTop: '8%' }}>
                                                                        <RadarChart data={chartData} />
                                                                    </div>
                                                                    <h4 style={{ textAlign: "center", marginTop: '8%' }}>
                                                                        {moment(row?.createdAt).format("DD-MM-YYYY")}
                                                                    </h4>
                                                                </material.Paper>
                                                            </div>
                                                            <div className='col-7 mb-4'>
                                                                <span>
                                                                    {row?.linkedImageUri !== "" || row?.linkedImageUri !== null ? row?.linkedImageUri?.split(",").map((ele, i) => (
                                                                        <img
                                                                            key={i}
                                                                            src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                                                            height={200} width="35%"
                                                                            style={{ overflow: "hidden", margin: "10px" }}
                                                                        />
                                                                    )) : ""}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : null}
                                </>

                            )}
                        </div>
                    </material.Paper>
                    <Snackbar
                        openSnackBar={openSnackBar}
                        setOpenSnackBar={setOpenSnackBar}
                    />
                </div>
            </div>
            <AddProposedTreatmentPlan
                openAddProposedTreatmentPlan={openAddProposedTreatmentPlan}
                setOpenAddProposedTreatmentPlan={setOpenAddProposedTreatmentPlan}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
                getProposedTreatmentPlansByClinicIdAndOrgId={getProposedTreatmentPlansByClinicIdAndOrgId}
            />
            <DeletePermissionDialog
                getDeletePermissionData={getDeletePermissionData}
                openDeletePermissionDialog={openDeletePermissionDialog}
                setOpenDeletePermissionDialog={setOpenDeletePermissionDialog}
                title="Proposed Treatment Plan"
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    userData: state,
    clinicData: state,
    patientIds: state
});

export default connect(mapStateToProps)(ProposedTreatmentPlan);