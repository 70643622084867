import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../../navbar/Navbar';
import { material } from '../../../library/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { html } from '@codemirror/lang-html';
import CodeMirror from '@uiw/react-codemirror';
import { updateHtmlTemplate } from '../../../services/CampaignService';
import Snackbar from '../../toastrmessage/Snackbar';
import ViewAllPublicImage from './ViewAllPublicImage';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';

function EditTemplate(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const location = useLocation();
    const htmlTemplate = location.state?.templateData;
    const theme = useTheme();
    const navigate = useNavigate();

    const [htmlContent, setHtmlContent] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openPublicImages, setOpenPublicImages] = useState(false);
    const [openSaveAsDialog, setOpenSaveAsDialog] = useState({ action: false, templateData: null });
    const editorRef = useRef(htmlTemplate.emailTemplate);

    useEffect(() => {
        setHtmlContent(htmlTemplate.emailTemplate)
    }, []);

    const goBack = () => {
        navigate("/campaign");
    };

    const updateTemplate = () => {
        const payload = {
            id: htmlTemplate.id,
            emailTemplateName: htmlTemplate.emailTemplateName,
            emailTemplateId: htmlTemplate.emailTemplateId,
            clinicId: htmlTemplate.clinicId,
            orgId: htmlTemplate.orgId,
            emailTemplate: htmlContent
        }
        updateHtmlTemplate(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                navigate("/campaign");
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const openImageFolder = () => {
        setOpenPublicImages(true);
    };

    const saveAsTemplate = () => {
        const templateData = {
            id: htmlTemplate.id,
            emailTemplateId: htmlTemplate.emailTemplateId,
            clinicId: htmlTemplate.clinicId,
            orgId: htmlTemplate.orgId,
            emailTemplate: htmlContent
        }
        setOpenSaveAsDialog({ action: true, templateData: templateData })
    };

    const updateHtmlContent = useCallback(
        debounce((value) => setHtmlContent(value), 300),
        []
    );

    const handleChange = (value) => {
        editorRef.current = value; // Update ref to keep current value
        updateHtmlContent(value);
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className="row">
                <div className="col-6">
                    <material.Typography variant='h6'> Template Name : {htmlTemplate.emailTemplateName}</material.Typography>
                </div>
                <div className="col-6">
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={openImageFolder}>Open Image Folder</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                    </span>
                </div>
                <div className="col-12 mt-2">
                    <material.Paper elevation={3} sx={{ p: 1, pb: 6 }}>
                        <PanelGroup direction="horizontal" style={{ height: '80vh' }}>
                            <Panel>
                                <div>
                                    <h6>Editor</h6>
                                </div>
                                <div className='mt-3'>
                                    <CodeMirror
                                        value={editorRef.current}
                                        extensions={[html()]}
                                        onChange={handleChange}
                                        height="75vh"
                                        theme="light"
                                        options={{
                                            lineNumbers: true,
                                            lineWrapping: true,
                                            mode: 'html',
                                            autofocus: true,
                                        }}
                                    />
                                </div>
                            </Panel>
                            <PanelResizeHandle />
                            <Panel>
                                <div>
                                    <h6>Preview</h6>
                                    <iframe
                                        title="Rendered HTML"
                                        srcDoc={htmlContent}
                                        style={{ width: '100%', height: '75vh', border: 'none' }}
                                    />
                                </div>
                            </Panel>
                        </PanelGroup>
                        <span className='float-end me-3'>
                            <material.Button variant="outlined" color='success' sx={{ textTransform: "none", mr: 1 }} onClick={saveAsTemplate} startIcon={<material.SaveAsIcon />}>Save As</material.Button>
                            <material.Button variant="outlined" sx={{ textTransform: "none" }} onClick={updateTemplate} startIcon={<material.DoneIcon />}>Update</material.Button>
                        </span>
                    </material.Paper>
                </div>
            </div>
            <ViewAllPublicImage
                openPublicImages={openPublicImages}
                setOpenPublicImages={setOpenPublicImages}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <SaveAsDialog
                openSaveAsDialog={openSaveAsDialog}
                setOpenSaveAsDialog={setOpenSaveAsDialog}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

const SaveAsDialog = (props) => {

    const { openSaveAsDialog, setOpenSaveAsDialog } = props;
    const { register, handleSubmit, reset, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const navigate = useNavigate();
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const cancel = () => {
        setOpenSaveAsDialog({ action: false, templateData: null })
    };

    const saveAsTemplate = (formData) => {
        const payload = {
            ...openSaveAsDialog.templateData,
            emailTemplateName: formData.templateName,
            isNew: true
        }
        updateHtmlTemplate(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                navigate("/campaign");
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div>
            <material.Dialog open={openSaveAsDialog.action} fullWidth maxWidth="sm">
                <material.DialogTitle>Save Template As</material.DialogTitle>
                <material.DialogContent>
                    <material.TextField
                        required
                        margin="dense"
                        id="campaignName"
                        label="Template Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register('templateName', { required: true })}
                    />
                </material.DialogContent>
                <material.DialogActions sx={{ m: 1 }}>
                    <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} onClick={handleSubmit(saveAsTemplate)} startIcon={<material.SaveAsIcon />}>Save As</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default connect(mapStateToProps)(EditTemplate);