import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { getAllPublicImage } from '../../../services/CampaignService';
import Snackbar from '../../toastrmessage/Snackbar';
import { rootApiUrl } from '../../../environment/Environment';

function ViewAllPublicImage(props) {

    const { openPublicImages, setOpenPublicImages, clinicDetails, userDetails } = props;

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [allImages, setAllImages] = useState([]);
    const [selectedImgIndex, setSelectedImgIndex] = useState(null);

    useEffect(() => {
        if (openPublicImages) {
            getAllImage()
        }
    }, [openPublicImages == true]);

    const getAllImage = () => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        getAllPublicImage(payload)
            .then((resp) => {
                setAllImages(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const selectImg = (imageDetails, ind) => {
        setSelectedImgIndex(ind)
        const urlContent = imageDetails.imageTemplateUrl;
        navigator.clipboard
            .writeText(urlContent)
            .then(() => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: "Image URL copied to clipboard!",
                });
            })
            .catch((err) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: `Failed to copy text: ${err}`,
                });
            });
    };

    const close = () => {
        setOpenPublicImages(false);
    };

    return (
        <div>
            <material.Dialog open={openPublicImages} fullWidth maxWidth="lg">
                <material.DialogTitle>All Images
                    <span className='float-end'>
                        <material.IconButton onClick={close} title='Close'>
                            <material.CloseIcon color='error' />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        {allImages.length ? allImages.map((image, k) => (
                            <div className="col-3 mt-2" key={k}>
                                <material.Paper key={k} elevation={3} sx={{ width: "100%", p: 1, boxShadow: selectedImgIndex == k ? "0px 0px 15px 5px rgba(4, 143, 171, 1)" : "" }}>
                                    <img
                                        key={k}
                                        src={`${rootApiUrl}/get-skin-treatment-image?key=${image.imageKey}`}
                                        height={200} width="100%"
                                        style={{ overflow: "hidden", cursor: "pointer" }}
                                        onClick={() => selectImg(image, k)}
                                    />
                                </material.Paper>
                            </div>
                        )) : null}
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ViewAllPublicImage;