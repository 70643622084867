import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { Controller, useForm } from 'react-hook-form';
import { getInjectorListByClinicId, saveSchedule } from '../../../services/AppointmentService';
import Snackbar from '../../toastrmessage/Snackbar';

function AssignOtherInjector(props) {

    const { openAssignOtherInjector, setOpenAssignOtherInjector, getAllScheduleData, setOpenViewAppointment } = props;
    const { register, handleSubmit, getValues, control, watch, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [injectorData, setInjectorData] = useState([]);
    const [injectorName, setInjectorName] = useState([]);
    const [injectorId, setInjectorId] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        if (openAssignOtherInjector.action) {
            getAllInjectorByClinic(openAssignOtherInjector.appointmentData.ClinicId)
        }
    }, [openAssignOtherInjector.action === true]);

    const getAllInjectorByClinic = async (clinicId) => {
        await getInjectorListByClinicId(clinicId)
            .then((resp) => {
                const name = resp.data?.map((ele) => ele.fullName);
                setInjectorName(name)
                setInjectorData(resp.data);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const saveAppointment = (data) => {
        const payload = {
            Id: openAssignOtherInjector.appointmentData.Id,
            InjectorId: injectorId,
            ClinicId: openAssignOtherInjector.appointmentData.ClinicId,
            title: openAssignOtherInjector.appointmentData.title,
            PatientName: openAssignOtherInjector.appointmentData.PatientName,
            Email: openAssignOtherInjector.appointmentData.Email,
            PhoneNumber: openAssignOtherInjector.appointmentData.PhoneNumber,
            start: openAssignOtherInjector.appointmentData.start,
            end: openAssignOtherInjector.appointmentData.end,
            MachineName: openAssignOtherInjector.appointmentData.MachineName,
            MachineNumber: openAssignOtherInjector.appointmentData.MachineNumber,
            MachineId: openAssignOtherInjector.appointmentData.machineId,
            Purpose: openAssignOtherInjector.appointmentData.Purpose,
            RoomName: openAssignOtherInjector.appointmentData.RoomName,
            RoomNumber: openAssignOtherInjector.appointmentData.RoomNumber,
            RoomId: openAssignOtherInjector.appointmentData.roomId,
            startTime: openAssignOtherInjector.appointmentData.startTime,
            duration: openAssignOtherInjector.appointmentData.duration,
            patientId: openAssignOtherInjector.appointmentData.patientId,
            rescheduledCounter: openAssignOtherInjector.appointmentData.rescheduledCounter,
            bookingStatus: openAssignOtherInjector.appointmentData.bookingStatus,
        }
        saveSchedule(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: "Appointment created successfully",
                });
                getAllScheduleData();
                setInjectorId(null);
                reset({ injectorName: "" });
                setOpenAssignOtherInjector({ action: false, appointmentData: null });
                setOpenViewAppointment(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const cancel = () => {
        setInjectorId(null);
        reset({ injectorName: "" });
        setOpenAssignOtherInjector({ action: false, appointmentData: null });
    };

    return (
        <div>
            <material.Dialog open={openAssignOtherInjector.action} fullWidth maxWidth="sm">
                <material.DialogTitle>Injector List</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <Controller
                                name="injectorName"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <material.Autocomplete
                                        {...field}
                                        value={field.value || []}
                                        options={injectorName}
                                        renderInput={(params) => (
                                            <material.TextField
                                                margin="dense"
                                                {...params}
                                                variant="standard"
                                                label="Select Injector"
                                                fullWidth
                                            />
                                        )}
                                        onChange={(_, data) => {
                                            field.onChange(data);
                                            const injectorId = injectorData?.filter((ele) => ele.fullName == data).map((id) => id.injectorId)[0];
                                            setInjectorId(injectorId)
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(saveAppointment)} disabled={!isValid} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </div>
    );
}

export default AssignOtherInjector;