import React, { useEffect, useState } from 'react';
import Snackbar from '../../../toastrmessage/Snackbar';
import { StyledTableCell } from '../../../../shared/TableHeaderStyle';
import { material } from '../../../../library/material';
import { getCurrentOffers } from '../../../../services/ConfigureService';
import AddCurrentOffer from './AddCurrentOffer';

function CurrentOffers(props) {

    const { userDetails, clinicDetails } = props;
    const [openAddCurrentOffer, setOpenAddCurrentOffer] = useState({ action: false, data: null });
    const [getCurrentOfferData, setCurrentOfferData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        getCurrentOffersData()
    }, []);

    const addCurrentOffer = () => {
        setOpenAddCurrentOffer({ action: true, data: null });
    };

    const editCurrentOffer = (rowData) => {
        setOpenAddCurrentOffer({ action: true, data: rowData });
    };

    const getCurrentOffersData = () => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId
        }
        getCurrentOffers(payload)
            .then((resp) => {
                setCurrentOfferData(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    return (
        <div className='row' style={{ marginTop: -35 }}>
            <div className="col-12">
                <span className='float-end'>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addCurrentOffer}>Add Current Offer</material.Button>
                </span>
            </div>
            <div className="col-12 mt-3">
                <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <material.TableContainer>
                        <material.Table>
                            <material.TableHead>
                                <material.TableRow>
                                    <StyledTableCell>Treatment Name</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell sx={{ width: "60%" }}>Description</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {getCurrentOfferData.length ? getCurrentOfferData.map((item, k) => (
                                    <material.TableRow key={k}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                        onClick={() => editCurrentOffer(item)}
                                    >
                                        <material.TableCell>{item.forTreatment}</material.TableCell>
                                        <material.TableCell>{item.offerAmount}</material.TableCell>
                                        <material.TableCell>{item.offerName}</material.TableCell>
                                        <material.TableCell>{<div dangerouslySetInnerHTML={{ __html: item.offerDescription }} />}</material.TableCell>
                                    </material.TableRow>
                                )) : (
                                    <material.TableRow>
                                        <material.TableCell colSpan={10}>
                                            <h6 className="d-flex justify-content-center text-danger fw-bold">
                                                No data found
                                            </h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </material.Grid>
            </div>
            <AddCurrentOffer
                openAddCurrentOffer={openAddCurrentOffer}
                setOpenAddCurrentOffer={setOpenAddCurrentOffer}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getCurrentOffersData={getCurrentOffersData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default CurrentOffers;