import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import Navbar from '../../navbar/Navbar';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getInjectorById, updateInjector } from '../../../services/InjectorService';
import Snackbar from '../../toastrmessage/Snackbar';
import { dateFormat } from '../../../date-and-time-format/DateAndTimeFormat';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function EditInjector(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const userData = location.state ? location.state.userData : "";
    localStorage.setItem("menuName", `Edit Injector Details for ${userData.fullName}`);
    const [dateOfBirth, setDateOfBirth] = useState(dayjs(new Date()));
    const [selectGender, setSelectGender] = useState('');
    const [isDoctor, setIsDoctor] = React.useState("");
    const [phone, setPhone] = useState("");

    const { register, handleSubmit, reset, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getInjectorDetailsByInjectorId()
    }, []);

    const changeGender = (event) => {
        setSelectGender(event.target.value)
    };

    const goBack = () => {
        navigate("/user_management/injector-list")
    };

    const getInjectorDetailsByInjectorId = async () => {
        await getInjectorById(userData.userId)
            .then((resp) => {
                reset(resp.data);
                setSelectGender(resp.data?.gender);
                setDateOfBirth(dayjs(resp.data?.dob));
                setPhone(resp.data?.phone);
                setIsDoctor(resp.data.isDoctor === 1 ? "Yes" : "No");
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const updateUserDetails = (formData) => {
        const payload = {
            userId: userData.userId,
            title: formData.title,
            firstName: formData.firstName,
            lastName: formData.lastName,
            dob: dateFormat(dateOfBirth.$d),
            gender: selectGender,
            AHPRA_Reg_ID: formData.AHPRA_Reg_ID,
            address: formData.address,
            email: formData.email,
            phone: phone,
            isDoctor: isDoctor === "Yes" ? true : false,
            prescriberGovtId: formData.prescriberGovtId,
            orgId: userData.orgId,
            clinicId: userData.clinicId,
        }
        updateInjector(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                navigate("/user_management/injector-list")
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-12'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} className='ms-2' onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                    <material.Paper className='p-4 mt-2' elevation={1}>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.title?.type === "required"}
                                    label="Title"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    {...register("title")}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.firstName?.type === "required"}
                                    required
                                    label="First Name"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    {...register("firstName", { required: true, minLength: 3 })}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <p className='form-text text-danger'>{errors.firstName?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.lastName?.type === "required"}
                                    required
                                    label="Last Name"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    {...register("lastName", { required: true, minLength: 3 })}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <p className='form-text text-danger'>{errors.lastName?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DesktopDatePicker
                                        format='DD-MM-YYYY'
                                        label="Date of Birth"
                                        value={dateOfBirth}
                                        onChange={(newValue) => {
                                            setDateOfBirth(newValue)
                                        }}
                                        sx={{ width: "100%" }}
                                        slotProps={{ textField: { variant: 'standard' } }}
                                    />
                                </LocalizationProvider>
                            </div>
                            {userData?.gender ? (
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.FormControl sx={{ marginTop: 3 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={changeGender}
                                            value={selectGender}
                                        >
                                            <material.FormControlLabel value="MALE" control={<material.Radio color="secondary" />} label="Male" />
                                            <material.FormControlLabel value="FEMALE" control={<material.Radio color="secondary" />} label="Female" />
                                            <material.FormControlLabel value="TRANSGENDER" control={<material.Radio color="secondary" />} label="Other" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                </div>
                            ) : null}
                            {userData.role.includes("INJECTOR") ? (
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        error={errors.AHPRA_Reg_ID?.type === "required"}
                                        label="AHPRA ID"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        // InputProps={{ readOnly: userData.readOnly }}
                                        {...register("AHPRA_Reg_ID")}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <p className='form-text text-danger'>{errors.AHPRA_Reg_ID?.type === "required" && 'This field is required'}</p>
                                </div>
                            ) : null}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.email?.type === "required"}
                                    required
                                    label="Email ID"
                                    id="standard-error"
                                    variant="standard"
                                    type="email"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                    {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                />
                                <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                                {(errors?.email?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                            </div>
                            {userData?.phone ? (
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <PhoneInput
                                        required
                                        placeholder="Phone"
                                        defaultCountry="AU"
                                        inputRef={register}
                                        enableSearch={true}
                                        value={phone}
                                        control={control}
                                        international
                                        rules={{ required: true }}
                                        onChange={(phone) => setPhone(phone)}
                                        error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                    />
                                </div>
                            ) : null}
                            {isDoctor == "Yes" ? (
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        error={errors.prescriberGovtId?.type === "required"}
                                        label="Prescriber Id"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        {...register("prescriberGovtId", { required: true })}
                                    />
                                    <p className='form-text text-danger'>{errors.prescriberGovtId?.type === "required" && 'This field is required'}</p>
                                </div>
                            ) : null}
                            {userData.role.includes("INJECTOR") ? (
                                <div className="col-lg-9 col-md-6 col-sm-12">
                                    <material.TextField
                                        error={errors.address?.type === "required"}
                                        required
                                        label="Address"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        {...register("address", { required: true })}
                                    />
                                    <p className='form-text text-danger'>{errors.address?.type === "required" && 'This field is required'}</p>
                                </div>
                            ) : null}
                            {userData.role.includes("INJECTOR") ? (
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                    <material.FormControl>
                                        <material.FormLabel>Doctor</material.FormLabel>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={(event) => setIsDoctor(event.target.value)}
                                            value={isDoctor}
                                        >
                                            <material.FormControlLabel value={"Yes"} control={<material.Radio color="secondary" />} label="Yes" />
                                            <material.FormControlLabel value={"No"} control={<material.Radio color="secondary" />} label="No" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                </div>
                            ) : null}
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-5'>
                                <span className='float-end'>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(updateUserDetails)} disabled={!isValid}>
                                        Update
                                    </material.Button>
                                </span>
                            </div>
                        </div>
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default EditInjector;