import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getEmployeePayrollHours = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-employee-time-sheets`, { params: { ...payload } }); 
};

export const saveUpdateEmployeePayrollHours = async (payrollHours) => {
    return await axios.post(`${rootApiUrl}/save-update-employee-time-sheets`, payrollHours);
};
export const approveEmployeePayrollHours = async (payrollHours) => {
    return await axios.post(`${rootApiUrl}/approve-employee-time-sheets`, payrollHours);
};