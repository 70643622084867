import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from 'react-hook-form';
import moment from 'moment/moment';
import { dateFormat, localDateTimeFormat } from '../../date-and-time-format/DateAndTimeFormat';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateInjector, updateUser } from '../../services/UserManagementService';
import dayjs from 'dayjs';
import Snackbar from '../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import Navbar from '../navbar/Navbar';

const icon = <material.CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <material.CheckBoxIcon fontSize="small" />;

const roles = [
    { role: "ADMIN", user: "SUPERADMIN" },
    { role: "PRESCRIBER", user: "SUPERADMIN" },
    { role: "MANAGER", user: "ADMIN" },
    { role: "INJECTOR", user: "ADMIN" },
    { role: "THERAPIST", user: "ADMIN" },
];

function EditUserMangement(props) {

    const { userLoginData } = props;
    const userDetails = userLoginData.authReducer.data;
    const [dateOfBirth, setDateOfBirth] = useState(dayjs(new Date()));
    const [date, setDate] = useState(dayjs(new Date()));
    const [selectGender, setSelectGender] = useState('');
    const [isDoctor, setIsDoctor] = React.useState("");
    const [selectRole, setSelectRole] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const location = useLocation();
    const userData = location.state ? location.state.userData : "";
    localStorage.setItem("menuName", "Edit User Management");

    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
        defaultValues: userData
    });
    const navigate = useNavigate();

    useEffect(() => {
        setDateOfBirth(userData.dateOfBirth ? dayjs(userData.dateOfBirth) : dayjs(new Date()));
        setDate(userData.createdAt ? dayjs(userData.createdAt) : dayjs(new Date()));
        setSelectGender(userData.gender ? userData.gender : selectGender);
        setIsDoctor(userData.isDoctor ? userData.isDoctor ? "Yes" : "No" : isDoctor);
        setSelectRole(userData.role)
        if (userDetails.role.includes("SUPERADMIN")) {
            let role = roles.filter((ele) => ele.user === "SUPERADMIN").map((res) => res.role);
            setUserRoles(role)
        } else if (userDetails.role.includes("ADMIN")) {
            let role = roles.filter((ele) => ele.user === "ADMIN").map((res) => res.role);
            setUserRoles(role)
        }
    }, []);

    const goBack = () => {
        navigate("/user_management")
    };

    const changeGender = (event) => {
        setSelectGender(event.target.value)
    };

    const updateUserDetails = async (formData) => {
        let obj = {
            userId: userData.userId,
            title: formData.title,
            firstName: formData.firstName,
            lastName: formData.lastName,
            dob: dateFormat(dateOfBirth.$d),
            gender: selectGender,
            AHPRA_Reg_ID: formData.AHPRA_Reg_ID,
            address: formData.address,
            email: formData.email,
            phone: formData.phone,
            timeStamp: moment(date.$d).format("YYYY-MM-DDTHH:mm:ss"),
            isDoctor: isDoctor === "Yes" ? true : false,
            prescriberGovtId: formData.prescriberGovtId,
            role: selectRole,
            orgId: formData.orgId,
            clinicId: formData.clinicId,
        }
        await updateUser(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data
                })
                navigate("/user_management")
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data
                })
            })
    };

    const handleChangeRoles = (e, value) => {
        setSelectRole(value)
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} className='ms-2' onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>
                <material.Paper className='p-4 mt-2' elevation={1}>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.firstName?.type === "required"}
                                required
                                label="First Name"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: userData.readOnly }}
                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                {...register("firstName", { required: true, minLength: 3 })}
                            // InputProps={{ readOnly: injectorData.action === "view" ? true : false }}
                            />
                            <p className='form-text text-danger'>{errors.firstName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.lastName?.type === "required"}
                                required
                                label="Last Name"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: userData.readOnly }}
                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                {...register("lastName", { required: true, minLength: 3 })}
                            />
                            <p className='form-text text-danger'>{errors.lastName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DesktopDatePicker
                                    format='DD-MM-YYYY'
                                    label="Date of Birth"
                                    value={dateOfBirth}
                                    onChange={(newValue) => {
                                        setDateOfBirth(newValue)
                                    }}
                                    sx={{ width: "100%" }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </div>
                        {userData?.gender ? (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.FormControl sx={{ marginTop: 3 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={changeGender}
                                        value={selectGender}
                                    >
                                        <material.FormControlLabel value="MALE" control={<material.Radio color="secondary" />} label="Male" />
                                        <material.FormControlLabel value="FEMALE" control={<material.Radio color="secondary" />} label="Female" />
                                        <material.FormControlLabel value="TRANSGENDER" control={<material.Radio color="secondary" />} label="Other" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </div>
                        ) : null}
                        {userData.role.includes("INJECTOR") ? (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.AHPRA_Reg_ID?.type === "required"}
                                    // required={userData.role.includes("INJECTOR") ? true : false}
                                    label="AHPRA ID"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: userData.readOnly }}
                                    {...register("AHPRA_Reg_ID")}
                                />
                                <p className='form-text text-danger'>{errors.AHPRA_Reg_ID?.type === "required" && 'This field is required'}</p>
                            </div>
                        ) : null}
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.email?.type === "required"}
                                required
                                label="Email ID"
                                id="standard-error"
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: userData.readOnly }}
                                {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                            />
                            <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                            {(errors?.email?.type === "pattern") && (
                                <p className='text-danger'>This is not a valid Email</p>
                            )}
                        </div>
                        {userData?.phone ? (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.phone?.type === "required"}
                                    required
                                    label="Phone Number"
                                    id="standard-error"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: userData.readOnly }}
                                    {...register("phone", { required: true, minLength: 9 })}
                                />
                                <p className='form-text text-danger'>{errors.phone?.type === "required" && 'This field is required'}</p>
                                {(errors?.phone?.type === "minLength") && (
                                    <p className='text-danger'>This field minimum contain 9 digits</p>
                                )}
                            </div>
                        ) : null}
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DateTimePicker
                                    label="Date Of Entry"
                                    format='DD-MM-YYYY'
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-lg-3">
                            <material.Autocomplete
                                // error={errors.treatmentArea?.type === "required"}
                                id="treatment"
                                onChange={handleChangeRoles}
                                options={userRoles}
                                disableCloseOnSelect
                                multiple
                                getOptionLabel={(option) => option}
                                defaultValue={userData.role}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <material.Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => <material.TextField {...params} required variant="standard" label="Role"
                                    {...register("role")}
                                />}
                            />
                        </div>
                        {isDoctor == "Yes" ? (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.prescriberGovtId?.type === "required"}
                                    label="Prescriber Id"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    required
                                    {...register("prescriberGovtId", { required: true })}
                                />
                                <p className='form-text text-danger'>{errors.prescriberGovtId?.type === "required" && 'This field is required'}</p>
                            </div>
                        ) : null}
                        {userData.role.includes("INJECTOR") ? (
                            <div className="col-lg-9 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.address?.type === "required"}
                                    required
                                    label="Address"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: userData.readOnly }}
                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                    {...register("address", { required: true })}
                                />
                                <p className='form-text text-danger'>{errors.address?.type === "required" && 'This field is required'}</p>
                            </div>
                        ) : null}
                        {userData.role.includes("INJECTOR") ? (
                            <div className="col-lg-3 col-md-3 col-sm-6">
                                <material.FormControl>
                                    <material.FormLabel>Doctor</material.FormLabel>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(event) => setIsDoctor(event.target.value)}
                                        value={isDoctor}
                                    >
                                        <material.FormControlLabel value={"Yes"} control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value={"No"} control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </div>
                        ) : null}
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-5'>
                            <span className='float-end' hidden={userData.readOnly}>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(updateUserDetails)} disabled={!isValid}>
                                    Update
                                </material.Button>
                            </span>
                        </div>
                    </div>
                </material.Paper>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoginData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(EditUserMangement);