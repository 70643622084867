import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { createBreak, getInjectorListByClinicId } from '../../../services/AppointmentService';
import Snackbar from '../../toastrmessage/Snackbar';

var injectorId;

function AddBreakHourDialog(props) {

    const { openAddBreakDialog, setOpenAddBreakDialog, userData, clinicData, getAllScheduleData, getAllInjectorByClinicId } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const userDetails = userData.authReducer.data;
    const [startDate, setStartDate] = useState(dayjs(new Date()));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [startTime, setStartTime] = useState(dayjs(new Date()));
    const [selectTimeDuration, setSelectTimeDuration] = useState("");
    const [selectTitle, setSelectTitle] = useState("");
    const { register, handleSubmit, reset, setValue, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [injectorData, setInjectorData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openAddBreakDialog) {
            getAllInjectorByClinic();
            // getMasterDataForScheduler();
        }
    }, [openAddBreakDialog === true]);

    const getAllInjectorByClinic = async () => {
        await getInjectorListByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                // allInjectorDetails = resp.data;
                // let injector = resp.data.map((ele) => ele.name)
                setInjectorData(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const handleChangeTimeDuration = (event) => {
        if (event.target.value === "Full Day") {
            setStartTime(dayjs("2022-04-17T09:00"));
            setValue("EndTime", "17:00");
        } else {
            const timeArray = event.target.value.split(" ");
            const hoursIndex = timeArray.indexOf("hour");
            const minutesIndex = timeArray.indexOf("min");
            let hours;
            let minutes;
            if (hoursIndex > 0 && minutesIndex < 0) {
                hours = Number(timeArray[hoursIndex - 1]);
                const time = hours * 60
                let getTimeDuration = calculateNextTime(moment(startTime.$d).format("HH:mm"), time);
                setValue("EndTime", getTimeDuration)
            } else if (minutesIndex > 0 && hoursIndex < 0) {
                minutes = Number(timeArray[minutesIndex - 1]);
                const time = minutes
                let getTimeDuration = calculateNextTime(moment(startTime.$d).format("HH:mm"), time);
                setValue("EndTime", getTimeDuration)
            } else if (hoursIndex > 0 && minutesIndex > 0) {
                hours = Number(timeArray[hoursIndex - 1]);
                minutes = Number(timeArray[minutesIndex - 1]);
                const time = hours * 60 + minutes
                let getTimeDuration = calculateNextTime(moment(startTime.$d).format("HH:mm"), time);
                setValue("EndTime", getTimeDuration)
            }
        }
        setSelectTimeDuration(event.target.value)
    };

    const calculateNextTime = (givenTime, duration) => {
        const [givenHours, givenMinutes] = givenTime.split(':').map(Number);
        const givenDate = new Date();
        givenDate.setHours(givenHours);
        givenDate.setMinutes(givenMinutes);
        givenDate.setTime(givenDate.getTime() + duration * 60000);
        const nextTime = `${String(givenDate.getHours()).padStart(2, '0')}:${String(
            givenDate.getMinutes()
        ).padStart(2, '0')}`;
        return moment(nextTime, "HH:mm").format("HH:mm");
    };

    const selectInjector = (e, newValue) => {
        injectorId = newValue.userId
    };

    const addBreak = (data) => {
        if (moment(startDate.$d).format("YYYY-MM-DD") <= moment(endDate.$d).format("YYYY-MM-DD")) {
            let payload = {
                title: selectTitle === "Other" ? data.otherTitle : data.title,
                "InjectorId": injectorId,
                "startDate": moment(startDate.$d).format("YYYY-MM-DD"),
                "endDate": moment(endDate.$d).format("YYYY-MM-DD"),
                "breakStartTime": selectTimeDuration === "Full Day" ? "09:00" : moment(startTime.$d).format("HH:mm"),
                "breakEndTime": data.EndTime,
                "ClinicId": clinicDetails.clinicId,
                "PatientName": "",
                "Email": "",
                "PhoneNumber": ""
            }
            createBreak(payload)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllScheduleData();
                    getAllInjectorByClinicId();
                    setStartDate(dayjs(new Date()));
                    setEndDate(dayjs(new Date()));
                    setStartTime(dayjs(new Date()));
                    setSelectTimeDuration("");
                    setSelectTitle("")
                    reset({ EndTime: "", duration: "" });
                    setOpenAddBreakDialog(false);
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Start date will not be grater than end date",
            })
        }
    };

    const close = () => {
        setStartDate(dayjs(new Date()));
        setEndDate(dayjs(new Date()));
        setStartTime(dayjs(new Date()));
        reset({ EndTime: "", duration: "" });
        setSelectTimeDuration("");
        setSelectTitle("");
        setOpenAddBreakDialog(false);
    };

    const handleChange = (event) => {
        setSelectTitle(event.target.value)
    };

    return (
        <div>
            <material.Dialog open={openAddBreakDialog} hideBackdrop fullWidth maxWidth="sm">
                <material.DialogTitle>Add Break Hour</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                            <div>
                                <material.Autocomplete
                                    id="generatedBy"
                                    margin="dense"
                                    onChange={selectInjector}
                                    options={injectorData}
                                    getOptionLabel={(option) => option.fullName}
                                    renderInput={(params) => <material.TextField {...params} required variant="standard" label="Injector"
                                        {...register("injectorName", { required: true })}
                                    />}
                                />
                            </div>
                        <div className="mt-3">
                            <material.FormControl variant="standard" fullWidth>
                                <material.InputLabel id="demo-simple-select-standard-label">Title</material.InputLabel>
                                <material.Select
                                    {...register("title", { required: true, })}
                                    required
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectTitle}
                                    onChange={handleChange}
                                >
                                    <material.MenuItem value={"Lunch"}>Lunch</material.MenuItem>
                                    <material.MenuItem value={"Cleaning"}>Cleaning</material.MenuItem>
                                    <material.MenuItem value={"Unavailable"}>Unavailable</material.MenuItem>
                                    <material.MenuItem value={"Training"}>Training</material.MenuItem>
                                    <material.MenuItem value={"Other"}>Other</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        {selectTitle === "Other" ? (
                            <div className='mt-3'>
                                <material.TextField
                                    error={errors.otherTitle?.type === "required"}
                                    {...register("otherTitle", { required: true })}
                                    label="Title Free Text"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                        ) : null}
                        <div className="mt-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    label="Start"
                                    format='DD-MM-YYYY'
                                    value={startDate}
                                    disablePast
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    sx={{ width: "100%" }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="mt-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* <material.DatePicker
                                    label="End"
                                    format='DD-MM-YYYY'
                                    value={endDate}
                                    disablePast
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    sx={{ width: "100%" }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                /> */}
                                <Controller
                                    name="endDate"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <material.DatePicker
                                            label="End*"
                                            format='DD-MM-YYYY'
                                            defaultValue={endDate}
                                            onChange={(newValue) => {
                                                setEndDate(newValue);
                                                field.onChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    {...params}
                                                    variant="standard"
                                                    color="info"
                                                    {...field}
                                                />
                                            )}
                                            slotProps={{ textField: { variant: 'standard', color: 'info' } }}
                                            sx={{ width: '100%' }}
                                            disablePast
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="mt-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.TimePicker
                                    label="Start Time"
                                    format='HH:mm'
                                    value={startTime}
                                    onChange={(newValue) => {
                                        setStartTime(newValue);
                                    }}
                                    sx={{ width: "100%" }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='mt-3'>
                            <material.FormControl variant="standard" fullWidth>
                                <material.InputLabel id="demo-simple-select-standard-label" required>Time Duration</material.InputLabel>
                                <material.Select
                                    {...register("duration", { required: true, })}
                                    required
                                    label="Unit"
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    fullWidth
                                    value={selectTimeDuration}
                                    onChange={handleChangeTimeDuration}
                                >
                                    <material.MenuItem value={"15 min"}>15 min</material.MenuItem>
                                    <material.MenuItem value={"30 min"}>30 min</material.MenuItem>
                                    <material.MenuItem value={"45 min"}>45 min</material.MenuItem>
                                    <material.MenuItem value={"1 hour"}>1 hour</material.MenuItem>
                                    <material.MenuItem value={"1 hour 15 min"}>1 hour 15 min</material.MenuItem>
                                    <material.MenuItem value={"1 hour 30 min"}>1 hour 30 min</material.MenuItem>
                                    <material.MenuItem value={"1 hour 45 min"}>1 hour 45 min</material.MenuItem>
                                    <material.MenuItem value={"2 hour"}>2 hour</material.MenuItem>
                                    <material.MenuItem value={"4 hour"}>4 hour</material.MenuItem>
                                    <material.MenuItem value={"6 hour"}>6 hour</material.MenuItem>
                                    <material.MenuItem value={"Full Day"}>Full Day</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className='mt-3'>
                            <material.TextField
                                error={errors.EndTime?.type === "required"}
                                {...register("EndTime", { required: true })}
                                label="End Time"
                                type="text"
                                InputProps={{ readOnly: true }}
                                fullWidth
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} color='error' onClick={close}>Close</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(addBreak)} disabled={!isValid}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddBreakHourDialog);