import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { Controller, useForm } from 'react-hook-form';
import { updatePlanDetails } from '../../../../services/TreatmentPlanService';
import Snackbar from '../../../toastrmessage/Snackbar';

function EditSkinAndCosmeticTreatment(props) {

    const { getProposedTreatmentPlan, openEditSkinAndCosmeticTreatment, setOpenEditSkinAndCosmeticTreatment, treatmentList } = props;
    const { register, handleSubmit, getValues, control, watch, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [treatmentArea, setTreatmentArea] = useState([]);
    const [selectedDays, setSelectedDays] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const days = [...Array(31).keys()].map((day) => (day + 1).toString());
    const selectDateFormat = ['Day', 'Week', "Month", 'Year'];
    const routine = ['Morning (AM Routine)', 'Night (PM Routine)', 'Morning & Night (AM & PM)', 'Morning - Under Sunscreen', 'Night - After Cleansing', 'Weekly (1-2 times per week)', 'As Needed (Spot Treatment or Specific Use)'];

    const onChangeTotalSession = watch("totalNumberOfSessionsRequired");
    const onChangeCostPerSession = watch("costPerSession");

    useEffect(() => {
        if (onChangeTotalSession && onChangeCostPerSession) {
            const totalCost = parseFloat(onChangeTotalSession) * parseFloat(onChangeCostPerSession);
            setValue("totalCost", totalCost);
        }
    }, [onChangeTotalSession, onChangeCostPerSession, setValue]);

    useEffect(() => {
        if (openEditSkinAndCosmeticTreatment.action && openEditSkinAndCosmeticTreatment.rowData !== null && openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home") {
            reset(openEditSkinAndCosmeticTreatment.rowData);
            setValue("routine", openEditSkinAndCosmeticTreatment.rowData.routine);
            setSelectedType(openEditSkinAndCosmeticTreatment.rowData.dateFormat);
            setSelectedDays(openEditSkinAndCosmeticTreatment.rowData.days)
        } else if (openEditSkinAndCosmeticTreatment.action && openEditSkinAndCosmeticTreatment.rowData !== null && (openEditSkinAndCosmeticTreatment.rowData?.recordType === "Cosmetic_Injectable" || openEditSkinAndCosmeticTreatment.rowData?.recordType === "Skin_Treatment")) {
            const treatment = treatmentList?.find(ele => ele.treatmentName === openEditSkinAndCosmeticTreatment.rowData.treatmentOrProductName);
            setTreatmentArea(treatment ? treatment.treatmentArea : []);
            reset(openEditSkinAndCosmeticTreatment.rowData);
            setValue("treatmentAreaOrProductInstructions", openEditSkinAndCosmeticTreatment.rowData.treatmentAreaOrProductInstructions.split(","));
            setSelectedType(openEditSkinAndCosmeticTreatment.rowData.dateFormat);
            setSelectedDays(openEditSkinAndCosmeticTreatment.rowData.days)
        }
    }, [openEditSkinAndCosmeticTreatment.action === true]);

    const updateTreatment = (data) => {
        const payload = {
            id: data.id,
            sessionNumber: data.sessionNumber,
            slNo: data.slNo,
            treatmentOrProductName: data.treatmentOrProductName,
            treatmentAreaOrProductInstructions: openEditSkinAndCosmeticTreatment.rowData.recordType === "At_Home" ? data.treatmentAreaOrProductInstructions : data.treatmentAreaOrProductInstructions?.length > 1 ? data.treatmentAreaOrProductInstructions.join(",") : data.treatmentAreaOrProductInstructions[0],
            totalNumberOfSessionsRequired: data.totalNumberOfSessionsRequired,
            costPerSession: data.costPerSession,
            totalCost: data.totalCost,
            dateFormat: data.dateFormat,
            days: data.days,
            active: true,
            routine: data.routine
        }
        updatePlanDetails(payload)
            .then((resp) => {
                getProposedTreatmentPlan(openEditSkinAndCosmeticTreatment.rowData.proposedPlanId)
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data,
                });
                setOpenEditSkinAndCosmeticTreatment({ action: false, rowData: null });
                reset({ sessionNumber: "", slNo: "", treatmentOrProductName: "", treatmentAreaOrProductInstructions: "", totalNumberOfSessionsRequired: "", costPerSession: "", totalCost: "" });
                setSelectedType("");
                setSelectedDays("");
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const cancel = () => {
        reset({ sessionNumber: "", slNo: "", treatmentOrProductName: "", treatmentAreaOrProductInstructions: "", totalNumberOfSessionsRequired: "", costPerSession: "", totalCost: "" });
        setOpenEditSkinAndCosmeticTreatment({ action: false, rowData: null });
        setSelectedType("");
        setSelectedDays("");
    };

    const handleChangeDay = (event) => {
        setSelectedDays(event.target.value);
    };

    const handleChangeType = (event) => {
        setSelectedType(event.target.value)
    };

    return (
        <div>
            <material.Dialog open={openEditSkinAndCosmeticTreatment.action} fullWidth maxWidth="lg">
                <material.DialogTitle>{openEditSkinAndCosmeticTreatment.rowData?.recordType === "Cosmetic_Injectable" ? "Edit Cosmetic Injectable" : "Skin Treatment"}</material.DialogTitle>
                <material.DialogContent>
                    <div className="row border border-2 p-2 my-2 rounded-2 mt-2" style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                        {openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? null : (
                            <div className="col-lg-2 col-md-4">
                                <material.TextField
                                    {...register("sessionNumber", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label={"Session Number"}
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                        )}
                        <div className="col-lg-2 col-md-4">
                            <material.TextField
                                {...register("slNo", { required: true, pattern: /^[0-9]+$/ })}
                                required
                                margin="dense"
                                id="name"
                                label={openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? "Sequence Number" : "Treatment Number"}
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-2 col-md-4">
                            <material.TextField
                                {...register("treatmentOrProductName")}
                                required
                                margin="dense"
                                id="name"
                                label={openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? "Product Name" : "Treatment Name"}
                                type="text"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                variant="standard"
                            />
                        </div>
                        {openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? (
                            <div className="col-lg-4 col-md-4">
                                <material.TextField
                                    {...register("treatmentAreaOrProductInstructions")}
                                    margin="dense"
                                    id="name"
                                    label="Instruction"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                        ) : null}
                        {openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? (
                            <div className="col-lg-4 col-md-4">
                                <Controller
                                    name={"routine"}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            value={field.value || []}
                                            options={routine}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Routine"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        ) : null}
                        {openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? null : (
                            <div className='col-4'>
                                <Controller
                                    name={"treatmentAreaOrProductInstructions"}
                                    control={control}
                                    defaultValue={[]}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            multiple
                                            disableCloseOnSelect
                                            {...field}
                                            value={field.value || []}
                                            options={treatmentArea}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Treatment Area"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? null : (
                            <div className="col-lg-2 col-md-4">
                                <material.TextField
                                    {...register("totalNumberOfSessionsRequired", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Total Number of Session"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                        )}
                        {openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? null : (
                            <div className="col-lg-4 col-md-4">
                                <material.TextField
                                    {...register("costPerSession", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Cost Per Session"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                        )}
                        <div className="col-lg-4 col-md-4">
                            <material.TextField
                                {...register("totalCost", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,4})?$/ })}
                                required
                                margin="dense"
                                id="name"
                                label="Total Cost"
                                type="number"
                                fullWidth
                                variant="standard"
                                InputProps={{ readOnly: openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? false : true }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className="col-12">
                            <div className="row border border-2 p-2 my-2 rounded-2 mt-2" style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                                <span className='fw-bold'>{openEditSkinAndCosmeticTreatment.rowData?.recordType === "At_Home" ? "Frequency" : "Repeat"}</span>
                                <div className="col-lg-4 col-md-4">
                                    <material.FormControl fullWidth>
                                        <material.Select
                                            {...register("days")}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            variant="standard"
                                            value={selectedDays}
                                            onChange={handleChangeDay}
                                        >
                                            {days.map((day, i) => (
                                                <material.MenuItem value={day} key={i}>{day}</material.MenuItem>
                                            ))}
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <material.FormControl fullWidth>
                                        <material.Select
                                            {...register("dateFormat")}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            variant="standard"
                                            value={selectedType}
                                            onChange={handleChangeType}
                                        >
                                            {selectDateFormat.map((ele, k) => (
                                                <material.MenuItem value={ele} key={k}>{ele}</material.MenuItem>
                                            ))}
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(updateTreatment)} disabled={!isValid} startIcon={<material.DoneIcon />}>Update</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div >
    );
}

export default EditSkinAndCosmeticTreatment;