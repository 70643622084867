import React from 'react';
import { material } from '../../../../library/material';

function DeletePermissionDialog(props) {

    const { openDeletePermissionDialog, setOpenDeletePermissionDialog, getDeletePermissionData, title } = props;

    const deletePlan = (data) => {
        if (data === "yes") {
            getDeletePermissionData(openDeletePermissionDialog.rowData)
        } else {
            setOpenDeletePermissionDialog({ action: false, rowData: null });
        }
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="sm" open={openDeletePermissionDialog.action}>
                <material.DialogContent>
                    <div className='d-flex justify-content-center'>
                        <span className='mt-2'>You want to delete this {title} ?</span>
                        <span className='ms-3'>
                            <material.Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} color='secondary' onClick={() => deletePlan("no")}>No</material.Button>
                            <material.Button variant="outlined" sx={{ textTransform: "none" }} onClick={() => deletePlan("yes")}>Yes</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default DeletePermissionDialog;