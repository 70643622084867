import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import Navbar from '../../navbar/Navbar';
import { material } from '../../../library/material';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AddPackage from './AddPackage';
import { connect } from 'react-redux';
import { getClinicPackage, saveOrUpdatePackage } from '../../../services/PackageConfigurationService';
import Snackbar from '../../toastrmessage/Snackbar';
import DeletePermissionDialog from '../view-patient-details/proposed-treatment/DeletePermissionDialog';
import GenerateInvoice from './invoice/GenerateInvoice';

function PackageList(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    localStorage.setItem("menuName", userDetails.role.includes("ADMIN") || userDetails.role.includes("MANAGER") ? "Package Configuration" : "Package Purchase")
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [openAddPackage, setOPenAddPackage] = useState({ action: false, data: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [packageData, setPackageData] = useState([]);
    const [openDeletePermissionDialog, setOpenDeletePermissionDialog] = useState({ action: false, rowData: null });
    const [openGenerateInvoiceDialog, setOpenGenerateInvoiceDialog] = useState({ action: false, rowData: null });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
        getPackageData()
    }, [openAddPackage.action == false]);

    const getPackageData = (paginationData) => {
        setIsLoading(true)
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            limit: paginationData?.limit ? paginationData.limit : 10,
            page: paginationData?.page ? paginationData.page : 0,
            search: paginationData?.search ? paginationData.search : ""
        }
        getClinicPackage(payload)
            .then((resp) => {
                setPackageData(resp.data.packages);
                setPage(Number(resp.data.currentPage));
                setRowsPerPage(resp.data.limit);
                setTotalElements(resp.data.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const addPackage = () => {
        setOPenAddPackage({ action: true, data: null });
    };

    const ViewTreatmentPackage = (rowData) => {
        setOPenAddPackage({ action: true, data: rowData });
    };

    const deleteTreatmentPackage = (rowData) => {
        setOpenDeletePermissionDialog({ action: true, rowData: rowData })
    };

    const getDeletePermissionData = (data) => {
        const payload = {
            active: false,
            isDeleted: true,
            id: data.id,
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        saveOrUpdatePackage(payload)
            .then((resp) => {
                getPackageData();
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.message,
                });
                setOpenDeletePermissionDialog({ action: false, rowData: null })
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const generateInvoice = (rowData) => {
        setOpenGenerateInvoiceDialog({ action: true, rowData: rowData })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getPackageData(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getPackageData(payload)
    };

    const searchPackageName = (value) => {
        const payload = {
            search: value
        }
        getPackageData(payload)
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className="row">
                <div className="col-6">
                    <span style={{ marginLeft: 5 }}>
                        <material.TextField
                            variant="standard"
                            label="Search Package Name"
                            onChange={(e) => searchPackageName(e.target.value)}
                            sx={{ mt: -2, width:"30ch" }}
                        />
                    </span>
                </div>
                <div className="col-6">
                    <span className="float-end">
                        {userDetails.role.includes("ADMIN") || userDetails.role.includes("MANAGER") ? (
                            <material.Button variant="contained" sx={{ textTransform: "none", mb: 2, ml: 1 }} size="small" color="primary" onClick={addPackage} startIcon={<material.AddIcon />}>
                                Add Package
                            </material.Button>
                        ) : null}
                    </span>
                </div>
                <div className="col-12">
                    <material.Paper elevation={3} sx={{ width: '100%', overflow: 'hidden', p: 3, }}>
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Package Id</StyledTableCell>
                                        <StyledTableCell>Package Name</StyledTableCell>
                                        <StyledTableCell>Package Description</StyledTableCell>
                                        <StyledTableCell>Total Amount</StyledTableCell>
                                        <StyledTableCell>Discount</StyledTableCell>
                                        <StyledTableCell>Total Payable Amount</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={12}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={12} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {packageData?.length ? packageData?.map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                >
                                                    <material.TableCell>{row.id}</material.TableCell>
                                                    <material.TableCell>{row.packageName}</material.TableCell>
                                                    <material.TableCell>{(row.packageDescription === "" || row.packageDescription === null) ? "N/A" : row.packageDescription}</material.TableCell>
                                                    <material.TableCell>{row.total}</material.TableCell>
                                                    <material.TableCell>{row.discount}</material.TableCell>
                                                    <material.TableCell>{row.totalPayableAmount}</material.TableCell>
                                                    <material.TableCell>
                                                        <span className='d-flex flex-column'>
                                                            <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='info' size="small" startIcon={<material.VisibilityIcon />} onClick={() => ViewTreatmentPackage(row)}>View</material.Button>
                                                            <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='secondary' size="small" onClick={() => generateInvoice(row)}>Package Purchase</material.Button>
                                                            {userDetails.role.includes("ADMIN") || userDetails.role.includes("MANAGER") ? (
                                                                <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='error' size="small" startIcon={<material.DeleteIcon />} onClick={() => deleteTreatmentPackage(row)}>Delete</material.Button>
                                                            ) : null}
                                                        </span>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={12}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <span className='mt-2'>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={totalElements}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </span>
                    </material.Paper>
                </div>
            </div>
            <AddPackage
                openAddPackage={openAddPackage}
                setOPenAddPackage={setOPenAddPackage}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getPackageData={getPackageData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeletePermissionDialog
                getDeletePermissionData={getDeletePermissionData}
                openDeletePermissionDialog={openDeletePermissionDialog}
                setOpenDeletePermissionDialog={setOpenDeletePermissionDialog}
                title="Package"
            />
            <GenerateInvoice
                openGenerateInvoiceDialog={openGenerateInvoiceDialog}
                setOpenGenerateInvoiceDialog={setOpenGenerateInvoiceDialog}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(PackageList);