import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import AddProtocol from './AddProtocol';
import { getSOPData } from '../../../../services/ProtocolService';
import { StyledTableCell } from '../../../../shared/TableHeaderStyle';
import Snackbar from '../../../toastrmessage/Snackbar';

function Protocol(props) {

    const { userDetails, clinicDetails } = props;
    const [openAddProtocol, setOpenAddProtocol] = useState({ action: false, data: null });
    const [getProtocolData, setProtocolData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
        getProtocol()
    }, []);

    const addProtocol = () => {
        setOpenAddProtocol({ action: true, data: null })
    };

    const getProtocol = (payloadData) => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId,
            search: payloadData ? payloadData.search : "",
            limit: payloadData ? payloadData.limit : 10,
            page: payloadData ? payloadData.page : 0
        }
        getSOPData(payload)
            .then((resp) => {
                setProtocolData(resp.data.results);
                setPage(Number(resp.data.currentPage));
                setRowsPerPage(resp.data.limit);
                setTotalElements(resp.data.totalItems);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const editProtocol = (rowData) => {
        setOpenAddProtocol({ action: true, data: rowData })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getProtocol(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getProtocol(payload)
    };

    const searchProtocol = (value) => {
        const payload = {
            search: value,
            limit: 10,
            page: 0,
        }
        getProtocol(payload)
    };

    return (
        <div className='row' style={{ marginTop: -35 }}>
            <div className="col-4">
                <span style={{ marginLeft: 5 }}>
                    <material.TextField
                        fullWidth
                        variant="standard"
                        label="Search Protocol"
                        onChange={(e) => searchProtocol(e.target.value)}
                    />
                </span>
            </div>
            <div className="col-8">
                <span className='float-end'>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addProtocol}>Add Protocol</material.Button>
                </span>
            </div>
            <div className="col-12 mt-3">
                <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <material.TableContainer sx={{ maxHeight: "70vh" }}>
                        <material.Table stickyHeader aria-label="sticky table">
                            <material.TableHead>
                                <material.TableRow>
                                    <StyledTableCell>Treatment Name</StyledTableCell>
                                    <StyledTableCell sx={{ width: "80%" }}>Protocol Details</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {getProtocolData.length ? getProtocolData.map((item, k) => (
                                    <material.TableRow key={k}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                        onClick={() => editProtocol(item)}
                                    >
                                        <material.TableCell>{item.forTreatment}</material.TableCell>
                                        <material.TableCell>{<div dangerouslySetInnerHTML={{ __html: item.sopDetails }} />}</material.TableCell>
                                    </material.TableRow>
                                )) : (
                                    <material.TableRow>
                                        <material.TableCell colSpan={10}>
                                            <h6 className="d-flex justify-content-center text-danger fw-bold">
                                                No data found
                                            </h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                    <material.TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </material.Grid>
            </div>
            <AddProtocol
                openAddProtocol={openAddProtocol}
                setOpenAddProtocol={setOpenAddProtocol}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getProtocol={getProtocol}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default Protocol;