import React, { useState, useEffect } from 'react';
import { material } from '../../library/material';
import { connect } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { getAllPAtients } from '../../services/PatientService';
import { getAllClinic } from '../../services/ClinicService';
import { getOtherInHandStocksByClinic } from '../../services/OtherStockService';
import Snackbar from '../toastrmessage/Snackbar';
import { getInvoiceOtherByInvoiceId, generateOtherInvoice } from '../../services/InvoiceManagementService';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { getUsersList } from '../../services/UserManagementService';
import { getPatientCreditList } from '../../services/PatientService';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

var otherInHandStockData = [];
var selectedProductDetails;
var patientDetails;
var clinicAllData;
var clinicId;
var productData = [];
var generatedBy;

function GenerateOtherInvoice(props) {

    const { userData, openGenerateInvoice, setOpenGenerateInvoice, getOtherInvoiceListOther, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [patientName, setPatientName] = useState([]);
    const [clinicName, setClinicName] = useState([]);
    const [newPatient, setNewPatient] = useState(false);
    const [openProduct, setOpenProduct] = useState({ action: false, productData: null });
    const [isDisable, setIsDisable] = useState(false);
    const [deleteCustom, setDeleteCustom] = useState(false);
    const [userList, setSUserList] = useState([]);
    const [isHide, setIsHide] = useState(true);
    const [patientCreditList, setPatientCreditList] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getCreditValue, setCreditValue] = useState(null);
    const { register, handleSubmit, reset, setValue, watch, getValues, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });

    useEffect(() => {
        getPatientByClinicId();
        getAllUserList()
    }, [openGenerateInvoice === true]);

    useEffect(() => {
        setDeleteCustom(false)
        let totalData = productData.map((ele) => ele.amount).reduce((a, b) => Number(a) + Number(b), 0)
        setValue("totalAmount", totalData)
    }, [deleteCustom === true]);

    const onChangeEft = watch('eft');
    const onChangeGiftCard = watch('giftCard');
    const onChangeCash = watch('cash');
    const onChangeTotalAmount = watch('totalAmount');
    const onChangeTotalCreditAmount = watch('patientCredit');

    useEffect(() => {
        totalCalculation();
    }, [onChangeEft, onChangeGiftCard, onChangeCash, onChangeTotalAmount, onChangeTotalCreditAmount]);

    const totalCalculation = () => {
        let eftValue = getValues('eft');
        let giftCardValue = getValues('giftCard');
        let cashValue = getValues('cash');
        let totalAmount = getValues('totalAmount');
        let totalCreditAmount = getValues('patientCredit');
        if (eftValue && giftCardValue && cashValue && !newPatient) {
            let totalValue = Number(totalAmount) - (Number(eftValue) + Number(giftCardValue) + Number(cashValue) + Number(totalCreditAmount));
            console.log(totalValue)
            setValue("remainingAmount", totalValue);
            setCreditValue(totalCreditAmount);
        } else if (eftValue && giftCardValue && cashValue && newPatient) {
            let totalValue = Number(totalAmount) - (Number(eftValue) + Number(giftCardValue) + Number(cashValue));
            console.log(totalValue)
            setValue("remainingAmount", totalValue);
        }
    };

    const getAllUserList = () => {
        getUsersList()
            .then((resp) => {
                const activeUser = resp.data.filter((user) => user.active === true);
                setSUserList(activeUser)
            })
            .catch((error) => {

            })
    };

    const getAllClinicData = async () => {
        await getAllClinic()
            .then((res) => {
                clinicAllData = res.data;
                let clinicName = res.data.map((ele) => ele.clinicName);
                setClinicName(clinicName)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const selectClinic = (e, value) => {
        clinicId = clinicAllData?.filter((resp) => resp.clinicName === value).map((ele) => ele.clinicId)[0];
        localStorage.setItem("clinicId", clinicId)
        setIsHide(false)
        if (!newPatient) {
            getPatientByClinicId()
        };
    };
    const getPatientByClinicId = async () => {
        await getAllPAtients(clinicDetails.clinicId)
            .then((res) => {
                patientDetails = res.data;
                let patientName = res.data.map((ele) => ele.name);
                setPatientName(patientName)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getPatientCreditListData = (patientData) => {
        let payload = {
            patientId: patientData.patientId,
            // onlyNotUsed: false
        }
        getPatientCreditList(payload)
            .then((resp) => {
                if (resp.data.length && resp.data[0].balance < 0) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Customer has an outstanding balance. Please check customer credit.",
                    })
                    setPatientCreditList(null)
                } else {
                    setPatientCreditList(resp.data.length ? resp.data[0].balance : 0)
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const newPatientDetails = () => {
        setNewPatient(true)
        setIsDisable(true)
    };
    const selectPatient = (e, value) => {
        setIsDisable(true)
        let patientData = patientDetails.filter((ele) => ele.name === value);
        reset(patientData[0]);
        getPatientCreditListData(patientData[0])
    };

    const addProduct = () => {
        setOpenProduct({ action: true, productData: clinicId })
    };

    const getProductData = (data) => {
        productData.push(data)
        getTotalAmount(productData)
    };

    const getTotalAmount = (productData) => {
        let totalData = productData.map((ele) => ele.amount).reduce((a, b) => Number(a) + Number(b), 0)
        setValue("totalAmount", totalData)
    };

    const deleteRow = (index) => {
        productData.splice(index, 1)
        setDeleteCustom(true)
    };

    const selectUser = (e, value) => {
        generatedBy = value.userId
    };

    const invoiceGenerate = async (data) => {
        let obj = {
            clinicId: clinicDetails.clinicId,
            patientName: data.patientName,
            patientId: data.patientId,
            patientEmail: data.email,
            injectorId: "",
            generatedBy: generatedBy,
            cash: Number(data.cash),
            eft: Number(data.eft),
            giftCard: Number(data.giftCard),
            products: productData,
            patientCredit: data.patientCredit,
        }
        await generateOtherInvoice(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Invoice Generated Successfully",
                })
                reset({ "product": "", product_quantity: "", clinicName: "", patientName: "", patientId: "", email: "", remainingAmount: "", totalAmount: "", eft: "", cash: "", giftCard: "" });
                setOpenGenerateInvoice(false);
                setNewPatient(false)
                setIsDisable(false)
                productData = [];
                getOtherInvoiceListOther()
                getInvoiceOtherByInvoiceId(resp.data)
                    .then((res) => {
                        const newTab = window.open("", "_blank")
                        newTab.document.write(res.data)
                    })
                    .catch((error) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data,
                        })
                    })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const close = () => {
        reset({ "product": "", product_quantity: "", clinicName: "", patientName: "", patientId: "", email: "", remainingAmount: "", totalAmount: "", eft: "", cash: "", giftCard: "" });
        setOpenGenerateInvoice(false);
        setNewPatient(false);
        productData = [];
        setIsDisable(false);
    };

    return (
        <div>
            <material.Dialog maxWidth="lg" fullWidth hideBackdrop open={openGenerateInvoice}>
                <material.DialogTitle>Generate Invoice</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='d-flex flex-row'>
                            <div>
                                <material.Button onClick={newPatientDetails} variant="contained" sx={{ textTransform: "none" }} disabled={isDisable}>New Patient</material.Button>
                            </div>
                        </div>
                        {newPatient ? (
                            <>
                                <div className='col-4 mt-3'>
                                    <material.TextField
                                        {...register("patientName", { required: true })}
                                        fullWidth
                                        type='text'
                                        label="Patient Name"
                                        variant="standard"
                                    />
                                </div>
                                <div className='col-4 mt-3'>
                                    <material.TextField
                                        {...register("email", { required: true })}
                                        fullWidth
                                        type='email'
                                        label="Email"
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-4 mt-3">
                                    <material.Autocomplete
                                        id="generatedBy"
                                        margin="dense"
                                        onChange={selectUser}
                                        options={userList}
                                        getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                        renderInput={(params) => <material.TextField {...params} required variant="standard" label="Sales Account"
                                            {...register("generatedBy")}
                                        />}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='col-4 mt-3'>
                                    <material.Autocomplete
                                        fullWidth
                                        id='patient'
                                        onChange={selectPatient}
                                        options={patientName}
                                        renderInput={(params) => <material.TextField {...params} variant="standard" label="Select Patient"
                                            {...register("patientName", { required: true })}
                                        />}
                                    />
                                </div>
                                <div className='col-4 mt-3'>
                                    <material.TextField
                                        {...register("email", { required: true })}
                                        fullWidth
                                        type='email'
                                        label="Email"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                {/* <div className='col-4 mt-3'>
                                    <material.TextField
                                        {...register("patientId", { required: true })}
                                        fullWidth
                                        type='text'
                                        label="Patient Id"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div> */}
                                <div className="col-4 mt-3">
                                    <material.Autocomplete
                                        id="generatedBy"
                                        margin="dense"
                                        onChange={selectUser}
                                        options={userList}
                                        getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                        renderInput={(params) => <material.TextField {...params} required variant="standard" label="Sales Account"
                                            {...register("generatedBy")}
                                        />}
                                    />
                                </div>
                            </>
                        )}
                        <div className='col-2 mt-3'>
                            <material.Button onClick={addProduct} variant="contained" sx={{ textTransform: "none" }}>Add Product</material.Button>
                        </div>
                        {productData.length ? (
                            <div className='col-12 mt-3'>
                                <material.TableContainer>
                                    <material.Table>
                                        <material.TableHead >
                                            <material.TableRow>
                                                <StyledTableCell>Product</StyledTableCell>
                                                <StyledTableCell>Available</StyledTableCell>
                                                <StyledTableCell >Per Unit Price</StyledTableCell>
                                                <StyledTableCell>Quantity</StyledTableCell>
                                                <StyledTableCell>Discount</StyledTableCell>
                                                <StyledTableCell>Total Amount</StyledTableCell>
                                                <StyledTableCell>Action</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {productData.map((rowData, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell>{rowData.product}</material.TableCell>
                                                    <material.TableCell>{rowData.inHand}</material.TableCell>
                                                    <material.TableCell>{rowData.perUnitPrice}</material.TableCell>
                                                    <material.TableCell>{rowData.product_quantity}</material.TableCell>
                                                    <material.TableCell>{rowData.discount}</material.TableCell>
                                                    <material.TableCell>{rowData.amount}</material.TableCell>
                                                    <material.TableCell>
                                                        <material.IconButton title='Delete' aria-label="create" size="large" onClick={() => deleteRow(i)}>
                                                            <material.DeleteIcon color='error' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            ))}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                                <div className='row'>
                                    <div className='col-3 mt-3'>
                                        <material.TextField
                                            {...register("giftCard", { required: true })}
                                            required
                                            fullWidth
                                            type='number'
                                            label="Gift Card"
                                            variant="standard"
                                            defaultValue={0}
                                        />
                                    </div>
                                    <div className='col-3 mt-3'>
                                        <material.TextField
                                            {...register("eft", { required: true })}
                                            required
                                            fullWidth
                                            type='number'
                                            label="EFT"
                                            variant="standard"
                                            defaultValue={0}
                                        />
                                    </div>
                                    <div className='col-3 mt-3'>
                                        <material.TextField
                                            {...register("cash", { required: true })}
                                            required
                                            fullWidth
                                            type='number'
                                            label="Cash"
                                            variant="standard"
                                            defaultValue={0}
                                        />
                                    </div>
                                    <div className='col-3 mt-3'>
                                        <material.TextField
                                            {...register("totalAmount", { required: true })}
                                            fullWidth
                                            type='number'
                                            label="Total Amount"
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                            defaultValue={0}
                                        // defaultValue={productData?.map((ele) => ele.amount).reduce((a, b) => Number(a) + Number(b), 0)}
                                        />
                                    </div>
                                    <div className='col-3 mt-3'>
                                        <material.TextField
                                            {...register("remainingAmount", { required: true })}
                                            fullWidth
                                            type='number'
                                            label="Remaining Amount"
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                        />
                                    </div>
                                    {newPatient ? null : (
                                        <div className='col-3 mt-3'>
                                            <material.TextField
                                                {...register("patientCredit", { required: true })}
                                                fullWidth
                                                type='number'
                                                label="Credit"
                                                InputLabelProps={{ shrink: true }}
                                                variant="standard"
                                                defaultValue={0}
                                                disabled={patientCreditList === null}
                                            />
                                            {(getCreditValue > patientCreditList) && (
                                                <h6 className="text-danger">Maximum credit available {patientCreditList}</h6>
                                            )}
                                            <h6>Available Balance : {patientCreditList}</h6>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end me-4 mb-3'>
                        <material.Button onClick={close} variant="contained" color='error' sx={{ textTransform: "none", mr: 2 }}>Close</material.Button>
                        {productData.length ? (
                            <material.Button onClick={handleSubmit(invoiceGenerate)} variant="contained" sx={{ textTransform: "none" }}>Generate Invoice</material.Button>
                        ) : null}
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ProductDialog
                userDetails={userDetails}
                openProduct={openProduct}
                setOpenProduct={setOpenProduct}
                getProductData={getProductData}
                clinicDetails={clinicDetails}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

const ProductDialog = (props) => {
    const { userDetails, setOpenProduct, openProduct, getProductData, clinicDetails } = props;
    const { register, handleSubmit, reset, getValues, setValue, watch, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [productName, setProductName] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const onChangePrice = watch('perUnitPrice');
    const onChangeQuantity = watch('product_quantity');
    const onChangeDiscount = watch('discount');

    useEffect(() => {
        totalCalculation();
    }, [onChangePrice, onChangeQuantity, onChangeDiscount]);

    const totalCalculation = () => {
        let val1 = getValues('perUnitPrice');
        let val2 = getValues('product_quantity');
        let val3 = getValues('discount');
        if (val1 && val2 && val3) {
            let totalValue = (Number(val1) * Number(val2)) - Number(val3);
            setValue("amount", totalValue);
        };
    };

    useEffect(() => {
        if (openProduct.action) {
            getOtherInHandStockData();
            setValue("discount", 0);
        }
    }, [openProduct.action === true]);

    const getOtherInHandStockData = async () => {
        await getOtherInHandStocksByClinic(clinicDetails.clinicId)
            .then((resp) => {
                otherInHandStockData = resp.data.filter((ele) => ele.stockPriceId);
                let productName = otherInHandStockData?.map((ele) => ele.product);
                setProductName(productName)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const selectProducts = (e, value) => {
        selectedProductDetails = otherInHandStockData.filter((ele) => value == ele.product);
        reset(selectedProductDetails[0])
    };

    const saveProduct = (formData) => {
        getProductData(formData)
        setOpenProduct({ action: false, productData: null })
        reset({ perUnitPrice: "", product_quantity: "", discount: "", product: "", inHand: "", amount: "" })
    };

    const close = () => {
        setOpenProduct({ action: false, productData: null })
        reset({ perUnitPrice: "", product_quantity: "", discount: "", product: "", inHand: "", amount: "" })
    }

    return (
        <div>
            <material.Dialog maxWidth="lg" fullWidth hideBackdrop open={openProduct.action}>
                <material.DialogTitle>Product Details</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-4 mt-3'>
                            <material.Autocomplete
                                fullWidth
                                id='patient'
                                onChange={selectProducts}
                                options={productName}
                                renderInput={(params) => <material.TextField required {...params} variant="standard" label="Select Product"
                                    {...register("product", { required: true })}
                                />}
                            />
                        </div>
                        <div className='col-4 mt-3'>
                            <material.TextField
                                {...register("inHand", { required: true })}
                                required
                                fullWidth
                                type='number'
                                label="Available"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                        <div className='col-4 mt-3'>
                            <material.TextField
                                {...register("perUnitPrice", { required: true })}
                                required
                                fullWidth
                                type='number'
                                label="Per Price Unit"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                        <div className='col-4 mt-3'>
                            <material.TextField
                                {...register("product_quantity", { required: true })}
                                required
                                fullWidth
                                type='number'
                                label="Quantity"
                                variant="standard"
                            />
                        </div>
                        <div className='col-4 mt-3'>
                            <material.TextField
                                {...register("discount", { required: true })}
                                required
                                fullWidth
                                type='number'
                                label="Discount"
                                variant="standard"
                                defaultValue={0}
                            />
                        </div>
                        <div className='col-4 mt-3'>
                            <material.TextField
                                {...register("amount", { required: true })}
                                fullWidth
                                type='number'
                                label="Total Amount"
                                variant="standard"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end me-4 mb-3'>
                        <material.Button onClick={close} variant="contained" color='error' sx={{ textTransform: "none", mr: 2 }}>Close</material.Button>
                        <material.Button onClick={handleSubmit(saveProduct)} variant="contained" sx={{ textTransform: "none" }}>Save</material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
        </div>
    )
}

export default connect(mapStateToProps)(GenerateOtherInvoice);