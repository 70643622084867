import React, { useState, useEffect } from 'react';
import { material } from '../../../library/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { getSkinTreatmentPlanForPatient } from '../../../services/SkinTreatmentService';
import { changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../navbar/Navbar';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function PatientTreatmentList(props) {

    const { clinicData } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const location = useLocation();
    const navigate = useNavigate();
    const patientData = location.state ? location.state.patientData : "";
    const [treatmentData, setTreatmentData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    localStorage.setItem("menuName", `Skin Treatment List for ${patientData.name}`)

    useEffect(() => {
        getTreatmentForPatient()
    }, []);

    const getTreatmentForPatient = async () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait fetching data",
        })
        await getSkinTreatmentPlanForPatient(clinicDetails.clinicId, patientData.patientId)
            .then((resp) => {
                setTreatmentData(resp.data);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Fetching completed",
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const goBack = () => {
        navigate(-1)
    };

    const showTreatmentDetails = (patientData) => {
        navigate("/patient-list/view-patient-details/patient_skin_treatment_list/treatment_details", { state: { patientData } })
    };

    const initiateSession = () => {
        navigate("/patient-list/view-patient-details/patient_skin_treatment_list/skin-treatment-booking", { state: { patientData: { ...patientData, callFrom: "patientSkinTreatmentList" } } })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                        <material.Typography variant='h6'>Would you like to start a new session or select from pre-purchased sessions below?</material.Typography>
                    </div>
                    <div className='col-6'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={initiateSession}>Initiate Session</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>
                <material.Paper className='p-4 pb-5 mt-3' elevation={3}>
                    <material.TableContainer sx={{ maxHeight: 460 }}>
                        <material.Table stickyHeader aria-label="sticky table">
                            <material.TableHead >
                                <material.TableRow>
                                    <StyledTableCell>Treatment Name</StyledTableCell>
                                    <StyledTableCell>Treatment Area</StyledTableCell>
                                    <StyledTableCell>Number of Sessions</StyledTableCell>
                                    <StyledTableCell>Remaining of Sessions</StyledTableCell>
                                    <StyledTableCell>Product/Machine</StyledTableCell>
                                    <StyledTableCell>Created At</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {treatmentData.length ? treatmentData.map((rowData, i) => (
                                    <material.TableRow key={i}
                                        sx={rowData.remainingSessions === 0 ? { backgroundColor: "#FFCCCB", cursor: "pointer" } : { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "#CCFFCD" } }}
                                        onClick={() => showTreatmentDetails(rowData)}
                                    >
                                        <material.TableCell>{rowData.treatmentName}</material.TableCell>
                                        <material.TableCell>{rowData.treatmentArea}</material.TableCell>
                                        <material.TableCell>{rowData.totalNoOfSession}</material.TableCell>
                                        <material.TableCell>{rowData.remainingSessions}</material.TableCell>
                                        <material.TableCell>{rowData.productOrMachineUsed}</material.TableCell>
                                        <material.TableCell>{changeDateFormate(rowData.createdAt)}</material.TableCell>
                                        <material.TableCell size='small'>
                                            {rowData.state === "REJECTED" ? (
                                                <span className="badge bg-danger">
                                                    REJECTED
                                                </span>
                                            ) : null}
                                            {rowData.state === "APPROVED" ? (
                                                <span className="badge bg-success">
                                                    APPROVED
                                                </span>
                                            ) : null}
                                            {rowData.isPartOfPackage ? (
                                                <span className="badge bg-primary ms-2">
                                                    Part of Package
                                                </span>
                                            ) : null}
                                        </material.TableCell>
                                    </material.TableRow>
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={8}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </material.Paper>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(PatientTreatmentList);