import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useTheme } from '@mui/material';
import Navbar from '../navbar/Navbar';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAgreementUrl, getSubmittedFormById, updateChecklist } from '../../services/on-boarding-service/OnBoardingService';
import Snackbar from '../toastrmessage/Snackbar';
import ViewUploadedDocument from './supporting-document-upload/ViewUploadedDocument';
import NursesAgreementDialog from './agreement-form/NursesAgreementDialog';
import ViewPdf from './supporting-document-upload/ViewPdf';
import ConsultantAgreementDialog from './agreement-form/ConsultantAgreementDialog';

function OnBoardingChecklist(props) {

    const { register, handleSubmit, reset, control, setValue, watch, getValues, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    localStorage.setItem("menuName", "On-Boarding Checklist")
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const formSubmittedData = location.state?.rowData;

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getSubmittedData, setGetSubmittedData] = useState(null);
    const [openViewAgreementDialog, setOpenViewAgreementDialog] = useState({ action: false, pdfUrl: null });
    const [openViewUploadedDocument, setOpenViewUploadedDocument] = useState({ action: false, formSubmittedData: null });
    const [openViewPdf, setOpenViewPdf] = useState({ action: false, pdfName: null, link: null });

    useEffect(() => {
        getChecklistData()
    }, []);

    const goBack = () => {
        navigate('/manage-onboard')
    };

    const getChecklistData = () => {
        getSubmittedFormById(formSubmittedData.formId)
            .then((resp) => {
                reset(resp.data);
                setGetSubmittedData(resp.data);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })
    };

    const saveChecklist = (formData) => {
        const payload = {
            formId: formSubmittedData.formId,
            ...formData
        }
        updateChecklist(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getChecklistData();
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data
                })
            })
    };

    const agreementSend = () => {
        const payload = {
            formId: formSubmittedData.formId,
            agreementSent: true
        }
        updateChecklist(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getChecklistData();
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })
    };

    const viewAgreement = () => {
        if (getSubmittedData?.signedAgreementReceived) {
            if (getSubmittedData?.agreementSignedByAdmin) {
                getAgreementUrl(formSubmittedData.formId)
                    .then((resp) => {
                        setOpenViewPdf({ action: true, pdfName: null, pdfUrl: resp.data })
                    })
                    .catch((error) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "",
                        })
                    })
            } else {
                setOpenViewAgreementDialog({ action: true, formSubmittedData: formSubmittedData })
            }
        } else {

        }
    };

    const viewUploadedDocument = () => {
        setOpenViewUploadedDocument({ action: true, formSubmittedData: formSubmittedData })
    };

    const systemSetUp = () => {
        const payload = {
            formId: formSubmittedData.formId,
            setUpCompleted: true
        }
        updateChecklist(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getChecklistData();
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "",
                })
            })
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className="row">
                <div className="col-12">
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={viewUploadedDocument}>View Uploaded Document</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={goBack}>Back</material.Button>
                    </span>
                </div>
                <div className="col-12 mt-3">
                    <material.Paper elevation={3} sx={{ p: 3, pb: 7 }}>
                        <form>
                            <div style={{ maxHeight: "65vh", overflowY: "auto", border: "1px solid #ccc", borderRadius: "4px" }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='fw-bold'>
                                            <td>Name</td>
                                            <td>{formSubmittedData?.formSubmittedBy}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Email</td>
                                            <td>{formSubmittedData?.emailAddress}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Role</td>
                                            <td>{formSubmittedData?.applicantRole}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Submission Date</td>
                                            <td>{formSubmittedData?.formSubmittedOn}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Initial enquiry received via website or phone call</td>
                                            <td>
                                                <Controller
                                                    name="initialEnquiry"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Zoom meeting arranged for assessment of injector knowledge and injecting experience.</td>
                                            <td>
                                                <Controller
                                                    name="knowledgeAssessment"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Agreement sent</td>
                                            <td>
                                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={agreementSend} hidden={getSubmittedData?.agreementSent}>Agreement Sent</material.Button>
                                                <Controller
                                                    name="agreementSent"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    disabled
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Signed agreement received</td>
                                            <td>
                                                <Controller
                                                    name="signedAgreementReceived"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    disabled
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>AHPRA Registration & Insurance details received</td>
                                            <td>
                                                <Controller
                                                    name="ahpraRegistrationReceived"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    disabled
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Insurance details received</td>
                                            <td>
                                                <Controller
                                                    name="insuranceEvidenceReceived"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    disabled
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>First Aid Certificate received or evidence of course booking date</td>
                                            <td>
                                                <Controller
                                                    name="firstAidCertificateReceived"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    disabled
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Location Audit completed (online)</td>
                                            <td>
                                                <Controller
                                                    name="locationAudit"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>All document verified</td>
                                            <td>
                                                <Controller
                                                    name="allDocumentVerified"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>All document verified by</td>
                                            <td>
                                                <material.TextField
                                                    id='allDocumentVerifiedBy'
                                                    type="text"
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                    {...register('allDocumentVerifiedBy')}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Sign Agreement</td>
                                            <td>
                                                <Controller
                                                    name="agreementSignedByAdmin"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    disabled
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                                <material.Button variant="contained" sx={{ textTransform: "none", ml: 1 }} size='small' onClick={viewAgreement} hidden={!getSubmittedData?.signedAgreementReceived}>View Agreement</material.Button>
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Set up system</td>
                                            <td>
                                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={systemSetUp} hidden={getSubmittedData?.setUpCompleted}>Initialize System Set-up</material.Button>
                                                <Controller
                                                    name="setUpCompleted"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    disabled
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Copy of protocols sent inc.Emergency Escalation, Vascular Occlusion/Hyalase & ASCIA Protocol</td>
                                            <td>
                                                <Controller
                                                    name="protocolEmailSent"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field }) => (
                                                        <material.FormControlLabel
                                                            control={
                                                                <material.Checkbox
                                                                    disabled
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    color="secondary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <span className='float-end mt-2'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(saveChecklist)}>Save</material.Button>
                            </span>
                        </form>
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ViewUploadedDocument
                openViewUploadedDocument={openViewUploadedDocument}
                setOpenViewUploadedDocument={setOpenViewUploadedDocument}
            />
            <ViewPdf
                openViewPdf={openViewPdf}
                setOpenViewPdf={setOpenViewPdf}
                callForm="check_list"
            />
            {formSubmittedData.applicantRole === "ADMIN" ? (
                <NursesAgreementDialog
                    openViewAgreementDialog={openViewAgreementDialog}
                    setOpenViewAgreementDialog={setOpenViewAgreementDialog}
                    getChecklistData={getChecklistData}
                />
            ) : formSubmittedData.applicantRole === "PRESCRIBER" ? (
                <ConsultantAgreementDialog
                    openViewAgreementDialog={openViewAgreementDialog}
                    setOpenViewAgreementDialog={setOpenViewAgreementDialog}
                    getChecklistData={getChecklistData}
                />
            ) : null}
        </material.Grid>
    );
}

export default OnBoardingChecklist;