import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateSinglePackageDetails } from '../../../services/PackageConfigurationService';
import Snackbar from '../../toastrmessage/Snackbar';

function EditSinglePackageDetails(props) {

    const { openEditPackageDetails, setOpenEditSinglePackageDetails, getSingleClinicPackages, packageId } = props;
    const { register, handleSubmit, resetField, control, watch, setValue, reset, getValues, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [selectValue, setSelectValue] = useState("");

    const costPerUnit = watch("perSessionCost");
    const quantity = watch("numberOfSession_quantityOfProduct");
    const discountPercentage = watch("discountPercentage");
    const discount = watch("discount");
    const totalCost = watch("total");

    useEffect(() => {
        const getPerUnitCost = getValues('perSessionCost');
        const getQuantity = getValues('numberOfSession_quantityOfProduct');
        const getDiscountPercentage = getValues('discountPercentage');
        const getDiscount = getValues('discount');
        const getTotalCost = getValues('total');
        if (costPerUnit && quantity) {
            const totalCost = parseFloat(getPerUnitCost) * parseInt(getQuantity);
            setValue("total", totalCost.toFixed(2));
        }
        if (getTotalCost && getDiscountPercentage) {
            const discount = parseFloat(getTotalCost) * parseInt(getDiscountPercentage) / 100;
            setValue("discount", discount.toFixed(2));
        }
        if (getDiscount && getTotalCost) {
            const payableAmount = parseFloat(getTotalCost) - parseInt(getDiscount)
            setValue("totalPayableAmount", payableAmount.toFixed(2));
        }
    }, [costPerUnit, quantity, discountPercentage, discount, totalCost])

    useEffect(() => {
        if (openEditPackageDetails.action) {
            reset(openEditPackageDetails.packageData);
            setSelectValue(openEditPackageDetails.packageData.isComplementary ? "Yes" : "No")
        }
    }, [openEditPackageDetails.action == true]);

    const saveSinglePackage = (formData) => {
        const payload = {
            id: formData.id,
            clinicId: formData.clinicId,
            orgId: formData.orgId,
            treatment_product_service: formData.treatment_product_service,
            packageId: formData.packageId,
            isComplementary: formData.isComplementary == "Yes" ? true : false,
            treatmentProductName: formData.treatmentProductName,
            treatmentProductArea: formData.treatmentProductArea,
            numberOfSession_quantityOfProduct: formData.numberOfSession_quantityOfProduct,
            perSessionCost: formData.perSessionCost,
            total: formData.total,
            discountPercentage: formData.discountPercentage,
            discount: formData.discount,
            totalPayableAmount: formData.totalPayableAmount
        }
        saveOrUpdateSinglePackageDetails(payload)
            .then((resp) => {
                getSingleClinicPackages(openEditPackageDetails.packageData.packageId);
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.message,
                });
                setOpenEditSinglePackageDetails({ action: false, packageData: null })
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data,
                });
            })
    };

    const cancel = () => {
        setOpenEditSinglePackageDetails({ action: false, packageData: null })
    };

    const handleChange = (event) => {
        setSelectValue(event.target.value)
    };

    return (
        <div>
            <material.Dialog open={openEditPackageDetails.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold'>{openEditPackageDetails?.packageData?.treatment_product_service == "TREATMENT" ? "Edit Treatment" : openEditPackageDetails?.packageData?.treatment_product_service == "PRODUCT" ? "Edit Product" : openEditPackageDetails?.packageData?.treatment_product_service == "SERVICE" ? "Edit Service" : ""}</material.DialogTitle>
                <material.DialogContent>
                    <div className="row border border-2 p-2 my-2 rounded-2 mt-2" style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("treatmentProductName")}
                                required
                                margin="dense"
                                id="name"
                                label="Product Name"
                                type="text"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("treatmentProductArea")}
                                margin="dense"
                                id="name"
                                label="Instruction"
                                type="text"
                                fullWidth
                                multiline
                                rows={2}
                                InputProps={{ readOnly: true }}
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4">
                            <material.FormControl fullWidth variant='standard'>
                                <material.InputLabel id="demo-simple-select-label">Is Complementary</material.InputLabel>
                                <material.Select
                                    {...register("isComplementary", { required: true })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectValue}
                                    onChange={handleChange}
                                >
                                    <material.MenuItem value="Yes">Yes</material.MenuItem>
                                    <material.MenuItem value="No">No</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4">
                            <material.TextField
                                {...register("numberOfSession_quantityOfProduct", { required: true })}
                                required
                                margin="dense"
                                id="name"
                                label="Quantity of Product"
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4">
                            <material.TextField
                                {...register("perSessionCost", { required: true })}
                                required
                                margin="dense"
                                id="name"
                                label="Per Unit Cost"
                                type="text"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4">
                            <material.TextField
                                {...register("total", { required: true })}
                                required
                                margin="dense"
                                id="name"
                                label="Total Cost"
                                type="number"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4">
                            <material.TextField
                                {...register("discountPercentage", { required: true })}
                                required
                                margin="dense"
                                id="name"
                                label="Discount Percentage"
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4">
                            <material.TextField
                                {...register("discount", { required: true })}
                                required
                                margin="dense"
                                id="name"
                                label="Discount"
                                type="number"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4">
                            <material.TextField
                                {...register("totalPayableAmount", { required: true })}
                                required
                                margin="dense"
                                id="name"
                                label="Total Payable Amount"
                                type="number"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(saveSinglePackage)} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default EditSinglePackageDetails;