import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { getPendingTreatmentRequest } from '../../../services/PrescriberService';
import { changeDateAndTimeFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import Snackbar from '../../toastrmessage/Snackbar';
import StatusChangeReasonDialog from '../../dialog/StatusChangeReasonDialog';
import { socket } from '../../../socket/Socket';
import { getPatientAction } from '../../../store/action/Action';
import { connect } from 'react-redux';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';


var allPatientRequestData;

function WatingRoom(props) {
    const { getPatientDetails } = props;
    const [isLoading, setisLoading] = useState(true);
    const [patientRequestData, setPatientRequestData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openStatusChangeDialog, setOpenStatusChangeDialog] = useState({
        "action": false, "data": ""
    });
    const [treatmentQueueUpdated, setTreatmentQueueUpdated] = useState();
    const navigate = useNavigate();

    socket.on("treatment-queue-updated", (res) => {
        setTreatmentQueueUpdated(res);
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Queue Updated",
        })
    });

    localStorage.setItem("menuName", "Approval Queue")

    useEffect(() => {
        pendingTreatmantRequest()
    }, [treatmentQueueUpdated]);

    const pendingTreatmantRequest = async () => {
        await getPendingTreatmentRequest()
            .then((resp) => {
                allPatientRequestData = resp.data;
                setPatientRequestData(resp.data)
                setisLoading(false)
                setTreatmentQueueUpdated("")
            })
            .catch((error) => {

            })
    };

    function filterByPatientName(value) {
        const filteredRows = allPatientRequestData.filter((row) => {
            return row.patientName
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setPatientRequestData(allPatientRequestData);
        } else {
            setPatientRequestData(filteredRows);
        };
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function showTreatmentPlan(treatmentPlanDetails) {
        getPatientDetails(treatmentPlanDetails)
        navigate("/waiting-list/treatment-plan-details", { state: { treatmentPlanDetails } })
    };

    const statusChange = async (value) => {
        setOpenStatusChangeDialog({ action: true, data: value })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <span style={{ marginLeft: 5 }}>
                    <material.TextField
                        sx={{ width: "30ch" }}
                        variant="standard"
                        label="Filter by Patient Name"
                        onChange={(e) => filterByPatientName(e.target.value)}
                    />
                </span>
                <div className="row mt-3">
                    <div className="col-12">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <material.TableContainer sx={{ maxHeight: 700 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Patient Name</StyledTableCell>
                                            <StyledTableCell>Clinic Name</StyledTableCell>
                                            <StyledTableCell>Injector Name</StyledTableCell>
                                            <StyledTableCell>Treatment</StyledTableCell>
                                            <StyledTableCell>Request Generate Time (utc)</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {patientRequestData.length ? patientRequestData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                        className={row.locked ? "disabled-row" : null}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.patientName}</material.TableCell>
                                                        <material.TableCell size='small'>{row.clinicName}</material.TableCell>
                                                        <material.TableCell size='small'>{row.injectorName}</material.TableCell>
                                                        <material.TableCell size='small'>
                                                            {row.treatmentPlan.map((treatment, k) => (
                                                                <span className='d-flex flex-wrap' key={k}>
                                                                    {treatment.treatmentName}, {treatment.treatmentArea}, {treatment.product}, {treatment.qty}
                                                                </span>
                                                            ))}
                                                        </material.TableCell>
                                                        <material.TableCell size='small'>{changeDateAndTimeFormate(row.putToPending)}</material.TableCell>
                                                        <material.TableCell size='small'>
                                                            {row.status === "PENDING" ? (
                                                                <span className="badge" style={{ backgroundColor: "yellowgreen" }}>
                                                                    PENDING
                                                                </span>
                                                            ) : null}
                                                        </material.TableCell>
                                                        <material.TableCell>
                                                            <span className='d-flex flex-column'>
                                                                <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='secondary' size="small" startIcon={<material.VisibilityIcon />} onClick={() => showTreatmentPlan({ ...row, "parentComponent": "waitingRoom" })}>View</material.Button>
                                                                <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='success' size="small" startIcon={<material.DoneIcon />} onClick={() => statusChange({ "action": "approve", ...row, "component": "waitingRoom" })}>Approve</material.Button>
                                                                <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='error' size="small" startIcon={<material.PriorityHighIcon />} onClick={() => statusChange({ "action": "delete", ...row, "component": "waitingRoom" })}>Reject</material.Button>
                                                            </span>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>)}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <hr />
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={patientRequestData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <StatusChangeReasonDialog
                openStatusChangeDialog={openStatusChangeDialog}
                setOpenStatusChangeDialog={setOpenStatusChangeDialog}
                pendingTreatmantRequest={pendingTreatmantRequest}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPatientDetails: (patientData) => {
            dispatch(getPatientAction(patientData))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WatingRoom);