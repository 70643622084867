import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { Controller, useFieldArray } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import moment from 'moment';
import { registerByInviteWithClinic } from '../../services/on-boarding-service/OnBoardingService';
import Snackbar from '../toastrmessage/Snackbar';
import { useNavigate } from 'react-router-dom';

const roles = [
    "MANAGER",
    "INJECTOR",
    "THERAPIST",
];
const gender = ["MALE", "FEMALE", "TRANSGENDER"]

function AddUsers(props) {

    const navigate = useNavigate()
    const { register, errors, handleSubmit, control, reset, setValue, watch } = props;
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "userData",
    });

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        append({ firstName: "", lastName: "", dateOfBirth: "", email: "", role: "", gender: "", AHPRA_Reg_ID: "", address: "", phone: "", password: "" })
    }, []);

    const addUser = () => {
        append({ firstName: "", lastName: "", dateOfBirth: "", email: "", role: "", gender: "", AHPRA_Reg_ID: "", address: "", phone: "", password: "" })
    };

    const saveOnboarding = (formData) => {
        const payload = {
            inviteCode: formData.inviteCode,
            orgName: formData.orgName,
            registeredOfficeAddress: formData.registeredOfficeAddress,
            acn: formData.acn,
            abn: formData.abn,
            director1: formData.director1,
            director1Id: formData.director1Id,
            director1Phone: formData.director1Phone,
            director1Mail: formData.director1Mail,
            director2: formData.director2,
            director2Id: formData.director2Id,
            director2Phone: formData.director2Phone,
            director2Mail: formData.director2Mail,
            contact1Name: formData.contact1Name,
            contact1Mail: formData.contact1Mail,
            contact1Phone: formData.contact1Phone,
            contact1Address: formData.contact1Address,
            contact2Name: formData.contact2Name,
            contact2Mail: formData.contact2Mail,
            contact2Phone: formData.contact2Phone,
            contact2Address: formData.contact2Address,
            dateOfBirth: formData.dateOfBirth,
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.password,
            role: formData.role,
            clinicData: {
                clinicName: formData.clinicName,
                registeredOfficeAddress: formData.clinic_registeredOfficeAddress,
                acn: formData.acn,
                abn: formData.abn,
                director1: formData.clinic_director1Id,
                director1Id: formData.clinic_director1,
                director1Phone: formData.clinic_director1Phone,
                director1Mail: formData.clinic_director1Mail,
                director2: formData.clinic_director2Id,
                director2Id: formData.clinic_director2,
                director2Phone: formData.clinic_director2Phone,
                director2Mail: formData.clinic_director2Mail,
                contact1Name: formData.clinic_contact1Name,
                contact1Mail: formData.clinic_contact1Mail,
                contact1Phone: formData.clinic_contact1Phone,
                contact1Address: formData.clinic_contact1Address,
                contact2Name: formData.clinic_contact2Name,
                contact2Mail: formData.clinic_contact2Mail,
                contact2Phone: formData.clinic_contact2Phone,
                contact2Address: formData.clinic_contact2Address,
                defaultConfig: formData.defaultConfig
            },
            userData: formData.userData,
        }
        registerByInviteWithClinic(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset();
                navigate("/login");
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "",
                })
            })
    };

    return (
        <div>
            <material.Paper sx={{ p: 4, mt: 2 }}>
                <div className="row">
                    <div className="col-6">
                        <material.Typography>User Details</material.Typography>
                    </div>
                    <div className="col-6">
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={addUser}>Add</material.Button>
                        </span>
                    </div>
                </div>
                {fields.map((field, ind) => {
                    const userRole = watch(`userData.${ind}.role`, []);
                    const isDoctor = watch(`userData.${ind}.isDoctor`, null);
                    return (
                        <div className="row border border-2 p-2 my-2 rounded-2 mt-3" key={field.id} style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    {...register(`userData.${ind}.firstName`, { required: true })}
                                    required
                                    margin="dense"
                                    id="firstName"
                                    label="First Name"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    {...register(`userData.${ind}.lastName`, { required: true })}
                                    required
                                    margin="dense"
                                    id="lastName"
                                    label="Last Name"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name={`userData.${ind}.dateOfBirth`}
                                        control={control}
                                        defaultValue={null}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <material.DatePicker
                                                label="Date of Birth *"
                                                format="DD-MM-YYYY"
                                                value={field.value || null}
                                                onChange={(newValue) => {
                                                    field.onChange(moment(newValue?.$d).format("YYYY-MM-DD"));
                                                }}
                                                renderInput={(params) => (
                                                    <material.TextField
                                                        margin="dense"
                                                        {...params}
                                                        variant="standard"
                                                        color="success"
                                                    />
                                                )}
                                                disableFuture
                                                sx={{ width: '100%' }}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    {...register(`userData.${ind}.email`, { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    required
                                    margin="dense"
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Controller
                                    name={`userData.${ind}.role`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            multiple
                                            disableCloseOnSelect
                                            value={field.value || []}
                                            options={roles}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    required
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Role"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Controller
                                    name={`userData.${ind}.gender`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            options={gender}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    required
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Gender"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {userRole.includes("INJECTOR") && (
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register(`userData.${ind}.AHPRA_Reg_ID`, { required: true })}
                                        required
                                        margin="dense"
                                        id="AHPRA_Reg_ID"
                                        label="AHPRA Reg ID"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                </div>
                            )}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    {...register(`userData.${ind}.address`, { required: true })}
                                    required
                                    margin="dense"
                                    id="address"
                                    label="Address"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                                <p className='form-text text-danger'>{errors.address?.type === "required" && 'This field is required'}</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Controller
                                    name={`userData.${ind}.phone`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <PhoneInput
                                            {...field}
                                            required
                                            placeholder="Phone"
                                            defaultCountry="AU"
                                            enableSearch={true}
                                            value={field.value || ""}
                                            international
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.IconButton
                                    title='Delete'
                                    aria-label="delete"
                                    size="large"
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 2, } }}
                                    onClick={() => remove(ind)}
                                >
                                    <material.DeleteIcon color='warning' />
                                </material.IconButton>
                            </div>
                        </div>
                    )
                })}
                <div className="row">
                    <div className="col-12">
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} color='success' onClick={handleSubmit(saveOnboarding)}>Save All</material.Button>
                        </span>
                    </div>
                </div>
            </material.Paper>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddUsers;