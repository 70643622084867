import React, { useEffect, useRef, useState } from 'react';
import { material } from '../../../library/material';
import { uploadPatientGeneralImage, uploadPatientGeneralImageFile } from '../../../services/PatientService';
import { uploadCosmeticTreatmentImage, uploadCosmeticTreatmentImageFile } from '../../../services/TreatmentPlanService';
import { socket } from '../../../socket/Socket';
import Snackbar from '../../toastrmessage/Snackbar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function Camera(props) {

    const { openUploadPhoto, setOpenUploadPhoto, blankTreatmentData, userDetails, clinicDetails, getCosmeticImageList, callFrom, patientData, getAllImagePatient, typeOfImage } = props;
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const navigate = useNavigate();
    const [facingMode, setFacingMode] = useState("environment");
    const [error, setError] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [selectFolder, setSelectFolder] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openUploadPhoto.action && openUploadPhoto.uploadType === "click_and_upload") {
            startCamera()
        }
    }, [facingMode, openUploadPhoto.action == true]);

    const startCamera = async () => {
        try {
            if (videoRef.current && videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }

            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: facingMode },
            });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (err) {
            setError("Camera access was denied or not available.");
            console.error("Error accessing camera:", err);
        }
    };

    const handleCloseCamera = async () => {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        videoRef.current.srcObject = null;
    };

    const handleSwitchCamera = async () => {
        setFacingMode((prevMode) => (prevMode == "environment" ? "user" : "environment"));
    };

    const handleCapture = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        if (video && canvas) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageData = canvas.toDataURL("image/png");
            setCapturedImage(imageData);
            upload(imageData);
        }
        handleCloseCamera();
    };

    const uploadFile = () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait...",
        })
        let data = new FormData();
        for (const file of selectFolder) {
            data.append('images', file);
        }
        data.append('typeOfImage', callFrom === "patient-details" ? "profile" : typeOfImage === "after_image" ? "after_image" : "before_image");
        data.append('treatmentPlanRequestId', callFrom === "patient-details" ? null : blankTreatmentData.treatmentPlanRequestId);
        data.append('patientId', callFrom === "patient-details" ? patientData.patientId : blankTreatmentData.patientId);
        data.append('orgId', userDetails.orgId);
        data.append('clinicId', clinicDetails.clinicId);
        data.append('date', moment().format("DD/MM/YYYY, h:mm"));
        data.append('treatmentName', "");
        data.append('clinicName', clinicDetails.clinicName);
        data.append('patientName', callFrom === "patient-details" ? patientData.firstName + " " + patientData.lastName : blankTreatmentData.patientName);
        if (callFrom === "patient-details") {
            uploadPatientGeneralImageFile(data)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllImagePatient();
                    setOpenUploadPhoto({ action: false, uploadType: null });
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        } else {
            uploadCosmeticTreatmentImageFile(data)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    if (callFrom === "primary-user") {
                        getCosmeticImageList(blankTreatmentData)
                    } else {
                        socket.emit("new-photo-added");
                        navigate("/kiosk-menu");
                    }
                    setOpenUploadPhoto({ action: false, uploadType: null });
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        };
    };

    const upload = (image) => {
        startCamera();
        let obj = {
            image: image,
            patientId: callFrom === "patient-details" ? patientData.patientId : blankTreatmentData.patientId,
            treatmentPlanRequestId: callFrom === "patient-details" ? null : blankTreatmentData.treatmentPlanRequestId,
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId,
            typeOfImage: callFrom === "patient-details" ? "profile" : typeOfImage === "after_image" ? "after_image" : "before_image",
            date: moment().format("DD/MM/YYYY, h:mm"),
            treatmentName: "",
            clinicName: clinicDetails.clinicName,
            patientName: callFrom === "patient-details" ? patientData.firstName + " " + patientData.lastName : blankTreatmentData.patientName
        }
        if (callFrom === "patient-details") {
            uploadPatientGeneralImage(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    setCapturedImage(null);
                    getAllImagePatient();
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        } else {
            uploadCosmeticTreatmentImage(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    if (callFrom === "primary-user") {
                        setCapturedImage(null);
                        getCosmeticImageList(blankTreatmentData)
                    } else {
                        socket.emit("new-photo-added");
                        // navigate("/kiosk-menu");
                        // startCamera();
                        setCapturedImage(null);
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        };
    };

    const cancel = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
        setCapturedImage(null);
        setOpenUploadPhoto({ action: false, uploadType: null });
    };

    const chooseFile = (e) => {
        let fileName = e.target.files;
        setSelectFolder(fileName);
    };

    return (
        <div>
            <material.Dialog maxWidth={openUploadPhoto.uploadType === "click_and_upload" ? "md" : "sm"} fullWidth open={openUploadPhoto.action} hideBackdrop>
                <material.DialogTitle>Upload Photo</material.DialogTitle>
                {openUploadPhoto.uploadType === "upload_file" ? (
                    <material.DialogContent>
                        <input
                            margin="dense"
                            id="fileInput"
                            label="Choose Photo"
                            accept=".jpg, .jpeg, .png"
                            fullWidth
                            type="file"
                            onChange={chooseFile}
                            multiple
                        />
                    </material.DialogContent>
                ) : (
                    <material.DialogContent>
                        {error ? (
                            <p style={{ color: "red" }}>{error}</p>
                        ) : (
                            <>
                                {/* {capturedImage ? (
                                    <div style={{ marginTop: "20px" }}>
                                        <span className='d-flex justify-content-center'>
                                            <img
                                                src={capturedImage}
                                                alt="Captured"
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "500px",
                                                    borderRadius: "8px",
                                                    border: "2px solid #ccc",
                                                    marginTop: "10px",
                                                }}
                                            />
                                        </span>
                                    </div>
                                ) : ( */}
                                <div>
                                    <span className='d-flex justify-content-center'>
                                        <video
                                            ref={videoRef}
                                            autoPlay
                                            playsInline
                                            style={{
                                                width: "100%",
                                                maxWidth: "500px",
                                                borderRadius: "8px",
                                                border: "2px solid #ccc",
                                            }}
                                        ></video>
                                        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
                                    </span>
                                    <span className='d-flex justify-content-center mt-2'>
                                        <material.Button startIcon={<material.CameraswitchIcon />} size="small" variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={handleSwitchCamera}>
                                            Switch Camera
                                        </material.Button>
                                        <material.Button startIcon={<material.CameraAltIcon />} size="small" variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={handleCapture}>
                                            Capture
                                        </material.Button>
                                    </span>
                                </div>
                                {/* )} */}
                            </>
                        )}
                    </material.DialogContent>
                )}
                <material.DialogActions className='m-2'>
                    <material.Button sx={{ textTransform: "none", mr: 1 }} variant="outlined" color='error' size="small" onClick={cancel}>Cancel</material.Button>
                    {openUploadPhoto.uploadType === "upload_file" ? (
                        <material.Button sx={{ textTransform: "none" }} variant="outlined" color='info' size="small" onClick={uploadFile}>Upload</material.Button>
                    ) : null
                        // (
                        //     <material.Button sx={{ textTransform: "none" }} variant="outlined" color='info' size="small" onClick={upload} disabled={capturedImage == null}>Upload</material.Button>
                        // )
                    }
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default Camera;