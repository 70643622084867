import React from 'react';
import { material } from '../../../../library/material';
import { useForm } from 'react-hook-form';

function AddOtherProduct(props) {

    const { openAddOtherProduct, setOpenAddOtherProduct, getOtherProduct } = props;
    const { register, handleSubmit, resetField, control, watch, getValues, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });

    const saveOtherProduct = (data) => {
        const products = data.otherProduct.split(",");
        getOtherProduct(products);
        setOpenAddOtherProduct(false);
        reset({ otherProduct: "" });
    };

    const cancel = () => {
        setOpenAddOtherProduct(false);
        reset({ otherProduct: "" });
    };

    return (
        <div>
            <material.Dialog open={openAddOtherProduct} hideBackdrop fullWidth maxWidth="sm">
                <material.DialogTitle>Add Other Product</material.DialogTitle>
                <material.DialogContent>
                    <span style={{ fontSize: 12, color: "red" }}>This data is temporary and will be removed when you close this menu. To keep it permanently, please add it to your stocks.</span>
                    <material.TextField
                        {...register('otherProduct', { required: true })}
                        margin="dense"
                        id="name"
                        label="Product Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                    />
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(saveOtherProduct)} disabled={!isValid} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
        </div>
    );
}

export default AddOtherProduct;