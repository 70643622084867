import React, { useEffect, useState, useRef } from 'react';
import { material } from '../../../library/material';
import Webcam from 'react-webcam';
import { saveTreatmentPlan } from '../../../services/TreatmentPlanService';
import Snackbar from '../../toastrmessage/Snackbar';
import { UploadPhotoDialog } from '../treatment-plan/TreatmentPlan';
import { connect } from 'react-redux';
import { socket } from '../../../socket/Socket';
import Camera from '../camera/Camera';

var uploadImage = [];

function AfterImages(props) {

    const { openAfterImage, setOpenAfterImage, getTreatmentPlanDataByPlanId, userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openUploadPhoto, setOpenUploadPhoto] = useState({ action: false, uploadType: null });

    const uploadAfterImage = async () => {
        let obj = {
            treatmentPlanRequestId: openAfterImage.patientData.treatmentPlanRequestId,
            patientId: openAfterImage.patientData.patientId,
            injectorId: openAfterImage.patientData.injectorId,
            timeStamp: openAfterImage.patientData.timeStamp,
            targetAreaBefore: openAfterImage.patientData.targetAreaBefore,
            treatmentPlan: openAfterImage.patientData.treatmentPlan,
            clinicId: openAfterImage.patientData.clinicId,
            bdd_form_id: openAfterImage.patientData.bdd_form_id,
            bdd_support_image: openAfterImage.patientData.bdd_support_image,
            targetAreaAfter: uploadImage
        };
        await saveTreatmentPlan(obj)
            .then((resp) => {
                setOpenAfterImage({ action: false, patientData: null })
                uploadImage = [];
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Submit successfully",
                })
                getTreatmentPlanDataByPlanId()
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const cancel = () => {
        setOpenAfterImage({ action: false, patientData: null });
        uploadImage = [];
    };

    const clickPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "click_and_upload" });
    };

    const selectPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "upload_file" });
    };

    const addPhotoUsingSecondary = () => {
        let obj = {
            blankTreatmentData: openAfterImage.patientData,
            clinicDetails,
            userDetails,
            imageType: openAfterImage.imageType
        }
        socket.emit("add-photo-kiosk", obj)
    };

    return (
        <div>
            <material.Dialog open={openAfterImage.action} hideBackdrop fullWidth maxWidth="md">
                <material.DialogTitle>{openAfterImage.imageType === "after_image" ? "After Images" : "Before Images"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div>
                        <span>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={clickPhoto} startIcon={<material.AddAPhotoIcon />}>Add Photo</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={selectPhoto} startIcon={<material.UploadFileIcon />}>Upload Photo</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="small" onClick={addPhotoUsingSecondary} startIcon={<material.AddAPhotoIcon />}>Add Photo Using Secondary</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='m-3'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} color='error' size="medium" onClick={cancel}>Cancel</material.Button>
                        {uploadImage?.length ? (
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={uploadAfterImage} startIcon={<material.UploadIcon />}>Upload</material.Button>
                        ) : null}
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            {/* <UploadPhotoDialog
                openUploadPhoto={openUploadPhoto}
                setOpenUploadPhoto={setOpenUploadPhoto}
                blankTreatmentData={openAfterImage.patientData}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getCosmeticImageList={getTreatmentPlanDataByPlanId}
                callFrom="primary-user"
                typeOfImage={openAfterImage.imageType}
            /> */}
            <Camera
                openUploadPhoto={openUploadPhoto}
                setOpenUploadPhoto={setOpenUploadPhoto}
                blankTreatmentData={openAfterImage.patientData}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getCosmeticImageList={getTreatmentPlanDataByPlanId}
                callFrom="primary-user"
                typeOfImage={openAfterImage.imageType}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AfterImages);