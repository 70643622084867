import React, { useEffect, useState } from 'react';
import Navbar from '../navbar/Navbar';
import { material } from '../../library/material';
import { getClinicForInjector, getAllClinic } from '../../services/ClinicService';
import { connect } from 'react-redux';
import { getClinicAction } from '../../store/action/Action';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

function ClinicSelector(props) {

    const { userData, getClinicDetails } = props;
    let userDetails = userData.authReducer.data;
    const [clinicData, setClinicData] = useState([]);
    localStorage.setItem("menuName", "Clinic Selector");
    const navigate = useNavigate();
    const theme = useTheme();
    useEffect(() => {
        if (userDetails.role.includes("ADMIN")) {
            getClinic();
        } else {
            getClinicForUsers();
        }
    }, []);

    const getClinicForUsers = async () => {
        await getClinicForInjector()
            .then((resp) => {
                setClinicData(resp.data)
            })
            .catch((error) => {

            })
    };

    const getClinic = async () => {
        await getAllClinic()
            .then((resp) => {
                setClinicData(resp.data)
                if (!resp.data.length) {
                    navigate("/clinic")
                }
            })
            .catch((error) => {

            })
    };

    const selectClinic = (clinicData) => {
        getClinicDetails(clinicData);
        navigate("/card-component")
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div>
                {/* {clinicData?.length < 2 ? (
                    <>
                        {clinicData?.map((ele, i) => (
                            <div key={i}>
                                <material.Card elevation={3} sx={{ p: 2, mt: 2, backgroundColor: "#3877b5", minWidth: 275 }}>
                                    <material.CardActionArea onClick={() => selectClinic(ele)}>
                                        <material.CardContent>
                                            <material.Typography sx={{ color: "white", fontSize: 22 }} className='text-center fw-bold'>{ele.clinicName}</material.Typography>
                                        </material.CardContent>
                                    </material.CardActionArea>
                                </material.Card>
                            </div>
                        ))}
                    </>
                ) : ( */}
                <div className="row container-fluid">
                    {clinicData?.map((ele, i) => (
                        <div className="col-lg-3" key={i}>
                            <material.Card elevation={3} sx={{ p: 2, mt: 2, backgroundColor: "#3877b5" }}>
                                <material.CardActionArea onClick={() => selectClinic(ele)}>
                                    <material.CardContent>
                                        <material.Typography sx={{ color: "white", fontSize: 22 }} className='text-center fw-bold'>{ele.clinicName}</material.Typography>
                                    </material.CardContent>
                                </material.CardActionArea>
                            </material.Card>
                        </div>
                    ))}
                </div>
                {/* )} */}
            </div>
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClinicDetails: (data) => {
            dispatch(getClinicAction(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSelector);