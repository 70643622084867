import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { getCampaignPatientList } from '../../../services/CampaignService';
import Snackbar from '../../toastrmessage/Snackbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';

var allListData;

function ViewCustomPatient(props) {

    const { openViewCustomPatient, setOpenViewCustomPatient, clinicDetails, userDetails } = props;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [patientList, setPatientList] = useState([]);
    const [selectIndex, setSelectIndex] = useState(null);
    const [selectedList, setSelectedList] = useState(null)

    useEffect(() => {
        if (openViewCustomPatient) {
            getPatientList()
        }
    }, [openViewCustomPatient === true]);

    const getPatientList = () => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        getCampaignPatientList(payload)
            .then((resp) => {
                allListData = resp.data;
                setPatientList(resp.data);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const selectListName = (selectedData, index) => {
        setSelectedList(selectedData)
        setSelectIndex(index)
    };

    const handleClose = () => {
        setSelectIndex(null);
        setSelectedList(null);
        setOpenViewCustomPatient(false);
    };

    const searchList = (value) => {
        const filteredRows = allListData.filter((row) => {
            return row.listName
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setPatientList(allListData);
        } else {
            setPatientList(filteredRows);
        }
    };

    return (
        <div>
            <material.Dialog open={openViewCustomPatient} fullWidth fullScreen>
                <material.DialogTitle>Patient Custom List
                    <span className='float-end'>
                        <material.IconButton title='Close' color='error' onClick={handleClose}>
                            <material.CloseIcon sx={{ cursor: "pointer" }} />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-4">
                            <material.Paper elevation={3} sx={{ p: 2, height: "90vh", overflow: "auto" }}>
                                <div className='d-flex flex-row space-between mt-2'>
                                    <span className='me-3'>
                                        <material.Typography variant='h6'>List Name</material.Typography>
                                    </span>
                                    <span>
                                        <material.TextField
                                            fullWidth
                                            variant="standard"
                                            label="Search List"
                                            onChange={(e) => searchList(e.target.value)}
                                            sx={{ mt: -3, width: "40ch" }}
                                        />
                                    </span>
                                </div>
                                {patientList.length ? patientList.map((patient, k) => (
                                    <material.List component="nav" aria-label="secondary mailbox folder" key={k}
                                    >
                                        <material.ListItemButton
                                            selected={selectIndex == k}
                                            onClick={() => selectListName(patient, k)}
                                        >
                                            <material.ListItemText primary={patient.listName} />
                                        </material.ListItemButton>
                                    </material.List>
                                )) : null}
                            </material.Paper>
                        </div>
                        <div className="col-8">
                            <material.Paper elevation={3} sx={{ p: 2 }}>
                                <span>
                                    <material.Typography variant='h6'>Patient List</material.Typography>
                                </span>
                                <material.TableContainer sx={{ maxHeight: "90vh" }}>
                                    <material.Table stickyHeader aria-label="sticky table">
                                        <material.TableHead >
                                            <material.TableRow>
                                                <StyledTableCell>Name</StyledTableCell>
                                                <StyledTableCell>Email</StyledTableCell>
                                                <StyledTableCell>Phone No</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {selectedList?.data.length ? selectedList.data.map((patient, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell>{patient.firstName + " " + patient.lastName}</material.TableCell>
                                                    <material.TableCell>{patient.email}</material.TableCell>
                                                    <material.TableCell>{patient.phoneNumberWithCountryCode}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </material.Paper>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ViewCustomPatient;