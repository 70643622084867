import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { material } from "../../../library/material";
import Navbar from "../../navbar/Navbar";
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import AddCreateBankCashFloat from "./AddCreateBankCashFloat";
import { getAllCashFloat } from "../../../services/CreateBankCashFloatService";
import { connect } from "react-redux";
import Snackbar from "../../toastrmessage/Snackbar";
import moment from "moment";
import { rootApiUrl } from "../../../environment/Environment";

function CashFloat(props) {
  const { userData, clinicData } = props;
  const userDetails = userData.authReducer.data;
  const clinicDetails = clinicData.clinicReducer.data;
  const theme = useTheme();
  const [openCreateBankCashFloat, setOpenCreateBankCashFloat] = useState({
    action: false,
    cashFloatData: null,
  });
  const [createBankCashFloatTableData, setCreateBankCashFloatTableData] =
    useState([]);
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    getAllCreateBankCashFloat();
  }, []);

  const createBankCashFloat = () => {
    setOpenCreateBankCashFloat({ action: true, cashFloatData: null });
  };

  // const today = moment().format("YYYY-MM-DD");
  // const isDateAlreadyExists = createBankCashFloatTableData.some(
  //   (item) => moment(item.date).format("YYYY-MM-DD") === today
  // );

  const isDisabled = createBankCashFloatTableData.some(
    (item) => (item.isSubmitted === true || item.isSubmitted === 1)
  );

  const editCashFloat = (allData) => {
    setOpenCreateBankCashFloat({ action: true, cashFloatData: allData });
  };

  const getAllCreateBankCashFloat = async () => {
    let payload = {
      clinicId: clinicDetails.clinicId,
      orgId: userDetails.orgId,
    };
    await getAllCashFloat(payload)
      .then((resp) => {
        setCreateBankCashFloatTableData(resp.data);
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response?.data?.message,
        });
      });
  };

  const downloadAttachment = (attachmentLink) => {
    setOpenCreateBankCashFloat({ action: false, cashFloatData: null });
    window.open(`${rootApiUrl}/agl-link?key=${attachmentLink}`, "_blank")
  };

  return (
    <material.Grid
      className="container-fluid"
      sx={{
        [theme.breakpoints.down("sm")]: { mt: 10 },
        [theme.breakpoints.up("sm")]: { mt: 10 },
        [theme.breakpoints.up("md")]: { mt: 10 },
        [theme.breakpoints.up("lg")]: { mt: 10 },
      }}
    >
      <Navbar />
      <div className="row">
        <div className="col-12">
          <span className="float-end">
            {userDetails.role.includes("INJECTOR") ? (
              <material.Button
                disabled={isDisabled}
                onClick={createBankCashFloat}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                <material.CreateIcon sx={{ mr: 1 }} />
                Create Bank Cash Float
              </material.Button>
            ) : null}
          </span>
        </div>
        <div className="mt-3">
          <material.TableContainer sx={{ maxHeight: "80vh" }}>
            <material.Table stickyHeader aria-label="sticky table">
              <material.TableHead sx={{ border: 1 }}>
                <material.TableRow>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Date
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Opening Balance
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Cash Sale
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Closing Balance
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={5}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    COINS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={5}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Notes
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Cash in Hand
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Variance
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Comments
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Float Limit
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    To Hand over / Bank
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Day Closed by
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Bank Deposit By / Received By
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    sx={{ border: 1 }}
                  >
                    Add Attachment
                  </StyledTableCell>
                  {/* {userDetails.role.includes("MANAGER") ?
                    (<StyledTableCell rowSpan={2} align="center" sx={{ border: 1 }}>  Action</StyledTableCell>) : ""} */}
                </material.TableRow>
                <material.TableRow>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    5 cents
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    10 cents
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    50 cents
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    1 dollar
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    2 dollar
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    5 dollar
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    10 dollar
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    20 dollar
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    50 dollar
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: 1 }}>
                    100 dollar
                  </StyledTableCell>
                </material.TableRow>
              </material.TableHead>
              <material.TableBody>
                {createBankCashFloatTableData.length ? (
                  createBankCashFloatTableData.map((data, i) => (
                    <material.TableRow key={i}
                      sx={userDetails.role.includes("MANAGER") && !data.isApprove ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                      onClick={userDetails.role.includes("MANAGER") && !data.isApprove ? () => editCashFloat(data) : null}
                    >
                      <material.TableCell align="center">
                        {data.date}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.openingBalanceAmount}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.cashSale}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.closingBalanceAmount}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.coins_5_cents}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.coins_10_cents}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.coins_50_cents}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.coins_1_dollar}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.coins_2_dollar}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.notes_5_dollar}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.notes_10_dollar}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.notes_20_dollar}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.notes_50_dollar}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.notes_100_dollar}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.cashInHandAmount}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.variance}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.comments}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.floatLimit}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.toHandOverBank}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.dayClosedByUserName}
                      </material.TableCell>
                      <material.TableCell align="center">
                        {data.bankDepositByReceivedBy}
                      </material.TableCell>
                      <material.TableCell align="center">
                        <material.Link component="button" variant="body2" title="Download Attachment" onClick={(e) => { e.stopPropagation(); downloadAttachment(data.addAttachment) }}>{data.addAttachment.split("/").pop()}</material.Link>
                      </material.TableCell>
                    </material.TableRow>
                  ))
                ) : (
                  <material.TableRow>
                    <material.TableCell colSpan={22}>
                      <h6 className="d-flex justify-content-center text-danger fw-bold">
                        No data found
                      </h6>
                    </material.TableCell>
                  </material.TableRow>
                )}
              </material.TableBody>
            </material.Table>
          </material.TableContainer>
        </div>
      </div>
      <AddCreateBankCashFloat
        openCreateBankCashFloat={openCreateBankCashFloat}
        setOpenCreateBankCashFloat={setOpenCreateBankCashFloat}
        getAllCreateBankCashFloat={getAllCreateBankCashFloat}
      />
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
    </material.Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state,
    clinicData: state,
  };
};

export default connect(mapStateToProps)(CashFloat);
