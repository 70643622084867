import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const getCampaignNameByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-campaign-names`, { params: { clinicId: clinicId } });
};

export const getCampaignNameByCampaignRefNo = async (campaignRefNo) => {
    return await axios.get(`${rootApiUrl}/get-campaign-by-id`, { params: { campaignRefNo: campaignRefNo } });
};

export const startCampaign = async (campaignData) => {
    return await axios.post(`${rootApiUrl}/start-campaign`, campaignData);
};

export const startSendingEmailByCampaignRefNo = async (campaignRefNo) => {
    return await axios.post(`${rootApiUrl}/start-sending-email-by-campaign-ref-no`, campaignRefNo);
};

export const startSendingSmsByCampaignRefNo = async (campaignRefNo) => {
    return await axios.post(`${rootApiUrl}/start-sending-sms-by-campaign-ref-no`, campaignRefNo);
};

export const uploadTemplateForCampaign = async (templateData) => {
    return await axios.post(`${rootApiUrl}/upload-template-for-campaign`, templateData);
};

export const getTemplateList = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-template-list`, { params: { ...payload } });
};

export const saveCustomCampaignList = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-custom-campaign-list`, payload);
};

export const getAllCustomCampaignList = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-all-custom-campaign-list`, { params: { ...payload } });
};

export const createHtmlTemplate = async (templateData) => {
    return await axios.post(`${rootApiUrl}/save-email-campaign-template`, templateData)
};

export const getCampaignPatientList = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-campaign-patient-list`, { params: { ...payload } })
};

export const getAllHtmlTemplate = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-all-template-html`, { params: { ...payload } })
};

export const updateHtmlTemplate = async (payload) => {
    return await axios.post(`${rootApiUrl}/update-existing-template`, payload)
};

export const getAllPublicImage = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-all-public-image`, { params: { ...payload } })
};