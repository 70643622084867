import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const createNewClinicPackage = async (payload) => {
    return await axios.post(`${rootApiUrl}/create-new-clinic-package`, payload)
};

export const saveOrUpdatePackage = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-or-update-package`, payload)
};

export const getClinicPackage = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-clinic-package`, { params: { ...payload } })
};

export const getSinglePackage = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-single-clinic-package`, { params: { ...payload } })
};

export const saveOrUpdatePackageDetails = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-or-update-package-details`, payload)
};

export const saveOrUpdateSinglePackageDetails = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-or-update-single-package-details`, payload)
};

export const getPackageDetails = async (packageId) => {
    return await axios.get(`${rootApiUrl}/get-clinic-package-details`, { params: { packageId: packageId } })
};

export const getPackageInvoiceByPatientId = async (patientId) => {
    return await axios.get(`${rootApiUrl}/get-package-invoice-by-patient-id`, { params: { patientId: patientId } })
};

export const savePackageInvoice = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-package-invoice`, payload)
};

export const viewPackageInvoice = async (invoiceId) => {
    return await axios.get(`${rootApiUrl}/get-package-invoice`, { params: { invoiceId } })
};

export const sendPackageInvoice = async (invoiceId) => {
    return await axios.get(`${rootApiUrl}/send-package-invoice`, { params: { invoiceId } })
};