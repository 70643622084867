import React, { useState } from 'react';
import { material } from '../../library/material';
import { skinTreatmentDecreaseRemaining } from '../../services/SkinTreatmentService';
import Snackbar from '../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditSkinTreatmentInvoice from './EditSkinTreatmentInvoice';

function PreSessionWarning(props) {

    const { openPreSessionWarningDialog, setOpenPreSessionWarningDialog, userData, callFrom, setAddSkinTreatmentNots, setOpenConsentForm, setAddPostSessionNots, setOpenPhotoUploadDialog } = props;
    const userDetails = userData.authReducer.data;
    // const [selectValue, setSelectValue] = useState("");
    const navigate = useNavigate();
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openEditInvoice, setOpenEditInvoice] = useState({ action: false, invoiceData: null })

    const usedTreatment = async () => {
        await skinTreatmentDecreaseRemaining(openPreSessionWarningDialog.patientData.skinTreatmentPlanId)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                if (openPreSessionWarningDialog.patientData.isPartOfPackage) {
                    if (callFrom === "Post Session Note") {
                        setAddSkinTreatmentNots({ action: false, patientData: null });
                        setOpenConsentForm({ action: false, patientData: null });
                        setAddPostSessionNots({ action: false, patientData: null, note: null });
                        setOpenPhotoUploadDialog({ action: false, treatmentData: null });
                        navigate("/patient-list/view-patient-details", { state: { patientData: openPreSessionWarningDialog.patientData } });
                    } else if (callFrom === "skin_treatment_details") {
                        setOpenPreSessionWarningDialog({ action: false, patientData: null });
                    };
                } else {
                    setOpenEditInvoice({ action: true, invoiceData: openPreSessionWarningDialog.patientData })
                }
                // if (callFrom === "Post Session Note") {
                //     setAddSkinTreatmentNots({ action: false, patientData: null });
                //     setOpenConsentForm({ action: false, patientData: null });
                //     setAddPostSessionNots({ action: false, patientData: null, note: null });
                //     setOpenPhotoUploadDialog({ action: false, treatmentData: null });
                //     setOpenPreSessionWarningDialog({ action: false, patientData: null })
                // }
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const cancel = () => {
        if (callFrom === "Post Session Note") {
            setAddSkinTreatmentNots({ action: false, patientData: null });
            setOpenConsentForm({ action: false, patientData: null });
            setAddPostSessionNots({ action: false, patientData: null, note: null });
            setOpenPhotoUploadDialog({ action: false, treatmentData: null });
        } else if (callFrom === "skin_treatment_details") {
            setOpenPreSessionWarningDialog({ action: false, patientData: null });
        };
    };

    // const handleChange = (event) => {
    //     setSelectValue(event.target.value)
    //     if (event.target.value === "Yes") {
    //         setOpenWarningDialog({ action: true, patientData: openPreSessionWarningDialog.patientData })
    //         setOpenPreSessionWarningDialog({ action: false, patientData: null })
    //         setSelectValue("")
    //     } else if (event.target.value === "No") {
    //         setOpenPreSessionWarningDialog({ action: false, patientData: null })
    //         setSelectValue("")
    //     }
    // };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="sm" open={openPreSessionWarningDialog.action} hideBackdrop>
                <material.DialogContent className='m-2'>
                    <div>
                        <material.Typography>Hello {userDetails.name}</material.Typography>
                        <material.Typography>You're about to finalize the current treatment session for {openPreSessionWarningDialog?.patientData?.patientId}</material.Typography>
                    </div>
                    <div className='mt-2'>
                        <span className='fw-bold'>Please confirm:</span>
                        <div>
                            <span>
                                All treatments and procedures for this session have been completed.
                                Patient records are updated with today's date and session details.
                                Any follow-up actions are noted.
                            </span>
                        </div>
                    </div>
                    <div>
                        <material.Typography className='fw-bold'>Would you like to mark this treatment session as finalized?</material.Typography>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end p-3'>
                        <material.Button variant="outlined" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={cancel}>
                            No
                        </material.Button>
                        <material.Button variant="outlined" sx={{ textTransform: "none" }} size="medium" onClick={usedTreatment}>
                            Yes
                        </material.Button>
                    </span>
                </material.DialogActions>
                {/* <material.DialogContent>
                    <div className='row'>
                        <div className='col-12 d-flex flex-row align-items-center'>
                            <div className='me-2 fw-bold text-dark'>Did you added pre session notes ? :</div>
                            <material.FormControl>
                                <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectValue}
                                    onChange={handleChange}
                                >
                                    <material.FormControlLabel value="Yes" control={<material.Radio color='secondary' />} label="Yes" />
                                    <material.FormControlLabel value="No" control={<material.Radio color='secondary' />} label="No" />
                                </material.RadioGroup>
                            </material.FormControl>
                        </div>
                    </div>
                </material.DialogContent> */}
            </material.Dialog>
            {/* <WarningDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                userDetails={userDetails}
            /> */}
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <EditSkinTreatmentInvoice
                openEditInvoice={openEditInvoice}
                setOpenEditInvoice={setOpenEditInvoice}
                callFrom={callFrom}
                setAddSkinTreatmentNots={setAddSkinTreatmentNots}
                setOpenConsentForm={setOpenConsentForm}
                setAddPostSessionNots={setAddPostSessionNots}
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
                setOpenPreSessionWarningDialog={setOpenPreSessionWarningDialog}
            />
        </div>
    )
}

const WarningDialog = (props) => {

    const { openWarningDialog, setOpenWarningDialog, userDetails } = props;
    let navigate = useNavigate();
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const usedTreatment = async () => {
        await skinTreatmentDecreaseRemaining(openWarningDialog.patientData.skinTreatmentPlanId)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenWarningDialog({ action: false, patientData: null })
                navigate(-1)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const cancel = () => {
        setOpenWarningDialog({ action: false, patientData: null })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="sm" open={openWarningDialog.action} hideBackdrop >
                <material.DialogContent className='m-2'>
                    <div>
                        <material.Typography>Hello {userDetails.name}</material.Typography>
                        <material.Typography>You're about to finalize the current treatment session for {openWarningDialog?.patientData?.patientId}</material.Typography>
                    </div>
                    <div className='mt-2'>
                        <span className='fw-bold'>Please confirm:</span>
                        <div>
                            <span>
                                All treatments and procedures for this session have been completed.
                                Patient records are updated with today's date and session details.
                                Any follow-up actions are noted.
                            </span>
                        </div>
                    </div>
                    <div>
                        <material.Typography className='fw-bold'>Would you like to mark this treatment session as finalized?</material.Typography>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end p-3'>
                        <material.Button variant="outlined" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={cancel}>
                            No
                        </material.Button>
                        <material.Button variant="outlined" sx={{ textTransform: "none" }} size="medium" onClick={usedTreatment}>
                            Yes
                        </material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(PreSessionWarning);