import React, { useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toastrmessage/Snackbar';
import { uploadClinicLogo } from '../../../services/ClinicService';

function UploadLogo(props) {

    const { userDetails, clinicDetails } = props;
    const { register, handleSubmit, reset, control, setValue, watch, getValues, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectFolder, setSelectFolder] = useState(null);

    const chooseFile = (e) => {
        let fileName = e.target.files;
        const file = fileName[0];
        if (file) {
            const validFileTypes = ['image/png'];
            if (validFileTypes.includes(file.type)) {
                setButtonDisabled(false);
                setSelectFolder(file);
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "warning",
                    "message": "Invalid file type",
                })
                setButtonDisabled(true);
            }
        }
    };

    const uploadImage = () => {
        let data = new FormData();
        data.append('image', selectFolder);
        data.append('orgId', userDetails.orgId);
        data.append('clinicId', clinicDetails.clinicId);
        uploadClinicLogo(data)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setButtonDisabled(true)
                setSelectFolder(null)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "",
                })
            })
    };

    const cancel = () => {
        setButtonDisabled(true)
        setSelectFolder(null)
    };

    return (
        <div>
            <div className='d-flex justify-content-center align-item-center'>
                <span>
                    <material.InputLabel>Choose Image*</material.InputLabel>
                    <input
                        required
                        type="file"
                        label="Choose Image"
                        accept=".png"
                        margin="dense"
                        onChange={chooseFile}
                    />
                    <span className='mt-5'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="medium" color='error' startIcon={<material.CloseIcon />} onClick={cancel}>Cancel</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", }} size="medium" color='success' startIcon={<material.DoneIcon />} onClick={handleSubmit(uploadImage)} disabled={buttonDisabled}>Upload</material.Button>
                    </span>
                </span>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default UploadLogo;