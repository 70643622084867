import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomToolbar = () => (
    <div id="toolbar">
        {/* Add custom toolbar buttons */}
        <select className="ql-header" defaultValue="" onChange={e => e.persist()}>
            <option value="1">Heading 1</option>
            <option value="2">Heading 2</option>
            <option value="">Normal</option>
        </select>

        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />

        <select className="ql-color">
            <option value="red" />
            <option value="green" />
            <option value="blue" />
            <option value="orange" />
            <option value="violet" />
            <option value="#d0d1d2" />
            <option value="#000101" />
        </select>

        <select className="ql-background">
            <option value="yellow" />
            <option value="lime" />
            <option value="cyan" />
        </select>

        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />

        <button className="ql-link" />
        <button className="ql-image" />

        <select className="ql-align">
            <option defaultValue />
            <option value="center" />
            <option value="right" />
            <option value="justify" />
        </select>
    </div>
);

function TextEditor(props) {

    const { editorContent, setEditorContent } = props;

    const modules = {
        toolbar: {
            container: '#toolbar',
        },
    };

    const formats = [
        'header', 'bold', 'italic', 'underline', 'color', 'background', 'list', 'bullet', 'link', 'image', 'align',
    ];

    return (
        <div>
            <CustomToolbar />
            <ReactQuill
                value={editorContent}
                onChange={setEditorContent}
                modules={modules}
                formats={formats}
                placeholder="Write something amazing..."
            />
        </div>
    );
}

export default TextEditor;