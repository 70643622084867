import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import Snackbar from '../toastrmessage/Snackbar';
import { useForm } from 'react-hook-form';
import { addPatientHistory } from '../../services/PatientService';
import moment from 'moment';
import { connect } from 'react-redux';
import PostSessionNote from './PostSessionNote';
import { getSkinTreatmentNotes } from '../../services/SkinTreatmentService';

function SkinTreatmentNote(props) {

    const { addSkinTreatmentNots, setAddSkinTreatmentNots, userData, setOpenConsentForm, callFrom, setOpenPhotoUploadDialog } = props;
    const userDetails = userData.authReducer.data;
    const [addPostSessionNots, setAddPostSessionNots] = useState({ action: false, patientData: null, note: null });
    const { register, handleSubmit, resetField, reset, formState: { errors, isValid }, setValue } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [nextStep, setNextStep] = useState(false);

    useEffect(() => {
        if (addSkinTreatmentNots.action === true && addSkinTreatmentNots.patientData?.skinTreatmentSessionId) {
            getSkinTreatmentNotesBySessionId();
        }
    }, [addSkinTreatmentNots.action === true]);

    const getSkinTreatmentNotesBySessionId = () => {
        getSkinTreatmentNotes(addSkinTreatmentNots.patientData.skinTreatmentSessionId)
            .then((resp) => {
                reset(resp.data?.settings?.history);
                if (resp.data?.settings?.history) {
                    setNextStep(true);
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const cancel = () => {
        setAddSkinTreatmentNots({ action: false, patientData: null });
        reset({ "notes": "" });
    };

    const save = async (formData) => {
        let obj = {
            typeOfNotes: "DERMAL_THERAPISTS_NOTES",
            patientId: addSkinTreatmentNots.patientData.patientId,
            dateOfEntry: moment().format("YYYY-MM-DDTHH:mm:ss"),
            skinTreatmentSessionId: addSkinTreatmentNots.patientData?.skinTreatmentSessionId,
            noteDefinition: (callFrom === "skin_treatment_details" || callFrom === "Add Photo") ? "Settings" : "Patient-Profile",
            history: formData,
            user: userDetails.name,
        }
        await addPatientHistory(obj)
            .then((resp) => {
                reset({ "notes": "" })
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Save successfully",
                })
                if (callFrom === "Add Photo") {
                    setAddPostSessionNots({ action: true, patientData: addSkinTreatmentNots.patientData, note: null });
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "",
                })
            })
    };

    const nextFlow = () => {
        setAddPostSessionNots({ action: true, patientData: addSkinTreatmentNots.patientData, note: null });
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="md" open={addSkinTreatmentNots.action} hideBackdrop >
                <material.DialogTitle>Settings</material.DialogTitle>
                <material.DialogContent>
                    <div className='row m-2'>
                        <material.TextField
                            id="standard-error"
                            variant="standard"
                            type="text"
                            // label="Skin Treatment Notes"
                            size="small"
                            multiline
                            fullWidth
                            {...register("notes", { required: true })}
                        />
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end'>
                        <material.Button variant="outlined" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={cancel} startIcon={<material.CloseIcon />}>
                            Cancel
                        </material.Button>
                        <material.Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} size="medium" onClick={handleSubmit(save)} disabled={!isValid}>
                            Save
                        </material.Button>
                        <material.Button sx={{ textTransform: "none" }} hidden={!nextStep || callFrom === "skin_treatment_details"} size='medium' variant="outlined" onClick={nextFlow}>Next</material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <PostSessionNote
                addPostSessionNots={addPostSessionNots}
                setAddPostSessionNots={setAddPostSessionNots}
                setOpenConsentForm={setOpenConsentForm}
                setAddSkinTreatmentNots={setAddSkinTreatmentNots}
                callFrom="Settings"
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(SkinTreatmentNote);