import React, { useEffect, useState } from "react";
import AddEmployeePayrollHoursDetails from "./AddEmployeePayrollHoursDetails";
import { material } from "../../../library/material";
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import Snackbar from "../../toastrmessage/Snackbar";
import Navbar from "../../navbar/Navbar";
import { connect } from "react-redux";
import {
  approveEmployeePayrollHours,
  getEmployeePayrollHours,
} from "../../../services/EmployeePayrollHoursServices";
import { useTheme } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

var allMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function EmployeePayrollHoursDetails(props) {
  const { userData, clinicData } = props;
  const userDetails = userData.authReducer.data;
  const clinicDetails = clinicData.clinicReducer.data;
  const theme = useTheme();
  const [openEmployeePayrollHours, setOpenEmployeePayrollHours] = useState({
    action: false,
    employeePayrollHoursData: null,
  });
  const [employeePayrollHoursTableData, setEmployeePayrollHoursTableData] =
    useState([]);
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });
  const [filters, setFilters] = useState({
    employeeName: "",
    month: "",
  });
  const [selectAll, setSelectAll] = useState(false);
  const [allDataId, setAllDataId] = useState([]);

  useEffect(() => {
    getAllEmployeePayrollHours();
  }, []);

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      const unapprovedIds = employeePayrollHoursTableData
        .filter((item) => !item.isApprove)
        .map((item) => item.id);
      setAllDataId(unapprovedIds);
    } else {
      setAllDataId([]);
    }
  };

  const handleItemChange = (id) => {
    setAllDataId((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const searchByUserName = (value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, employeeName: value };
      getAllEmployeePayrollHours(updatedFilters);
      return updatedFilters;
    });
  };
  const searchMonthUserData = (event, newValue) => {
    const eventMonthData = newValue;
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, month: eventMonthData };
      getAllEmployeePayrollHours(updatedFilters);
      return updatedFilters;
    });
  };

  const AddEmployeePayrollHours = () => {
    setOpenEmployeePayrollHours({
      action: true,
      employeePayrollHoursData: null,
    });
  };

  const approve = () => {
    const payload = allDataId.map((id) => ({
      id,
      isApprove: true,
    }));
    approveEmployeePayrollHours(payload)
      .then((resp) => {
        setAllDataId([]);
        setOpenSnackBar({
          action: true,
          type: "success",
          message: "Time sheets approve successfully.",
        });
        getAllEmployeePayrollHours();
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.message,
        });
      });
  };

  const getAllEmployeePayrollHours = async (searchData) => {
    let payload = {
      clinicId: clinicDetails.clinicId,
      orgId: userDetails.orgId,
      month: searchData ? searchData.month : "",
      employeeName: searchData ? searchData.employeeName : "",
    };
    await getEmployeePayrollHours(payload)
      .then((resp) => {
        setEmployeePayrollHoursTableData(resp.data);
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error?.response?.data,
        });
      });
  };

  const editPayrollHours = (row) => {
    setOpenEmployeePayrollHours({
      action: true,
      employeePayrollHoursData: row,
    });
  };

  return (
    <div>
      <material.Grid
        className="container-fluid"
        sx={{
          [theme.breakpoints.down("sm")]: { mt: 10 },
          [theme.breakpoints.up("sm")]: { mt: 10 },
          [theme.breakpoints.up("md")]: { mt: 10 },
          [theme.breakpoints.up("lg")]: { mt: 10 },
        }}
      >
        <Navbar />
        <div className="row">
          <div className="col-3">
            <material.Autocomplete
              id="area"
              onChange={searchMonthUserData}
              options={allMonth}
              renderInput={(params) => <material.TextField fullWidth {...params} required variant="standard" label="Search User Month Wise"
              />}
            />
          </div>
          <div className="col-3">
            {userDetails.role.includes("MANAGER") ? (
              <span style={{ marginLeft: 5 }}>
                <material.TextField
                  fullWidth
                  variant="standard"
                  label="Search User by Name"
                  onChange={(e) => searchByUserName(e.target.value)}
                  sx={{ mb: 2 }}
                />
              </span>
            ) : null}
          </div>

          <div className="col-6 mt-3">
            <span>
              {userDetails.role.includes("MANAGER") ? (
                <material.FormControlLabel
                  control={
                    <material.Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  }
                  label="Select All"
                />
              ) : null}
            </span>
            <span className="float-end">
              {userDetails.role.includes("MANAGER") ? (
                <material.Button
                  onClick={approve}
                  variant="contained"
                  color="success"
                  disabled={allDataId.length === 0}
                  sx={{
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <VerifiedUserIcon sx={{ mr: 1 }} /> Approve
                </material.Button>
              ) : null}
            </span>
            <span className="float-end">
              {userDetails.role.includes("INJECTOR") || userDetails.role.includes("THERAPIST") ? (
                <material.Button onClick={AddEmployeePayrollHours} variant="contained" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.AddIcon />}>Add Employee Payroll Hours</material.Button>
              ) : null}
            </span>
          </div>
          <div className="mt-3">
            <material.TableContainer>
              <material.Table>
                <material.TableHead>
                  <material.TableRow>
                    <StyledTableCell>Employee Name</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Entry Time</StyledTableCell>
                    <StyledTableCell>Exit Time</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Break</StyledTableCell>
                    {userDetails.role.includes("MANAGER") ? (
                      <StyledTableCell>Work Hours</StyledTableCell>
                    ) : null}
                    <StyledTableCell>Comments</StyledTableCell>
                    {userDetails.role.includes("MANAGER") ? (
                      <StyledTableCell>Action</StyledTableCell>
                    ) : null}
                  </material.TableRow>
                </material.TableHead>
                <material.TableBody>
                  {employeePayrollHoursTableData.length ? (
                    employeePayrollHoursTableData.map((data, i) => (
                      <material.TableRow key={i}>
                        <material.TableCell>
                          {data.employeeName}
                        </material.TableCell>
                        <material.TableCell>{data.date}</material.TableCell>
                        <material.TableCell>
                          {data.entryTime}
                        </material.TableCell>
                        <material.TableCell>{data.exitTime}</material.TableCell>
                        <material.TableCell>{data.type}</material.TableCell>
                        <material.TableCell>
                          {data.breakTime}
                        </material.TableCell>
                        {userDetails.role.includes("MANAGER") ? (
                          <material.TableCell>
                            {data.workHour}
                          </material.TableCell>
                        ) : null}
                        <material.TableCell>{data.comments}</material.TableCell>
                        {userDetails.role.includes("MANAGER") ? (
                          <material.TableCell>
                            <material.IconButton
                              disabled={data.isApprove}
                              title="Edit Treatment"
                              aria-label="create"
                              size="large"
                              onClick={() => editPayrollHours(data)}
                            >
                              <material.CreateIcon color={data.isApprove ? "disabled" : "primary"} />
                            </material.IconButton>
                            <material.FormControlLabel
                              disabled={data.isApprove === false ? false : true}
                              control={
                                <material.Checkbox
                                  name="item2"
                                  //checked={data.checked}
                                  checked={allDataId.includes(data.id)}
                                  onChange={() => handleItemChange(data.id)}
                                  disabled={data.isApprove}
                                />
                              }
                            // label="Select"
                            />
                          </material.TableCell>
                        ) : null}
                      </material.TableRow>
                    ))
                  ) : (
                    <material.TableRow>
                      <material.TableCell colSpan={12}>
                        <h6 className="d-flex justify-content-center text-danger fw-bold">
                          No data found
                        </h6>
                      </material.TableCell>
                    </material.TableRow>
                  )}
                </material.TableBody>
              </material.Table>
            </material.TableContainer>
          </div>
        </div>
        <AddEmployeePayrollHoursDetails
          openEmployeePayrollHours={openEmployeePayrollHours}
          setOpenEmployeePayrollHours={setOpenEmployeePayrollHours}
          getAllEmployeePayrollHours={getAllEmployeePayrollHours}
          userDetails={userDetails}
          clinicDetails={clinicDetails}
        // handleUpdateApprove={handleUpdateApprove}
        />
        <Snackbar
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
        />
      </material.Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state,
    clinicData: state,
  };
};

export default connect(mapStateToProps)(EmployeePayrollHoursDetails);
