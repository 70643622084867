import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { Controller, useForm } from 'react-hook-form';
import { getTreatmentListByClinicId } from '../../../../services/TreatmentListService';
import Snackbar from '../../../toastrmessage/Snackbar';
import { getSkinTreatmentPriceListForBookingByClinicId } from '../../../../services/SkinTreatmentService';
import { savePlanDetails } from '../../../../services/TreatmentPlanService';
import { debounce } from 'lodash';

function AddSkinAndCosmeticTreatment(props) {

    const { clinicDetails, userDetails, openAddCosmeticAndSkinTreatment, setOpenAddCosmeticAndSkinTreatment, getProposedTreatmentPlan } = props;
    const { register, handleSubmit, getValues, control, watch, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [treatmentNameOptions, setTreatmentNameOptions] = useState([]);
    const [treatmentList, setTreatmentList] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [selectedTreatmentName, setSelectedTreatmentName] = useState([]);
    const days = [...Array(31).keys()].map((day) => (day + 1).toString());
    const selectDateFormat = ['Day', 'Week', "Month", 'Year'];

    useEffect(() => {
        if (openAddCosmeticAndSkinTreatment.action && openAddCosmeticAndSkinTreatment.openFor === "Cosmetic") {
            getCosmeticTreatmentList()
        } else if (openAddCosmeticAndSkinTreatment.action && openAddCosmeticAndSkinTreatment.openFor === "Skin") {
            getSkinTreatmentList()
        }
    }, [openAddCosmeticAndSkinTreatment.action === true]);


    useEffect(() => {
        const calculateTotalCost = debounce(() => {
            selectedTreatmentName.forEach((treatmentName) => {
                const costPerSession = watch(`${treatmentName}_costPerSession`);
                const totalSessions = watch(`${treatmentName}_totalNumberOfSessionsRequired`);
                if (costPerSession && totalSessions) {
                    const totalCost = parseFloat(costPerSession) * parseInt(totalSessions);
                    setValue(`${treatmentName}_totalCost`, totalCost.toFixed(2));
                }
            });
        }, 300);

        calculateTotalCost();
        return () => {
            calculateTotalCost.cancel();
        };
    }, [selectedTreatmentName, watch, setValue,
        ...selectedTreatmentName.map(treatmentName => watch(`${treatmentName}_costPerSession`)),
        ...selectedTreatmentName.map(treatmentName => watch(`${treatmentName}_totalNumberOfSessionsRequired`)),
    ]);

    const getCosmeticTreatmentList = async () => {
        getTreatmentListByClinicId(clinicDetails.clinicId, userDetails.orgId)
            .then((resp) => {
                setTreatmentList(resp.data);
                const treatmentName = resp.data.length ? resp.data.map((x) => x.treatmentName) : [];
                setTreatmentNameOptions(treatmentName)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const getSkinTreatmentList = () => {
        getSkinTreatmentPriceListForBookingByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                const skinTreatment = resp.data.map((x) => {
                    return {
                        treatmentName: x.treatmentName,
                        treatmentArea: x.treatmentDetails.map((x) => x.treatmentArea),
                    };
                });
                setTreatmentList(skinTreatment);
                const treatmentName = skinTreatment.length ? skinTreatment.map((x) => x.treatmentName) : [];
                setTreatmentNameOptions(treatmentName)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const getTreatmentAreaOptions = (treatmentName) => {
        const treatment = treatmentList.find(ele => ele.treatmentName === treatmentName);
        return treatment ? treatment.treatmentArea : [];
    };

    const saveTreatment = (data) => {
        const formData = { ...data, recordType: openAddCosmeticAndSkinTreatment.openFor === "Cosmetic" ? "Cosmetic_Injectable" : "Skin_Treatment" };
        const planId = openAddCosmeticAndSkinTreatment.planData.id;
        const array = [];
        for (const element of formData.treatmentName) {
            let temp = {}
            temp.treatmentOrProductName = element;
            temp.treatmentAreaOrProductInstructions = formData[element + "_treatmentArea"]?.length > 1 ? formData[element + "_treatmentArea"].join(",") : formData[element + "_treatmentArea"][0];
            temp.rational = formData.rational;
            temp.recordType = formData.recordType;
            temp.slNo = formData[element + "_slNo"];
            temp.proposedPlanId = planId;
            temp.sessionNumber = formData[element + "_sessionNumber"];
            temp.totalNumberOfSessionsRequired = formData[element + "_totalNumberOfSessionsRequired"];
            temp.days = formData[element + "_days"] !== "" ? formData[element + "_days"] : 0;
            temp.dateFormat = formData[element + "_dateFormat"];
            temp.routine = formData[element + "_routine"];
            temp.costPerSession = formData[element + "_costPerSession"];
            temp.totalCost = formData[element + "_totalCost"];
            temp.comment = formData[element + "_comment"];
            array.push(temp);
        }
        savePlanDetails(array)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data,
                });
                getProposedTreatmentPlan(planId);
                reset();
                reset({ rational: "" });
                setSelectedTreatmentName([]);
                setOpenAddCosmeticAndSkinTreatment({ action: false, patientData: null, planData: null, openFor: null });
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const cancel = () => {
        reset();
        reset({ rational: "" });
        setSelectedTreatmentName([]);
        setOpenAddCosmeticAndSkinTreatment({ action: false, patientData: null, planData: null, openFor: null });
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="xl" open={openAddCosmeticAndSkinTreatment.action} hideBackdrop>
                <material.DialogTitle>{openAddCosmeticAndSkinTreatment.openFor === "Cosmetic" ? "Cosmetic Injectable" : "Skin Treatment"}</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-6">
                            <Controller
                                name="treatmentName"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <material.Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        {...field}
                                        value={field.value || []}
                                        options={treatmentNameOptions}
                                        renderInput={(params) => (
                                            <material.TextField
                                                required
                                                margin="dense"
                                                {...params}
                                                variant="standard"
                                                label="Treatment Name"
                                                fullWidth
                                            />
                                        )}
                                        onChange={(_, data) => {
                                            field.onChange(data);
                                            setSelectedTreatmentName(data);
                                            data.forEach(name => {
                                                setValue(`${name}_treatmentName`, name);
                                                setValue(`${name}_slNo`, "");
                                                setValue(`${name}_treatmentArea`, "");
                                                setValue(`${name}_routine`, "");
                                                setValue(`${name}_days`, "");
                                                setValue(`${name}_dateFormat`, "");
                                                setValue(`${name}_totalNumberOfSessionsRequired`, "");
                                                setValue(`${name}_sessionNumber`, "");
                                            })
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <material.TextField
                                {...register("rational")}
                                margin="dense"
                                id="name"
                                label="Rational"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                    </div>
                    {selectedTreatmentName.length ? selectedTreatmentName.map((treatmentName, ind) => {
                        // setValue(`${treatmentName}_treatmentName`, treatmentName)
                        return (
                            <div className="row border border-2 p-2 my-2 rounded-2 mt-2" key={ind} style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                                <div className="col-lg-2 col-md-4">
                                    <material.TextField
                                        {...register(`${treatmentName}_sessionNumber`, { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="Session Number"
                                        type="number"
                                        fullWidth
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <material.TextField
                                        {...register(`${treatmentName}_slNo`, { required: true })}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="Treatment Number"
                                        type="number"
                                        fullWidth
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <material.TextField
                                        {...register(`${treatmentName}_treatmentName`)}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="Treatment Name"
                                        type="text"
                                        fullWidth
                                        defaultValue={treatmentName}
                                        InputProps={{ readOnly: true }}
                                        variant="standard"
                                    />
                                </div>
                                <div className='col-4'>
                                    <Controller
                                        name={`${treatmentName}_treatmentArea`}
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <material.Autocomplete
                                                multiple
                                                disableCloseOnSelect
                                                {...field}
                                                value={field.value || []}
                                                options={getTreatmentAreaOptions(watch(`${treatmentName}_treatmentName`))}
                                                renderInput={(params) => (
                                                    <material.TextField
                                                        margin="dense"
                                                        {...params}
                                                        variant="standard"
                                                        label="Treatment Area"
                                                        fullWidth
                                                    />
                                                )}
                                                onChange={(_, data) => field.onChange(data)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <material.TextField
                                        {...register(`${treatmentName}_totalNumberOfSessionsRequired`, { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="Total Number of Session"
                                        type="number"
                                        fullWidth
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <material.TextField
                                        {...register(`${treatmentName}_costPerSession`, { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="Cost Per Session"
                                        type="number"
                                        fullWidth
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <material.TextField
                                        {...register(`${treatmentName}_totalCost`, { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,4})?$/ })}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="Total Cost"
                                        type="number"
                                        fullWidth
                                        variant="standard"
                                        InputProps={{ readOnly: true }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <material.TextField
                                        {...register(`${treatmentName}_comment`)}
                                        margin="dense"
                                        id="name"
                                        label="Comment"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="row border border-2 p-2 my-2 rounded-2 mt-2" key={ind} style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                                        <span className='fw-bold'>Repeat</span>
                                        <div className="col-lg-2 col-md-4">
                                            <Controller
                                                name={`${treatmentName}_days`}
                                                control={control}
                                                defaultValue=""
                                                // rules={{ required: true }}
                                                render={({ field }) => (
                                                    <material.Autocomplete
                                                        {...field}
                                                        options={days}
                                                        renderInput={(params) => (
                                                            <material.TextField
                                                                required
                                                                margin="dense"
                                                                {...params}
                                                                variant="standard"
                                                                // label="Days"
                                                                fullWidth
                                                            />
                                                        )}
                                                        onChange={(event, data) => {
                                                            field.onChange(data);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-4">
                                            <Controller
                                                name={`${treatmentName}_dateFormat`}
                                                control={control}
                                                defaultValue=""
                                                // rules={{ required: true }}
                                                render={({ field }) => (
                                                    <material.Autocomplete
                                                        {...field}
                                                        options={selectDateFormat}
                                                        renderInput={(params) => (
                                                            <material.TextField
                                                                required
                                                                margin="dense"
                                                                {...params}
                                                                variant="standard"
                                                                // label="Date Format"
                                                                fullWidth
                                                            />
                                                        )}
                                                        onChange={(event, data) => {
                                                            field.onChange(data);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : ""}
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(saveTreatment)} disabled={!isValid} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddSkinAndCosmeticTreatment;