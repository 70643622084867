import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { getAllPatientByClinicId, getAllPAtients } from '../../../services/PatientService';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { saveCustomCampaignList } from '../../../services/CampaignService';
import Snackbar from '../../toastrmessage/Snackbar';

const label = { inputProps: { 'aria-label': 'Color switch demo', 'aria-label2': 'Checkbox demo' } };

function PatientSelection(props) {

    const { openPatientSelection, setOpenPatientSelection, clinicDetails } = props;
    const [allPatientList, setAllPatientList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPatients, setSelectedPatients] = useState([]);
    const [templateName, setTemplateName] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
        if (openPatientSelection.action) {
            getAllPatientList()
            setSelectedPatients([]);
            setTemplateName("");
        };
    }, [openPatientSelection.action === true]);

    const getAllPatientList = (paginationData) => {
        setIsLoading(true);
        // getAllPAtients(openPatientSelection.clinicData?.clinicId)
        //     .then((resp) => {
        //         setAllPatientList(resp.data);
        //         setIsLoading(false);
        //     })
        //     .catch((error) => {
        //         setOpenSnackBar({
        //             "action": true,
        //             "type": "error",
        //             "message": error.response.data,
        //         })
        //     })
        const payload = {
            limit: paginationData ? paginationData.limit : 10,
            page: paginationData ? paginationData.page : 0,
            clinicId: clinicDetails.clinicId,
            search: paginationData ? paginationData.search : ""
        }
        getAllPatientByClinicId(payload)
            .then((res) => {
                setAllPatientList(res.data.patients);
                setPage(Number(res.data.currentPage));
                setRowsPerPage(res.data.limit);
                setTotalElements(res.data.totalItems);
                setIsLoading(false)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message,
                })
            })
    };

    const cancel = () => {
        setOpenPatientSelection({ action: false, clinicData: null });
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedPatients(allPatientList.map(patient => patient.patientId));
        } else {
            setSelectedPatients([]);
        }
    };

    const handleCheckboxChange = (patientId) => {
        setSelectedPatients(prevSelected =>
            prevSelected.includes(patientId)
                ? prevSelected.filter(id => id !== patientId)
                : [...prevSelected, patientId]
        );
    };

    const isPatientSelected = (patientId) => selectedPatients.includes(patientId);

    const getSelectedPatientsData = () => {
        const patientData = allPatientList.filter(patient => selectedPatients.includes(patient.patientId));
        let data = [];
        data = patientData.map((ele) => {
            let obj = {
                listName: templateName,
                ...ele
            }
            return obj;
        })
        if (data.length) {
            saveCustomCampaignList(data)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    setTemplateName("");
                    setOpenPatientSelection({ action: false, clinicData: null });
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "error",
                "message": "No Patient Selected",
            })
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getAllPatientList(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getAllPatientList(payload)
    };

    const searchPatient = (value) => {
        const payload = {
            search: value
        }
        getAllPatientList(payload)
    };

    return (
        <div>
            <material.Dialog open={openPatientSelection.action} fullWidth maxWidth="xl" hideBackdrop>
                <material.DialogTitle>Patient List</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-3 mt-3">
                            <material.TextField
                                required
                                id="subject"
                                label="List Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(e) => setTemplateName(e.target.value)}
                                sx={{ mt: -2 }}
                            />
                        </div>
                        <div className="col-3">
                            <material.TextField
                                fullWidth
                                variant="standard"
                                label="Search Patient"
                                onChange={(e) => searchPatient(e.target.value)}
                            />
                        </div>
                        <div className="col-3 mt-3">
                            <material.FormGroup>
                                <material.FormControlLabel control={<material.Checkbox color='secondary' />} label="Select All Patient Visible Patient" onChange={handleSelectAll} />
                            </material.FormGroup>
                        </div>
                    </div>
                    <material.Paper elevation={3}>
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Phone No</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {allPatientList.length ? allPatientList.map((patient, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell>{patient.name}</material.TableCell>
                                                    <material.TableCell>{patient.email}</material.TableCell>
                                                    <material.TableCell>{patient.phoneNumber}</material.TableCell>
                                                    <material.TableCell>
                                                        <material.Checkbox {...label}
                                                            checked={isPatientSelected(patient.patientId)}
                                                            onChange={() => handleCheckboxChange(patient.patientId)}
                                                            color="secondary" />
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button sx={{ textTransform: "none", mr: 1 }} variant="outlined" color='error' onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button sx={{ textTransform: "none" }} variant="outlined" onClick={getSelectedPatientsData} disabled={templateName === ""} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default PatientSelection;