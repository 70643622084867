import React, { useState, useRef } from 'react';
import { material } from '../../library/material';
import { getTodayScheduleByInjectorId } from '../../services/AppointmentService';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { StyledTableCell } from '../../shared/TableHeaderStyle';

function ViewTodayScheduleData(props) {

    const { openViewSchedule, setOpenViewSchedule } = props;
    const componentRef = useRef();
    const [todayScheduleData, setTodayScheduleData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [printContent, setPrintContent] = useState("");

    const selectInjector = (e, value) => {
        getTodayScheduleData(value.resourceId)
    };

    const getTodayScheduleData = (userId) => {
        setIsLoading(true)
        getTodayScheduleByInjectorId(userId)
            .then((resp) => {
                setTodayScheduleData(resp.data.schedules)
                setIsLoading(false)
                setPrintContent(resp.data.htmlContent)
            })
            .catch((error) => {

            })
    };

    const close = () => {
        setOpenViewSchedule({ action: false, injectorData: null });
        setTodayScheduleData([])
    };

    const printTable = useReactToPrint({
        content: () => componentRef.current,
    });

    // const printTable = () => {
    //     const newTab = window.open("", "_blank")
    //     newTab.document.write(resp.data.htmlContent)
    // };

    return (
        <div>
            <material.Dialog maxWidth="lg" fullWidth hideBackdrop open={openViewSchedule.action} ref={componentRef}>
                <material.DialogTitle className='d-flex justify-content-between'>Today's Schedule
                    <span>
                        <material.IconButton color='error' title='Close' onClick={close}>
                            <material.CloseIcon />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className="col-4">
                            <material.Autocomplete
                                id="name"
                                margin="dense"
                                onChange={selectInjector}
                                options={openViewSchedule?.injectorData ? openViewSchedule.injectorData : []}
                                getOptionLabel={(option) => option.resourceTitle}
                                renderInput={(params) => <material.TextField {...params} required variant="standard" label="Injector"
                                // {...register("injectorName", { required: true })}
                                />}
                            />
                        </div>
                        <div className='col-lg-2'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={printTable} startIcon={<material.PrintIcon />}>Print</material.Button>
                        </div>
                        <div className='col-12 mt-3'>
                            <material.TableContainer sx={{ maxHeight: 560 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Patient Name</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Phone No.</StyledTableCell>
                                            <StyledTableCell>Purpose</StyledTableCell>
                                            <StyledTableCell>Room</StyledTableCell>
                                            <StyledTableCell>Start Time</StyledTableCell>
                                            <StyledTableCell>End Time</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={7}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={7} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {todayScheduleData.length ? todayScheduleData.map((rowData, i) => (
                                                    <material.TableRow key={i}>
                                                        <material.TableCell>{rowData.PatientName}</material.TableCell>
                                                        <material.TableCell>{rowData.Email}</material.TableCell>
                                                        <material.TableCell>{rowData.PhoneNumber}</material.TableCell>
                                                        <material.TableCell>{rowData.title}</material.TableCell>
                                                        <material.TableCell>{rowData.RoomName}</material.TableCell>
                                                        <material.TableCell>{rowData.start ? moment(rowData.start).format("HH:mm") : ""}</material.TableCell>
                                                        <material.TableCell>{rowData.end ? moment(rowData.end).format("HH:mm") : ""}</material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={7}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default ViewTodayScheduleData;