import React, { useState } from 'react';
import { material } from '../../../library/material';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import 'react-loading-skeleton/dist/skeleton.css';
import DeletePermissionDialog from '../view-patient-details/proposed-treatment/DeletePermissionDialog';
import { saveOrUpdatePackageDetails, saveOrUpdateSinglePackageDetails } from '../../../services/PackageConfigurationService';
import Snackbar from '../../toastrmessage/Snackbar';
import EditSinglePackageDetails from './EditSinglePackageDetails';

function ViewPackageList(props) {

    const { isLoading, getPackageList, clinicDetails, userDetails, getSingleClinicPackages, packageId } = props;
    const [openDeletePermissionDialog, setOpenDeletePermissionDialog] = useState({ action: false, rowData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [openEditPackageDetails, setOpenEditSinglePackageDetails] = useState({ action: false, packageData: null })

    const editPackage = (rowData) => {
        setOpenEditSinglePackageDetails({ action: true, packageData: rowData });
    };

    const deletePackage = (rowData) => {
        setOpenDeletePermissionDialog({ action: true, rowData: rowData })
    };

    const getDeletePermissionData = (data) => {
        const payload = {
            active: false,
            isDeleted: true,
            packageId: data.packageId,
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            id: data.id
        }
        saveOrUpdateSinglePackageDetails(payload)
            .then((resp) => {
                getSingleClinicPackages(data.packageId);
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.message,
                });
                setOpenDeletePermissionDialog({ action: false, rowData: null })
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    return (
        <div className='mt-2'>
            <material.TableContainer>
                <material.Table>
                    <material.TableHead >
                        <material.TableRow>
                            <StyledTableCell>Treatment / Product</StyledTableCell>
                            <StyledTableCell>Treatment Area / Instructions</StyledTableCell>
                            <StyledTableCell>Number of Sessions / Quantity of Product</StyledTableCell>
                            <StyledTableCell>Per Session / Per Unit Cost</StyledTableCell>
                            <StyledTableCell>Total</StyledTableCell>
                            <StyledTableCell>Discount Percentage</StyledTableCell>
                            <StyledTableCell>Discount</StyledTableCell>
                            <StyledTableCell>Total Payable Amount</StyledTableCell>
                            {userDetails.role.includes("ADMIN") || userDetails.role.includes("MANAGER") ? (
                                <StyledTableCell>Action</StyledTableCell>
                            ) : null}
                        </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                        {isLoading ? (
                            <material.TableRow >
                                <material.TableCell colSpan={12}>
                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                        <Skeleton count={12} />
                                    </SkeletonTheme>
                                </material.TableCell>
                            </material.TableRow>
                        ) : (
                            <>
                                {getPackageList?.length ? getPackageList?.map((row, i) => (
                                    <material.TableRow
                                        key={i}
                                        sx={{ backgroundColor: row.isComplementary ? "#f09df09d" : "" }}
                                    >
                                        <material.TableCell>{row.treatmentProductName}</material.TableCell>
                                        <material.TableCell sx={{ width: 100 }}>{row.treatmentProductArea}</material.TableCell>
                                        <material.TableCell>{row.numberOfSession_quantityOfProduct}</material.TableCell>
                                        <material.TableCell>{row.perSessionCost}</material.TableCell>
                                        <material.TableCell>{row.total}</material.TableCell>
                                        <material.TableCell>{row.discountPercentage}</material.TableCell>
                                        <material.TableCell>{row.discount}</material.TableCell>
                                        <material.TableCell>{row.totalPayableAmount}</material.TableCell>
                                        {userDetails.role.includes("ADMIN") || userDetails.role.includes("MANAGER") ? (
                                            <material.TableCell>
                                                <material.IconButton title='Edit Treatment' onClick={() => editPackage(row)}>
                                                    <material.CreateIcon color='info' />
                                                </material.IconButton>
                                                <material.IconButton title='Delete Treatment' onClick={() => deletePackage(row)}>
                                                    <material.DeleteIcon color='error' />
                                                </material.IconButton>
                                            </material.TableCell>
                                        ) : null}
                                    </material.TableRow>
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={12}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </>)}
                    </material.TableBody>
                </material.Table>
            </material.TableContainer>
            <DeletePermissionDialog
                getDeletePermissionData={getDeletePermissionData}
                openDeletePermissionDialog={openDeletePermissionDialog}
                setOpenDeletePermissionDialog={setOpenDeletePermissionDialog}
                title="Package"
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <EditSinglePackageDetails
                openEditPackageDetails={openEditPackageDetails}
                setOpenEditSinglePackageDetails={setOpenEditSinglePackageDetails}
                getSingleClinicPackages={getSingleClinicPackages}
                packageId={packageId}
            />
        </div>
    );
}

export default ViewPackageList;