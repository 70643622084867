import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { StyledTableCell } from '../../../../shared/TableHeaderStyle';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getCashFloatCoinNotesData } from '../../../../services/ClinicConfigService';
import AddCashFloat from './AddCashFloat';
import Snackbar from '../../../toastrmessage/Snackbar';

function CashFloat(props) {

    const { userDetails, clinicDetails } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [cashFloatData, setCashFloatData] = useState([]);
    const [openAddCashFloatDialog, setOpenAddCashFloatDialog] = useState({ action: false, rowData: null })
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getCashFloatData()
    }, []);

    const getCashFloatData = () => {
        setIsLoading(true);
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        getCashFloatCoinNotesData(payload)
            .then((resp) => {
                setCashFloatData(resp.data);
                setIsLoading(false)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data || error.response?.data?.message,
                });
            })
    };

    const addCashFloat = () => {
        setOpenAddCashFloatDialog({ action: true, rowData: null })
    };

    return (
        <div className='row' style={{ marginTop: "-30px" }}>
            <div className="col-12">
                <span className="float-end">
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addCashFloat} startIcon={<material.AddIcon />}>Add Cash Float</material.Button>
                </span>
            </div>
            <div className="col-12 mt-2">
                <material.TableContainer>
                    <material.Table>
                        <material.TableHead>
                            <material.TableRow>
                                <StyledTableCell rowSpan={2} align="center" sx={{ border: 1 }}>Total Amount</StyledTableCell>
                                <StyledTableCell colSpan={5} align="center" sx={{ border: 1 }}>COINS</StyledTableCell>
                                <StyledTableCell colSpan={5} align="center" sx={{ border: 1 }}>Notes</StyledTableCell>
                            </material.TableRow>
                            <material.TableRow>
                                <StyledTableCell align="center" sx={{ border: 1 }}>5 cents</StyledTableCell>
                                <StyledTableCell align="center" sx={{ border: 1 }}>10 cents</StyledTableCell>
                                <StyledTableCell align="center" sx={{ border: 1 }}>50 cents</StyledTableCell>
                                <StyledTableCell align="center" sx={{ border: 1 }}>1 dollar</StyledTableCell>
                                <StyledTableCell align="center" sx={{ border: 1 }}>2 dollar</StyledTableCell>
                                <StyledTableCell align="center" sx={{ border: 1 }}>5 dollar</StyledTableCell>
                                <StyledTableCell align="center" sx={{ border: 1 }}>10 dollar</StyledTableCell>
                                <StyledTableCell align="center" sx={{ border: 1 }}>20 dollar</StyledTableCell>
                                <StyledTableCell align="center" sx={{ border: 1 }}>50 dollar</StyledTableCell>
                                <StyledTableCell align="center" sx={{ border: 1 }}>100 dollar</StyledTableCell>
                            </material.TableRow>
                        </material.TableHead>
                        <material.TableBody>
                            {isLoading ? (
                                <material.TableRow>
                                    <material.TableCell colSpan={10}>
                                        <SkeletonTheme
                                            baseColor="#bbdefb"
                                            highlightColor="#c6ff00"
                                            enableAnimation="true"
                                            inline="true"
                                            width="100% "
                                            height="30px"
                                        >
                                            <Skeleton count={10} />
                                        </SkeletonTheme>
                                    </material.TableCell>
                                </material.TableRow>
                            ) : (
                                <>
                                    {cashFloatData.length ? (
                                        cashFloatData.map((item, k) => (
                                            <material.TableRow
                                                key={k}
                                            >
                                                <material.TableCell align="center">{item.totalAmount}</material.TableCell>
                                                <material.TableCell align="center">{item.coins_5_cents}</material.TableCell>
                                                <material.TableCell align="center">{item.coins_10_cents}</material.TableCell>
                                                <material.TableCell align="center">{item.coins_50_cents}</material.TableCell>
                                                <material.TableCell align="center">{item.coins_1_dollar}</material.TableCell>
                                                <material.TableCell align="center">{item.coins_2_dollar}</material.TableCell>
                                                <material.TableCell align="center">{item.notes_5_dollar}</material.TableCell>
                                                <material.TableCell align="center">{item.notes_10_dollar}</material.TableCell>
                                                <material.TableCell align="center">{item.notes_20_dollar}</material.TableCell>
                                                <material.TableCell align="center">{item.notes_50_dollar}</material.TableCell>
                                                <material.TableCell align="center">{item.notes_100_dollar}</material.TableCell>
                                            </material.TableRow>
                                        ))
                                    ) : (
                                        <material.TableRow>
                                            <material.TableCell colSpan={15}>
                                                <h6 className="d-flex justify-content-center text-danger fw-bold">
                                                    No data found
                                                </h6>
                                            </material.TableCell>
                                        </material.TableRow>
                                    )}
                                </>
                            )}
                        </material.TableBody>
                    </material.Table>
                </material.TableContainer>
            </div>
            <AddCashFloat
                openAddCashFloatDialog={openAddCashFloatDialog}
                setOpenAddCashFloatDialog={setOpenAddCashFloatDialog}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getCashFloatData={getCashFloatData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default CashFloat;