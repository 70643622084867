import React from 'react';
import { material } from '../../../../library/material';

function PermissionDialog(props) {

    const { openPermissionDialog, setOpenPermissionDialog, getPrintTypeValue } = props;

    const atHomeTreatment = () => {
        getPrintTypeValue("yes");
    };

    const entirePlan = () => {
        getPrintTypeValue("no");
    };

    const handleClose = () => {
        setOpenPermissionDialog(false)
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="sm" open={openPermissionDialog}>
                <material.DialogTitle>
                    <span className='float-end'>
                        <material.IconButton title='Close' color='error' onClick={handleClose}>
                            <material.CloseIcon sx={{ cursor: "pointer" }} />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='d-flex justify-content-center'>
                        <span className='mt-2'>Would you like to print ?</span>
                        <span className='ms-3'>
                            <material.Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} color='secondary' onClick={atHomeTreatment}>At Home</material.Button>
                            <material.Button variant="outlined" sx={{ textTransform: "none" }} onClick={entirePlan}>Entire Plan</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default PermissionDialog;