import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Addorganisation from '../components/add_organisation/Addorganisation';
import AddorgByInvite from '../components/add_org_by_invite/AddorgByInvite';
import Admin from '../components/admin/Admin';
import Clinic from '../components/clinic/Clinic';
import Login from '../components/login/Login';
import Usermanagement from '../components/usermanagement/Usermanagement';
import ProtectRoute from '../protect-route/ProtectRoute';
import Dashboard from '../components/dashboard/Dashboard';
import PatientManagement from '../components/dashboard/patient-management/PatientManagement';
import AddPatient from '../components/dashboard/add-patient/AddPatient';
import PatientHistory from '../components/dashboard/patient-history/PatientHistory';
import InjectorList from '../components/dashboard/injector-management/InjectorList';
import TreatmentPlan from '../components/dashboard/treatment-plan/TreatmentPlan';
import ApprovalWating from '../components/dashboard/approval-wating/ApprovalWating';
import ApprovalRequests from '../components/dashboard/approval-requests/ApprovalRequests';
import EditUserMangement from '../components/usermanagement/EditUserManagement';
import TreatmentPlanDetails from '../components/dashboard/treatment-plan-details/TreatmentPlanDetails';
import WatingRoom from '../components/dashboard/wating-room/WatingRoom';
import Stocks from '../components/dashboard/stocks/Stocks';
import TreatmentList from '../components/dashboard/treatment-list/TreatmentList';
import VendorManagement from '../components/dashboard/vendor-management/VendorManagement';
import DocumentManagement from '../components/dashboard/document-management/DocumentManagement';
import InvoiceManagement from '../components/dashboard/invoice-management/InvoiceManagement';
import AppointmentSchedule from '../components/dashboard/appointment-schedule/AppointmentSchedule';
import ConfigureMachines from '../components/dashboard/configure-machine/ConfigureMachines';
import ConfigureRoom from '../components/dashboard/configure-room/ConfigureRoom';
import Appointment from '../components/dashboard/appointment-schedule/Appointment';
import CosmeticInjectables from '../components/dashboard/price-management/CosmeticInjectables';
import SkinTreatmentPriceManagement from '../components/dashboard/price-management/SkinTreatmentPriceManagement';
import StockPurchased from '../components/dashboard/drug-book/StockPurchased';
import StockAdjustment from '../components/dashboard/drug-book/StockAdjustment';
import StockUsed from '../components/dashboard/drug-book/StockUsed';
import StockInHand from '../components/dashboard/drug-book/StockInHand';
import OtherStockPurchased from '../components/dashboard/other-stocks/OtherStockPurchased';
import OtherStock from '../components/dashboard/price-management/OtherStock';
import OtherInvoice from '../components/dashboard/invoice-management/OtherInvoice';
import ComparedImages from '../components/dashboard/treatment-plan-details/ComparedImages';
import OtherStockAdjustment from '../components/dashboard/other-stocks/OtherStockAdjustment';
import AddBDDFormByInviteCode from '../components/add-bdd-form-by-invite-code/AddBDDFormByInviteCode';
import ViewBddForm from '../components/view-bdd-form/ViewBddForm';
import OtherStockInHand from '../components/dashboard/other-stocks/OtherStockInHand';
import OtherStockUsed from '../components/dashboard/other-stocks/OtherStockUsed';
import ConsentFormAW from '../components/dashboard/consent-form/ConsentFormAW';
import ConsentFormDF from '../components/dashboard/consent-form/ConsentFormDF';
import SkinTreatment from '../components/dashboard/treatment-list/SkinTreatment';
import SkinTreatmentBooking from '../components/dashboard/skin-treatment-booking/SkinTreatmentBooking';
import SkinTreatmentList from '../components/dashboard/skin-treatment-booking/SkinTreatmentList';
import ViewPatientDetails from '../components/dashboard/view-patient-details/ViewPatientDetails';
import ProductTreatmentMapping from '../components/dashboard/product-treatment-mapping/ProductTreatmentMapping';
import PatientTreatmentList from '../components/dashboard/skin-treatment-booking/PatientTreatmentList';
import SkinTreatmentDetails from '../components/dashboard/skin-treatment-booking/SkinTreatmentDetails';
import EmailConfig from '../components/dashboard/email-configer/EmailConfig';
import SkinBooster from '../components/dashboard/consent-form/SkinBooster';
import ImageComparedNotes from '../components/dashboard/treatment-plan-details/ImageComparedNotes';
import HyalaseTreatment from '../components/dashboard/consent-form/HyalaseTreatment';
import TabView from '../components/sidenav/TabView';
import ClinicSelector from '../components/clinic/ClinicSelector';
import Campaign from '../components/dashboard/campaign/Campaign';
import EditInjector from '../components/dashboard/injector-management/EditInjector';
import Menu from '../components/kiosk/Menu';
import KioskAddPatient from '../components/kiosk/KioskAddPatient';
import KioskAddPhoto from '../components/kiosk/KioskAddPhoto';
import KioskConsentForm from '../components/kiosk/KioskConsentForm';
import Portfolio from '../components/dashboard/portfolio/Portfolio';
import ClinicConfig from '../components/dashboard/clinic-config/ClinicConfig';
import AddAssessment from '../components/dashboard/view-patient-details/GRS/AddAssessment';
import ViewGRSDetails from '../components/dashboard/view-patient-details/GRS/ViewAssessment';
import LinkGRS from '../components/dashboard/view-patient-details/GRS/LinkGRS';
import ViewPatientPastProcedure from '../components/dashboard/view-patient-details/ViewPatientPastProcedure';
import CreateTemplateDialog from '../components/dashboard/campaign/CreateTemplateDialog';
import CreateAppointmentOutside from '../components/CreateAppointmentOutside/CreateAppointmentOutside';
import InjectorAddHoliday from '../components/dashboard/injector-management/InjectorAddHoliday';
import ViewProtocol from '../components/dashboard/view-protocol/ViewProtocol';
import ViewCurrentOffer from '../components/dashboard/view-current-offer/ViewCurrentOffer';
import PackageList from '../components/dashboard/package-configuration/PackageList';
import PackageInvoice from '../components/dashboard/package-configuration/invoice/PackageInvoice';
import CashFloat from '../components/dashboard/cash-float/CashFloat';
import EditTemplate from '../components/dashboard/campaign/EditTemplate';
import AddOrganizationByInviteCode from '../components/on-boarding/AddOrganizationByInviteCode';
import EmployeePayrollHoursDetails from '../components/dashboard/employee-payroll-hours-details/EmployeePayrollHoursDetails';
import ManageOnBoarding from '../components/on-boarding/ManageOnBoarding';
import OnBoardingChecklist from '../components/on-boarding/OnBoardingChecklist';
import NursesAgreement from '../components/on-boarding/agreement-form/NursesAgreement';
import ConsultantAgreement from '../components/on-boarding/agreement-form/ConsultantAgreement';
import DocumentUpload from '../components/on-boarding/supporting-document-upload/DocumentUpload';
import AddPrescriberByInviteCode from '../components/on-boarding/AddPrescriberByInviteCode';
import UploadDocumentForAdmin from '../components/on-boarding/supporting-document-upload/UploadDocumentForAdmin';

export default function Routing() {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Navigate to="/login" />} />
                <Route path='/login' element={<Login />} />
                <Route path='/on_boarding' element={<AddOrganizationByInviteCode />} />
                <Route path='/p_on_boarding' element={<AddPrescriberByInviteCode />} />
                <Route path='/manage-onboard' element={<ProtectRoute Component={ManageOnBoarding} />} />
                <Route path='/manage-onboard/checklist' element={<ProtectRoute Component={OnBoardingChecklist} />} />
                <Route path='/card-component' element={<TabView />} />
                <Route path='/clinic-selector' element={<ProtectRoute Component={ClinicSelector} />} />
                <Route path='/organization' element={<ProtectRoute Component={Admin} />} />
                <Route path='/clinic/add_clinic' element={<ProtectRoute Component={Addorganisation} />} />
                <Route path='/organization/add_organization' element={<ProtectRoute Component={Addorganisation} />} />
                <Route path='/clinic' element={<ProtectRoute Component={Clinic} />} />
                <Route path='/user_management' element={<ProtectRoute Component={Usermanagement} />} />
                <Route path='/user_management/edit-user_management' element={<ProtectRoute Component={EditUserMangement} />} />
                <Route path='/registerorganisation' element={<AddorgByInvite />} />
                <Route path='/bdd_form' element={<AddBDDFormByInviteCode />} />
                <Route path='/current_offers' element={<ProtectRoute Component={ViewCurrentOffer} />} />
                <Route path='/protocol' element={<ProtectRoute Component={ViewProtocol} />} />


                <Route path='/create-appointment/:orgId/:clinicId/:injectorId/:campaignTag' element={<CreateAppointmentOutside />} />
                <Route path='/dashboard' element={<ProtectRoute Component={Dashboard} />} />
                <Route path='/clinic_configuration' element={<ProtectRoute Component={ClinicConfig} />} />
                <Route path='/portfolio' element={<ProtectRoute Component={Portfolio} />} />
                <Route path='/patient-list' element={<ProtectRoute Component={PatientManagement} />} />
                <Route path='/patient-list/add-patient' element={<ProtectRoute Component={AddPatient} />} />
                <Route path='/patient-list/view-patient-details' element={<ProtectRoute Component={ViewPatientDetails} />} />
                <Route path='/patient-list/view-patient-details/patient_skin_treatment_list' element={<ProtectRoute Component={PatientTreatmentList} />} />
                <Route path='/patient-list/view-patient-details/patient_skin_treatment_list/treatment_details' element={<ProtectRoute Component={SkinTreatmentDetails} />} />
                <Route path='/patient-list/view-patient-details/edit-patient' element={<ProtectRoute Component={AddPatient} />} />
                <Route path='/patient-list/edit-patient/view-bdd-form' element={<ProtectRoute Component={ViewBddForm} />} />
                <Route path='/patient-list/edit-patient/treatment-plan' element={<ProtectRoute Component={TreatmentPlan} />} />
                <Route path='/patient-list/edit-patient/treatment-plan/view-bdd-form' element={<ProtectRoute Component={ViewBddForm} />} />
                <Route path='/patient-list/view-patient-details/treatment-plan' element={<ProtectRoute Component={TreatmentPlan} />} />
                {/* /////// */}
                <Route path='/user_management/patient-list' element={<ProtectRoute Component={PatientManagement} />} />
                <Route path='/user_management/patient-list/add-patient' element={<ProtectRoute Component={AddPatient} />} />
                <Route path='/user_management/patient-list/view-patient-details' element={<ProtectRoute Component={ViewPatientDetails} />} />
                <Route path='/user_management/patient-list/view-patient-details/patient_skin_treatment_list' element={<ProtectRoute Component={PatientTreatmentList} />} />
                <Route path='/user_management/patient-list/view-patient-details/patient_skin_treatment_list/treatment_details' element={<ProtectRoute Component={SkinTreatmentDetails} />} />
                <Route path='/user_management/patient-list/edit-patient' element={<ProtectRoute Component={AddPatient} />} />
                <Route path='/user_management/patient-list/edit-patient/view-bdd-form' element={<ProtectRoute Component={ViewBddForm} />} />
                <Route path='/user_management/patient-list/edit-patient/treatment-plan' element={<ProtectRoute Component={TreatmentPlan} />} />
                <Route path='/user_management/patient-list/edit-patient/treatment-plan/view-bdd-form' element={<ProtectRoute Component={ViewBddForm} />} />
                <Route path='/user_management/patient-list/treatment-plan' element={<ProtectRoute Component={TreatmentPlan} />} />
                <Route path='/treatment-queue' element={<ProtectRoute Component={ApprovalWating} />} />
                <Route path='/treatment-queue/treatment-plan-details' element={<ProtectRoute Component={TreatmentPlanDetails} />} />
                <Route path='/treatment-queue/treatment-plan-details/compared_images' element={<ProtectRoute Component={ComparedImages} />} />
                <Route path='/treatment-queue/treatment-plan-details/compared_images/show_comparison' element={<ProtectRoute Component={ImageComparedNotes} />} />
                <Route path='/stocks' element={<ProtectRoute Component={Stocks} />} />
                <Route path='/waiting-list' element={<ProtectRoute Component={WatingRoom} />} />
                <Route path='/waiting-list/treatment-plan-details' element={<ProtectRoute Component={TreatmentPlanDetails} />} />
                <Route path='/document-management' element={<ProtectRoute Component={DocumentManagement} />} />
                <Route path='/invoice_management' element={<ProtectRoute Component={InvoiceManagement} />} />
                {/* <Route path='/appointment-schedule' element={<ProtectRoute Component={AppointmentSchedule} />} /> */}
                <Route path='/appointment-schedule' element={<ProtectRoute Component={Appointment} />} />
                {/* <Route path='/appointment-list' element={<ProtectRoute Component={Appointment} />} /> */}
                <Route path='/user_management/users_list' element={<ProtectRoute Component={Usermanagement} />} />
                <Route path='/user_management/injector-list' element={<ProtectRoute Component={InjectorList} />} />
                <Route path='/user_management/injector-list/injector-add-holiday' element={<ProtectRoute Component={InjectorAddHoliday} />} />
                <Route path='/user_management/injector-list/edit-injector' element={<ProtectRoute Component={EditInjector} />} />
                <Route path='/user_management/patient-list' element={<ProtectRoute Component={PatientManagement} />} />
                <Route path='/user_management/vender_list' element={<ProtectRoute Component={VendorManagement} />} />
                <Route path='/clinic_management/configure-machines' element={<ProtectRoute Component={ConfigureMachines} />} />
                <Route path='/clinic_management/document' element={<ProtectRoute Component={DocumentManagement} />} />
                <Route path='/clinic_management/configure-room' element={<ProtectRoute Component={ConfigureRoom} />} />
                <Route path='/clinic_management/clinic_list' element={<ProtectRoute Component={Clinic} />} />
                <Route path='/clinic_management/product_treatment_mapping' element={<ProtectRoute Component={ProductTreatmentMapping} />} />
                <Route path='/price_management/cosmetic_injectables' element={<ProtectRoute Component={CosmeticInjectables} />} />
                <Route path='/price_management/skin_treatment' element={<ProtectRoute Component={SkinTreatmentPriceManagement} />} />
                <Route path='/price_management/other_stock' element={<ProtectRoute Component={OtherStock} />} />
                <Route path='/treatment_list/cosmetic_injectables' element={<ProtectRoute Component={TreatmentList} />} />
                {/* <Route path='/treatment_list/skin_treatment' element={<ProtectRoute Component={SkinTreatment} />} /> */}
                <Route path='/drug_book/stock_purchased' element={<ProtectRoute Component={StockPurchased} />} />
                <Route path='/drug_book/stock_adjustment' element={<ProtectRoute Component={StockAdjustment} />} />
                <Route path='/drug_book/stock_used' element={<ProtectRoute Component={StockUsed} />} />
                <Route path='/drug_book/stock_insight' element={<ProtectRoute Component={StockInHand} />} />
                <Route path='/other_stock/stock_purchased' element={<ProtectRoute Component={OtherStockPurchased} />} />
                <Route path='/other_stock/stock_adjustment' element={<ProtectRoute Component={OtherStockAdjustment} />} />
                <Route path='/other_stock/stock_insight' element={<ProtectRoute Component={OtherStockInHand} />} />
                <Route path='/other_stock/stock_sold' element={<ProtectRoute Component={OtherStockUsed} />} />
                <Route path='/invoice_management/treatment_invoice' element={<ProtectRoute Component={InvoiceManagement} />} />
                <Route path='/invoice_management/other_invoice' element={<ProtectRoute Component={OtherInvoice} />} />
                <Route path='/invoice_management/skin_treatment_invoice' element={<ProtectRoute Component={SkinTreatmentList} />} />
                <Route path='/consent-form/A_W' element={<ConsentFormAW />} />
                <Route path='/consent-form/D_F' element={<ConsentFormDF />} />
                <Route path='/consent-form/S_B' element={<SkinBooster />} />
                <Route path='/consent-form/H_T' element={<HyalaseTreatment />} />
                <Route path='/treatment_list/skin_treatment' element={<ProtectRoute Component={SkinTreatment} />} />
                <Route path='/skin-treatment-booking' element={<ProtectRoute Component={SkinTreatmentBooking} />} />
                <Route path='/patient-list/view-patient-details/patient_skin_treatment_list/skin-treatment-booking' element={<ProtectRoute Component={SkinTreatmentBooking} />} />
                <Route path='/clinic_management/email_config' element={<ProtectRoute Component={EmailConfig} />} />
                <Route path='/patient-list/view-patient-details/patient_skin_treatment_list/skin-treatment-booking/treatment_details' element={<ProtectRoute Component={SkinTreatmentDetails} />} />
                <Route path='/campaign' element={<ProtectRoute Component={Campaign} />} />
                <Route path='/campaign/create-template' element={<ProtectRoute Component={CreateTemplateDialog} />} />
                <Route path='/appointment-schedule/view-patient-details' element={<ProtectRoute Component={ViewPatientDetails} />} />
                <Route path='/patient-list/view-patient-details/add-assessment' element={<ProtectRoute Component={AddAssessment} />} />
                <Route path='/patient-list/view-patient-details/view-GRS-data' element={<ProtectRoute Component={ViewGRSDetails} />} />
                <Route path='/patient-list/view-patient-details/LinkGRS' element={<ProtectRoute Component={LinkGRS} />} />
                <Route path='/patient-list/view-patient-details/view-patient-past-procedure' element={<ProtectRoute Component={ViewPatientPastProcedure} />} />
                <Route path='/package_configuration' element={<ProtectRoute Component={PackageList} />} />
                <Route path='/package_invoice' element={<ProtectRoute Component={PackageInvoice} />} />
                <Route path='/cash_float' element={<ProtectRoute Component={CashFloat} />} />
                <Route path='/edit_template' element={<ProtectRoute Component={EditTemplate} />} />
                <Route path='/employee_payroll_hours_details' element={<ProtectRoute Component={EmployeePayrollHoursDetails} />} />
                {/* kiosk */}
                <Route path='/kiosk-menu' element={<ProtectRoute Component={Menu} />} />
                <Route path='/kiosk-menu/kiosk-add-patient' element={<ProtectRoute Component={KioskAddPatient} />} />
                <Route path='/kiosk-menu/kiosk-add-treatment-photo' element={<ProtectRoute Component={KioskAddPhoto} />} />
                <Route path='/kiosk-menu/kiosk-consent' element={<ProtectRoute Component={KioskConsentForm} />} />

                {/* Agreement Form */}
                <Route path='/nurses-agreement/:formId' element={<NursesAgreement />} />
                <Route path='/consultant-agreement/:formId' element={<ConsultantAgreement />} />
                <Route path='/supporting-document-upload' element={<DocumentUpload />} />
                <Route path='/up-sup-doc/:formId' element={<UploadDocumentForAdmin />} />

            </Routes>
        </div>
    )
}
