import React, { useState, useRef, useEffect } from 'react';
import { material } from '../../../../library/material';
import { useForm } from 'react-hook-form';
import { deleteSOPData, saveOrUpdateSOP } from '../../../../services/ProtocolService';
import Snackbar from '../../../toastrmessage/Snackbar';
import DeleteConformationDialog from '../DeleteConformationDialog';
import TextEditor from './TextEditor';


function AddProtocol(props) {

    const { register, handleSubmit, resetField, control, watch, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const { openAddProtocol, setOpenAddProtocol, clinicDetails, userDetails, getProtocol } = props;
    const [editorContent, setEditorContent] = useState('');
    const myRef = useRef(null);
    const [snackBarState, setSnackBarState] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [openConformationDialog, setOpenConformationDialog] = useState(false);

    useEffect(() => {
        if (openAddProtocol.action && openAddProtocol.data !== null) {
            setEditorContent(openAddProtocol.data.sopDetails);
            reset(openAddProtocol.data)
        }
    }, [openAddProtocol.action === true]);

    const saveProtocol = (data) => {
        let payload;
        openAddProtocol.data !== null ?
            payload = {
                clinicId: clinicDetails.clinicId,
                orgId: userDetails.orgId,
                sopDetails: editorContent,
                forTreatment: data.forTreatment,
                id: openAddProtocol.data.id,
                active: true
            }
            :
            payload = {
                clinicId: clinicDetails.clinicId,
                orgId: userDetails.orgId,
                sopDetails: editorContent,
                forTreatment: data.forTreatment,
                active: true
            }
        saveOrUpdateSOP(payload)
            .then((resp) => {
                setSnackBarState({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                });
                getProtocol();
                setEditorContent("");
                reset({ forTreatment: "" });
                setOpenAddProtocol({ action: false, data: null })
            })
            .catch((error) => {
                setSnackBarState({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            })
    };

    const handleCancel = () => {
        setEditorContent("");
        reset({ forTreatment: "" });
        setOpenAddProtocol({ action: false, data: null })
    };

    const deleteProtocol = () => {
        setOpenConformationDialog(true);
    };

    const getDeleteConformationData = () => {
        deleteSOPData(openAddProtocol.data.id)
            .then((resp) => {
                setSnackBarState({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                });
                setOpenConformationDialog(false);
                getProtocol();
                setEditorContent("");
                reset({ forTreatment: "" });
                setOpenAddProtocol({ action: false, data: null })
            })
            .catch((error) => {
                setSnackBarState({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            })
    };

    return (
        <div ref={myRef}>
            <material.Dialog open={openAddProtocol.action} fullWidth maxWidth="lg" hideBackdrop>
                <material.DialogTitle>{openAddProtocol.data !== null ? "Edit Protocol" : "Add Protocol"}</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-6">
                            <material.TextField
                                margin="dense"
                                variant="standard"
                                label="Treatment Name"
                                fullWidth
                                type='text'
                                {...register('forTreatment', { required: true })}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <TextEditor
                                setEditorContent={setEditorContent}
                                editorContent={editorContent}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 1 }}>
                    <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} onClick={handleCancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddProtocol.data !== null ? (
                        <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' onClick={deleteProtocol} startIcon={<material.DeleteIcon />}>Delete</material.Button>
                    ) : null}
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveProtocol)} disabled={!isValid || editorContent === ''} startIcon={<material.DoneIcon />}>{openAddProtocol.data !== null ? "Update" : "Save"}</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <DeleteConformationDialog
                getDeleteConformationData={getDeleteConformationData}
                openConformationDialog={openConformationDialog}
                setOpenConformationDialog={setOpenConformationDialog}
                callFrom="Protocol"
            />
            <Snackbar
                openSnackBar={snackBarState}
                setOpenSnackBar={setSnackBarState}
            />
        </div>
    );
}

export default AddProtocol;