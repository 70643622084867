import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getSignedDocumentLink } from '../../../services/on-boarding-service/OnBoardingService';
import Snackbar from '../../toastrmessage/Snackbar';

function ViewPdf(props) {

    const { openViewPdf, setOpenViewPdf, callForm } = props;
    const [pdfLink, setPdfLink] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openViewPdf.action && callForm == "view_upload_document") {
            getDocumentLink()
        } else if (openViewPdf.action && callForm == "check_list") {
            setPdfLink(openViewPdf.pdfUrl)
        }
    }, [openViewPdf.action]);

    const getDocumentLink = () => {
        getSignedDocumentLink(openViewPdf.pdfName)
            .then((resp) => {
                setPdfLink(resp.data)
            })
            .catch((error) => {
                console.log(error)
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "",
                })
            })
    };

    const handleClose = () => {
        setPdfLink(null);
        setOpenViewPdf({ action: false, pdfName: null });
    };

    return (
        <div>
            <material.Dialog open={openViewPdf.action} fullWidth maxWidth="lg">
                <material.DialogTitle>
                    <span className='float-end'>
                        <material.IconButton title='Close' onClick={handleClose}>
                            <material.CloseIcon color='error' />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                        {pdfLink && (
                            <Viewer fileUrl={pdfLink} />
                        )}
                    </Worker>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ViewPdf;