import React, { useEffect, useState, useRef } from 'react'
import { styled } from "@mui/material/styles";
import { material } from '../../library/material'
import { connect } from 'react-redux';
import { logoutAction } from '../../store/action/Action';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { socket } from '../../socket/Socket';
import Snackbar from '../toastrmessage/Snackbar';
import CallNotification from '../toastrmessage/CallNotification';
import CallRington from "../../assets/sound/CallRington.m4r";
import { drawerWidth } from '../drawer/Swipedrawer';
import { lockTreatmentPlan } from '../../services/PrescriberService';
import { getHighestPriorityRole } from '../../highest-priority-role/HighestPriorityRole';
import ClinicChatBox from '../dashboard/clinic-config/chat-box/ClinicChatBox';



// const drawerWidth = 200;
const AppBar = styled(material.MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const initialState = {
  isLoggedIn: false,
  data: {
    email: "",
    orgId: "",
    refreshToken: "",
    role: "",
    token: "",
    userId: ""
  },
};

function Navbar(props) {

  const [choseOptine, setChoseOption] = React.useState(null);
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [openCallNotification, setOpenCallNotification] = useState({ "action": false, "data": "" });
  const { openSideNav, user, authLogout, patientData, clinicData, getAllPatientListByClinicId } = props;
  const userDetails = user.authReducer.data;
  const clinicDetails = clinicData.clinicReducer.data;
  let patientDetails = patientData.patientReducer.data;
  const navigate = useNavigate();
  const audioRef = useRef(null);
  const [openChatBoxComponent, setOpenChatBoxComponent] = useState(false);
  const [messageData, setMessageData] = useState(null);
  const [notification, setNotification] = useState(0);

  socket.on("new-patient-added", (resp) => {
    getAllPatientListByClinicId()
    setOpenSnackBar({
      "action": true,
      "type": "info",
      "message": "New Patient Added",
    })
  });

  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${userDetails.token}`;
    if (userDetails.role.includes("INJECTOR")) {
      socket.on('join-call', (resp) => {
        setOpenCallNotification({ action: true, data: resp.data })
        if (audioRef.current) {
          audioRef.current.play();
        }
      })
    };
    socket.emit('user-info', userDetails);
    socket.on("logout", (res) => {
      logOut()
    });
  }, []);

  async function logOut() {
    if (userDetails.role.includes("PRESCRIBER") && patientDetails !== "") {
      await lockTreatmentPlan(patientDetails.treatmentPlanRequestId)
    };
    authLogout(initialState, navigate)
    socket.disconnect()
  };

  const handleMenu = (event) => {
    setChoseOption(event.currentTarget);
  };

  const handleClose = () => {
    setChoseOption(null);
  };

  const stopRington = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const handleDrawer = () => {
    navigate("/card-component")
  }
  const viewEmployeePayrollHoursDetails = () => {
    navigate("/employee_payroll_hours_details")
  }

  const openChatBox = () => {
    setNotification(0);
    setOpenChatBoxComponent(true);
  };

  const getMessageNotification = (message) => {
    setMessageData(message)
    setNotification((prevMessages) => prevMessages + 1)
  };

  return (
    <div>
      <material.Box>
        <AppBar position="fixed"
          style={{ backgroundColor: "#fff" }}
        >
          <material.Toolbar>
            {localStorage.getItem("menuName") === "Clinic Selector" ? null : (
              <material.IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                // sx={{ mr: 2, ...(openSideNav && { display: 'none' }) }}
                title='Menu'
              >
                <material.MenuIcon sx={{ color: "black", fontSize: 35 }} />
              </material.IconButton>
            )}
            <material.Typography sx={{ color: "black", marginTop: "-2px !important", mr: 2 }} className="nav-content">{localStorage.getItem("menuName")}</material.Typography>
            <material.Typography sx={{ flexGrow: 1 }}></material.Typography>
            {clinicDetails.clinicId !== "" || userDetails.role.includes("PRESCRIBER") ? (
              <material.Typography sx={{ color: "black", mr: 2, marginTop: "-2px !important" }}>
                <material.IconButton title='Chat Box' onClick={openChatBox}>
                  <material.Badge color='secondary' badgeContent={messageData?.userId === userDetails.userId ? 0 : notification}>
                    <material.ChatIcon sx={{ fontSize: 30 }} color='info' />
                  </material.Badge>
                </material.IconButton>
              </material.Typography>
            ) : null}
            <material.Typography component="div">
              <div className="nav-content" style={{ color: "black", fontSize: 15, ml: 2 }}>Logged in as {userDetails.name}</div>
              {clinicDetails.clinicName ? (
                <div className="nav-content" style={{ color: "black", fontSize: 15, ml: 2 }}>Clinic: {clinicDetails.clinicName}</div>
              ) : null}
            </material.Typography>
            <material.IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <material.Tooltip>
                <material.Avatar />
              </material.Tooltip>
            </material.IconButton>
            <material.Menu
              id="menu-appbar"
              anchorEl={choseOptine}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(choseOptine)}
              onClose={handleClose}
            >
              <material.MenuItem className="nav-content"> <material.UserIcon sx={{ mr: 2 }} />Name: {userDetails.name}</material.MenuItem>
              <material.MenuItem className="nav-content">  <material.EmailIcon sx={{ mr: 2 }} />Email: {userDetails.email}</material.MenuItem>
              <material.MenuItem className="nav-content"> <material.RoleIcon sx={{ mr: 2 }} />Roles: {userDetails.role?.length ? userDetails?.role.join(',') : ''}</material.MenuItem>
              <material.MenuItem onClick={viewEmployeePayrollHoursDetails} className="nav-content"><material.VisibilityIcon sx={{ mr: 2 }} />View Employee Payroll Hours Details</material.MenuItem>
              <material.MenuItem onClick={logOut} className="nav-content"><material.LogoutIcon sx={{ mr: 2 }} />Logout</material.MenuItem>
            </material.Menu>
          </material.Toolbar>
        </AppBar>
      </material.Box>
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <CallNotification
        openCallNotification={openCallNotification}
        setOpenCallNotification={setOpenCallNotification}
        stopRington={stopRington}
      />
      <audio ref={audioRef} src={CallRington} />
      <ClinicChatBox
        openChatBoxComponent={openChatBoxComponent}
        setOpenChatBoxComponent={setOpenChatBoxComponent}
        clinicDetails={clinicDetails}
        userDetails={userDetails}
        getMessageNotification={getMessageNotification}
        setNotification={setNotification}
      />
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    user: state,
    patientData: state,
    clinicData: state
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authLogout: (initialState, navigate) => {
      dispatch(logoutAction(initialState, navigate))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

