import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { useForm } from 'react-hook-form';
import { deleteCurrentOffers, saveCurrentOffers } from '../../../../services/ConfigureService';
import Snackbar from '../../../toastrmessage/Snackbar';
import DeleteConformationDialog from '../DeleteConformationDialog';
import TextEditor from '../protocol/TextEditor';

function AddCurrentOffer(props) {

    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const { openAddCurrentOffer, setOpenAddCurrentOffer, userDetails, clinicDetails, getCurrentOffersData } = props;
    const [snackBarState, setSnackBarState] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [openConformationDialog, setOpenConformationDialog] = useState(false);
    const [editorContent, setEditorContent] = useState('');

    useEffect(() => {
        if (openAddCurrentOffer.action && openAddCurrentOffer.data !== null) {
            reset(openAddCurrentOffer.data);
            setEditorContent(openAddCurrentOffer.data.offerDescription);
        }
    }, [openAddCurrentOffer.action === true]);

    const handleCancel = () => {
        reset({ forTreatment: "", offerAmount: "" });
        setEditorContent("");
        setOpenAddCurrentOffer({ action: false, data: null });
    };

    const saveCurrentOffer = (formData) => {
        let payload = {};
        if (openAddCurrentOffer.data !== null) {
            payload = {
                orgId: userDetails.orgId,
                clinicId: clinicDetails.clinicId,
                offerDescription: editorContent,
                forTreatment: formData.forTreatment,
                offerAmount: formData.offerAmount,
                offerName: formData.offerName,
                id: openAddCurrentOffer.data.id,
            }
        } else {
            payload = {
                orgId: userDetails.orgId,
                clinicId: clinicDetails.clinicId,
                offerDescription: editorContent,
                forTreatment: formData.forTreatment,
                offerAmount: formData.offerAmount,
                offerName: formData.offerName
            }
        }
        saveCurrentOffers(payload)
            .then((resp) => {
                setSnackBarState({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                });
                getCurrentOffersData();
                setEditorContent("");
                reset({ forTreatment: "", offerAmount: "", offerName: "" });
                setOpenAddCurrentOffer({ action: false, data: null });
            })
            .catch((error) => {
                setSnackBarState({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            })
    };

    const deleteOffer = () => {
        setOpenConformationDialog(true);
    };

    const getDeleteConformationData = () => {
        deleteCurrentOffers(openAddCurrentOffer.data.id)
            .then((resp) => {
                setSnackBarState({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                });
                setOpenConformationDialog(false);
                getCurrentOffersData();
                reset({ forTreatment: "", offerAmount: "", offerName: "" });
                setOpenAddCurrentOffer({ action: false, data: null });
            })
            .catch((error) => {
                setSnackBarState({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            })
    };

    return (
        <div>
            <material.Dialog open={openAddCurrentOffer.action} fullWidth maxWidth="lg">
                <material.DialogTitle>{openAddCurrentOffer.data !== null ? "Edit Current Offer" : "Add Current Offer"}</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-4 mt-3">
                            <material.TextField
                                required
                                margin="dense"
                                variant="standard"
                                label="Treatment Name"
                                fullWidth
                                type='text'
                                {...register('forTreatment', { required: true })}
                            />
                        </div>
                        <div className="col-4 mt-3">
                            <material.TextField
                                required
                                margin="dense"
                                variant="standard"
                                label="Amount"
                                fullWidth
                                type='number'
                                {...register('offerAmount', { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                            />
                            {errors.offerAmount && errors.offerAmount.type === "pattern" && (
                                <p className="errorMsg text-danger">Allowed only number & also 2 digit after decimal</p>
                            )}
                        </div>
                        <div className="col-4 mt-3">
                            <material.TextField
                                required
                                margin="dense"
                                variant="standard"
                                label="Name"
                                fullWidth
                                type='text'
                                {...register('offerName', { required: true })}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <TextEditor
                                setEditorContent={setEditorContent}
                                editorContent={editorContent}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 1 }}>
                    <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} onClick={handleCancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddCurrentOffer.data !== null ? (
                        <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' onClick={deleteOffer} startIcon={<material.DeleteIcon />}>Delete</material.Button>
                    ) : null}
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveCurrentOffer)} disabled={!isValid || editorContent === ''} startIcon={<material.DoneIcon />}>{openAddCurrentOffer.data !== null ? "Update" : "Save"}</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <DeleteConformationDialog
                getDeleteConformationData={getDeleteConformationData}
                openConformationDialog={openConformationDialog}
                setOpenConformationDialog={setOpenConformationDialog}
                callFrom="Current Offer"
            />
            <Snackbar
                openSnackBar={snackBarState}
                setOpenSnackBar={setSnackBarState}
            />
        </div>
    );
}

export default AddCurrentOffer;