import React, { useState } from 'react';
import { material } from '../../library/material';
import AddUsers from './AddUsers';
import { Controller } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function AddClinic(props) {

    const { register, errors, handleSubmit, control, reset, isNextFlow, setValue, watch, getValues } = props;
    const [nextAddUserFlow, setNextAddUserFlow] = useState(false);

    const nextFlow = () => {
        setNextAddUserFlow(true);
    };

    const clinicDetailsSameAsOrgDetails = (event) => {
        if (event.target.checked) {
            setValue("clinic_registeredOfficeAddress", isNextFlow.orgData.registeredOfficeAddress)
            setValue("clinic_director1Id", isNextFlow.orgData.director1Id)
            setValue("clinic_director1", isNextFlow.orgData.director1)
            setValue("clinic_director1Phone", isNextFlow.orgData.director1Phone)
            setValue("clinic_director1Mail", isNextFlow.orgData.director1Mail)
            setValue("clinic_director2Id", isNextFlow.orgData.director2Id)
            setValue("clinic_director2", isNextFlow.orgData.director2)
            setValue("clinic_director2Phone", isNextFlow.orgData.director2Phone)
            setValue("clinic_director2Mail", isNextFlow.orgData.director2Mail)
            setValue("clinic_contact1Name", isNextFlow.orgData.contact1Name)
            setValue("clinic_contact1Mail", isNextFlow.orgData.contact1Mail)
            setValue("clinic_contact1Address", isNextFlow.orgData.contact1Address)
            setValue("clinic_contact1Phone", isNextFlow.orgData.contact1Phone)
            setValue("clinic_contact2Name", isNextFlow.orgData.contact2Name)
            setValue("clinic_contact2Mail", isNextFlow.orgData.contact2Mail)
            setValue("clinic_contact2Address", isNextFlow.orgData.contact2Address)
            setValue("clinic_contact2Phone", isNextFlow.orgData.contact2Phone)
        } else {
            setValue("clinic_registeredOfficeAddress", "")
            setValue("clinic_director1Id", "")
            setValue("clinic_director1", "")
            setValue("clinic_director1Phone", "")
            setValue("clinic_director1Mail", "")
            setValue("clinic_director2Id", "")
            setValue("clinic_director2", "")
            setValue("clinic_director2Phone", "")
            setValue("clinic_director2Mail", "")
            setValue("clinic_contact1Name", "")
            setValue("clinic_contact1Mail", "")
            setValue("clinic_contact1Address", "")
            setValue("clinic_contact1Phone", "")
            setValue("clinic_contact2Name", "")
            setValue("clinic_contact2Mail", "")
            setValue("clinic_contact2Address", "")
            setValue("clinic_contact2Phone", "")
        }
    };

    return (
        <div>
            <material.Paper elevation={1} sx={{ p: 4, mt: 2 }}>
                <div className="row">
                    <div className="col-12">
                        <span className='d-flex flex-row'>
                            <material.Typography>Clinic Details</material.Typography>
                            <material.FormGroup sx={{ ml: 2, mt: 1 }} onChange={clinicDetailsSameAsOrgDetails}>
                                <material.FormControlLabel control={<material.Checkbox />} label="Same as organization" />
                            </material.FormGroup>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinicName?.type === "required"}
                            {...register("clinicName", { required: true })}
                            required
                            label="Clinic Name"
                            id="clinicName"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            margin="dense"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_acn?.type === "required"}
                            {...register("clinic_acn", { minLength: 9, maxLength: 9 })}
                            label="ACN"
                            margin="dense"
                            id="clinic_acn"
                            variant="standard"
                            size="small"
                            type="number"
                            fullWidth
                        />
                        {(errors?.clinic_acn?.type === "minLength" || errors?.clinic_acn?.type === "maxLength") && (
                            <p className='text-danger'>This field only contain 9 digits</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_abn?.type === "required"}
                            {...register("clinic_abn", { minLength: 11, maxLength: 11 })}
                            label="ABN"
                            margin="dense"
                            id="clinic_abn"
                            variant="standard"
                            size="small"
                            type="number"
                            fullWidth
                        />
                        {(errors?.clinic_abn?.type === "minLength" || errors?.clinic_abn?.type === "maxLength") && (
                            <p className='text-danger'>This field only contain 11 digits</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 ">
                        <material.TextField
                            {...register("clinic_registeredOfficeAddress")}
                            label="Address"
                            margin="dense"
                            id="clinic_registeredOfficeAddress"
                            variant="standard"
                            size="small"
                            type='text'
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_director1Id?.type === "required"}
                            {...register("clinic_director1Id")}
                            margin="dense"
                            label="Director_1 ID"
                            id="clinic_director1Id"
                            variant="standard"
                            size="small"
                            type='text'
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_director1?.type === "required"}
                            {...register("clinic_director1")}
                            margin="dense"
                            label="Director_1 Name"
                            id="clinic_director1"
                            variant="standard"
                            size="small"
                            type="text"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <Controller
                            name="clinic_director1Phone"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <PhoneInput
                                    {...field}
                                    placeholder="Director_1 Ph."
                                    defaultCountry="AU"
                                    enableSearch={true}
                                    value={field.value || ""}
                                    international
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                />
                            )}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_director1Mail?.type === "required"}
                            {...register("clinic_director1Mail", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                            label="Director_1 Email"
                            margin="dense"
                            id="clinic_director1Mail"
                            variant="standard"
                            size="small"
                            type="email"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                        {(errors?.clinic_director1Mail?.type === "pattern") && (
                            <p className='text-danger'>This is not a valid Email</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_director2Id?.type === "required"}
                            {...register("clinic_director2Id")}
                            label="Director_2 ID"
                            margin="dense"
                            id="clinic_director2Id"
                            variant="standard"
                            size="small"
                            type="text"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_director2?.type === "required"}
                            {...register("clinic_director2")}
                            margin="dense"
                            label="Director_2 Name"
                            id="clinic_director2"
                            variant="standard"
                            size="small"
                            type="text"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <Controller
                            name="clinic_director2Phone"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <PhoneInput
                                    {...field}
                                    placeholder="Director_2 Ph."
                                    defaultCountry="AU"
                                    enableSearch={true}
                                    value={field.value || ""}
                                    international
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                />
                            )}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_director2Mail?.type === "required"}
                            {...register("clinic_director2Mail", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                            label="Director_2 Email"
                            margin="dense"
                            id="clinic_director2Mail"
                            variant="standard"
                            size="small"
                            type="email"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                        {(errors?.clinic_director2Mail?.type === "pattern") && (
                            <p className='text-danger'>This is not a valid Email</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_contact1Name?.type === "required"}
                            {...register("clinic_contact1Name")}
                            label="Contact_1 Name"
                            margin="dense"
                            id="clinic_contact1Name"
                            variant="standard"
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_contact1Mail?.type === "required"}
                            {...register("clinic_contact1Mail", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                            label="Contact_1 Email"
                            margin="dense"
                            id="clinic_contact1Mail"
                            variant="standard"
                            size="small"
                            type="email"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                        {(errors?.clinic_contact1Mail?.type === "pattern") && (
                            <p className='text-danger'>This is not a valid Email</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_contact1Address?.type === "required"}
                            {...register("clinic_contact1Address")}
                            label="Contact_1 Address"
                            margin="dense"
                            id="clinic_contact1Address"
                            variant="standard"
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <Controller
                            name="clinic_contact1Phone"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <PhoneInput
                                    {...field}
                                    placeholder="Contact_1 Ph."
                                    defaultCountry="AU"
                                    enableSearch={true}
                                    value={field.value || ""}
                                    international
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                />
                            )}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_contact2Name?.type === "required"}
                            {...register("clinic_contact2Name")}
                            label="Contact_2 Name"
                            margin="dense"
                            id="clinic_contact2Name"
                            variant="standard"
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_contact2Mail?.type === "required"}
                            {...register("clinic_contact2Mail", {
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}
                            label="Contact_2 Email"
                            margin="dense"
                            id="clinic_contact2Mail"
                            variant="standard"
                            size="small"
                            type="email"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                        {errors?.clinic_contact2Mail?.type === "pattern" && (
                            <p className='text-danger'>This is not a valid email</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.clinic_contact2Address?.type === "required"}
                            {...register("clinic_contact2Address")}
                            margin="dense"
                            label="Contact_2 Address"
                            id="clinic_contact2Address"
                            variant="standard"
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <Controller
                            name="clinic_contact2Phone"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <PhoneInput
                                    {...field}
                                    placeholder="Contact_2 Ph."
                                    defaultCountry="AU"
                                    enableSearch={true}
                                    value={field.value || ""}
                                    international
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                />
                            )}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                        <Controller
                            name="defaultConfig"
                            control={control}
                            render={({ field }) => (
                                <material.FormControlLabel
                                    control={
                                        <material.Checkbox
                                            {...field}
                                            checked={field.value}
                                            color="secondary"
                                        />
                                    }
                                    label="Default Config"
                                />
                            )}
                        />
                    </div>
                    <div className="col-12">
                        <span className='float-end'>
                            <material.Button disabled={nextAddUserFlow} variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={nextFlow}>Next</material.Button>
                        </span>
                    </div>
                </div>
            </material.Paper>
            {nextAddUserFlow ? (
                <AddUsers
                    handleSubmit={handleSubmit}
                    register={register}
                    errors={errors}
                    control={control}
                    reset={reset}
                    getValues={getValues}
                    watch={watch}
                    setValue={setValue}
                />
            ) : null}
        </div>
    );
}

export default AddClinic;