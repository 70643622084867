import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getClinicDetailsByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-clinic-by-clinic-id/${clinicId}`);
};

// export const getInjectorDetails = async (injectorId) => {
//     return await axios.get(`${rootApiUrl}/get-injector-details/${injectorId}`);
// };

export const saveTreatmentPlan = async (treatmentData) => {
    return await axios.post(`${rootApiUrl}/save-treatment-plan`, treatmentData);
};

export const saveProposedTreatmentPlans = async (requestBody) => {
    return await axios.post(`${rootApiUrl}/save-proposed-treatment-plans`, requestBody);
};

export const getTreatmentPlanByPlanId = async (treatmentPlanRequestId) => {
    return await axios.get(`${rootApiUrl}/get-treatment-plan-by-planId`, { params: { treatmentPlanRequestId: treatmentPlanRequestId } });
};

export const createBlankTreatmentPlan = async (data) => {
    return await axios.post(`${rootApiUrl}/create-blank-treatment-plan`, data)
};

export const getStockPriceId = async (data) => {
    return await axios.post(`${rootApiUrl}/get-stock-price-id`, data)
};

export const getPreWrittenNotesInjector = async (treatmentName, treatmentArea) => {
    return await axios.get(`${rootApiUrl}/get-pre-written-notes-injector`, { params: { treatmentName: treatmentName, treatmentArea: treatmentArea } })
};

export const getPreWrittenNotesPrescriber = async (treatmentName, treatmentArea) => {
    return await axios.get(`${rootApiUrl}/get-pre-written-notes-prescriber`, { params: { treatmentName: treatmentName, treatmentArea: treatmentArea } })
};

export const saveConsentFormAsFile = async (data) => {
    return await axios.post(`${rootApiUrl}/save-concent-form-as-file`, data)
};

export const downloadConsentForm = async (consentFormId) => {
    return await axios(`${rootApiUrl}/download-concent-file`, {
        method: 'GET',
        responseType: 'blob',
        params: { consentFormId: consentFormId }
    })
};

export const uploadCosmeticTreatmentImage = async (data) => {
    return await axios.post(`${rootApiUrl}/upload-cosmetic-treatment-image`, data)
};

export const uploadCosmeticTreatmentImageFile = async (file) => {
    return await axios.post(`${rootApiUrl}/upload-cosmetic-treatment-image-file`, file)
};

export const getImageListCosmetic = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-image-list-cosmetic`, { params: { ...payload } })
};

export const saveCompareImages = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-compare-image`, payload)
};

export const getCompareImageList = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-compare-image-list`, { params: { ...payload } })
};

export const addToPortfolio = async (payload) => {
    return await axios.put(`${rootApiUrl}/add-to-portfolio`, payload)
};

export const getProposedTreatmentPlanList = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-proposed-treatment-plan-list`, { params: { ...payload } })
};

export const getProposedTreatmentPlansDetails = async (proposedPlanId) => {
    return await axios.get(`${rootApiUrl}/get-proposed-treatment-details/${proposedPlanId}`)
};

export const sendProposedTreatmentPlansDetails = async (payload) => {
    return await axios.post(`${rootApiUrl}/send-proposed-treatment-details`, payload)
};

export const createNewProposedTreatmentPlan = async (payload) => {
    return await axios.post(`${rootApiUrl}/create-new-proposed-plan`, payload)
};

export const saveAndDraftProposedTreatmentPlan = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-proposed-treatment-plan`, payload)
};

export const approveProposedTreatmentPlan = async (planId) => {
    return await axios.put(`${rootApiUrl}/approve-plan`, null, { params: { id: planId } })
};

export const deleteProposedTreatmentPlan = async (planId) => {
    return await axios.put(`${rootApiUrl}/delete-plan`, null, { params: { id: planId } })
};

export const savePlanDetails = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-plan-details`, payload)
};

export const updatePlanDetails = async (payload) => {
    return await axios.put(`${rootApiUrl}/update-plan-details`, payload)
};

export const getTreatmentPlans = async (proposedPlanId) => {
    return await axios.get(`${rootApiUrl}/get-plan-details`, { params: { proposedPlanId } })
};

export const getTreatmentPlanHtml = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-plan-html`, { params: { ...payload } })
};

export const sendPlanEmail = async (proposedPlanId) => {
    return await axios.get(`${rootApiUrl}/send-plan-email`, { params: { proposedPlanId } })
};