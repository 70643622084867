import React from 'react';
import { material } from '../../../library/material';

function DeleteConformationDialog(props) {

    const { openConformationDialog, setOpenConformationDialog, getDeleteConformationData, callFrom } = props;

    const handleClick = (data) => {
        if (data === "Yes") {
            getDeleteConformationData()
        } else {
            setOpenConformationDialog(false)
        }
    };

    return (
        <div>
            <material.Dialog open={openConformationDialog} fullWidth maxWidth="sm">
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-12 d-flex flex-row justify-content-center align-items-center'>
                            <div className='me-2 fw-bold text-dark'>Will you delete this {callFrom} ?</div>
                            <span className='ms-3'>
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} onClick={() => handleClick("No")} startIcon={<material.CloseIcon />}>No</material.Button>
                                <material.Button variant="outlined" sx={{ textTransform: "none" }} onClick={() => handleClick("Yes")} startIcon={<material.DoneIcon />}>Yes</material.Button>
                            </span>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default DeleteConformationDialog;