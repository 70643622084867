import React, { useEffect, useState, useRef } from 'react';
import { material } from '../../../library/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeDateAndTimeFormate, changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import moment from 'moment';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Snackbar from '../../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import VideoCalling from '../video-call/VideoCalling';
import { getCallCredential } from '../../../services/VideoCallingService';
import { getPatientHistory } from '../../../services/PatientService';
import StatusChangeReasonDialog from '../../dialog/StatusChangeReasonDialog';
import { createBlankTreatmentPlan, downloadConsentForm, getImageListCosmetic, getTreatmentPlanByPlanId } from '../../../services/TreatmentPlanService';
import AddTreatmentPlan from '../treatment-plan/AddTreatmentPlan';
import { lockTreatmentPlan } from '../../../services/PrescriberService';
import { socket } from '../../../socket/Socket';
import { getBddFormDataByFormId } from '../../../services/BddFormService';
import AssessedForm from '../../dialog/AssessedForm';
import AddSupportingDoc from '../../dialog/AddSupportingDoc';
import { saveTreatmentPlan } from '../../../services/TreatmentPlanService';
import { changePlanStatus } from '../../../services/PrescriberService';
import ConsentFormAW from '../consent-form/ConsentFormAW';
import ConsentFormDF from '../consent-form/ConsentFormDF';
import ViewPatientProfile from '../../dialog/ViewPatientProfile';
import SkinBooster from '../consent-form/SkinBooster';
import AfterImages from './AfterImages';
import HyalaseTreatment from '../consent-form/HyalaseTreatment';
import Navbar from '../../navbar/Navbar';
import { rootApiUrl } from '../../../environment/Environment';
import { saveCompareImages, getCompareImageList } from '../../../services/TreatmentPlanService';
import ComparedImageDialog from '../view-patient-details/ComparedImageDialog';
// import { useStyles } from '../../../shared/CustomStyle';
import { useTheme } from '@mui/material/styles';
import LocalAnaesthetic from '../consent-form/injectable/LocalAnaesthetic';
import Threads from '../consent-form/injectable/Threads';
import ExomideScalp from '../consent-form/injectable/ExomideScalp';
import TreatmentChatBox from '../clinic-config/chat-box/TreatmentChatBox';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

var uploadImage = [];
var allImages = [];
var treatmentData = [];
var uniqueTreatmentName = [];

function TreatmentPlanDetails(props) {

    const { userData, clinicData } = props;
    const theme = useTheme();
    const clinicDetails = clinicData.clinicReducer.data;
    const [date, setDate] = React.useState(moment().format("YYYY-MM-DDTHH:mm:ss"));
    const [isLoading, setisLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openVideoChat, setOpenVideoChat] = useState(false);
    const [credentialData, setCredentialData] = useState([]);
    const [patientHistory, setPatientHistory] = useState([]);
    const [openStatusChangeDialog, setOpenStatusChangeDialog] = useState({
        "action": false, "data": ""
    });
    const [openTreatmentPlan, setOpenTreatmentPlan] = useState(false);
    const [hideShow, setHideShow] = useState(true);
    const [blankTreatmentData, setBlankTreatmentData] = useState({});
    const [hideAndShowPatientDetails, setHideAndShowPatientDetails] = useState(true);
    const [hideAndShowTreatmentPlan, setHideAndShowTreatmentPlan] = useState(false);
    const [openAssessedForm, setOpenAssessedForm] = useState({ "action": false, "bddFormData": {} });
    const [viewConsentForm, setViewConsentForm] = useState(true);
    const [viewDoc, setViewDoc] = useState({ "action": false, "data": "" });
    const [openPatientProfile, setOpenPatientProfile] = useState({ action: false, patientData: null });
    const videoRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const userDetails = userData.authReducer.data;
    const treatmentPlanDetails = location.state ? location.state.treatmentPlanDetails : "";
    const [openConsentForm, setOpenConsentForm] = useState({ action: true, patientData: treatmentPlanDetails });
    const [openAfterImage, setOpenAfterImage] = useState({ action: false, patientData: null, imageType: null });
    const [treatmentPlanData, setTreatmentPlanData] = useState({});
    localStorage.setItem("menuName", `Treatment Plan Details for ${treatmentPlanDetails.patientName}`);
    const [imageList, setImageList] = useState([]);
    const [afterImageList, setAfterImageList] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [comparedImg, setComparedImg] = useState(false);
    const [compareImages, setCompareImages] = useState([]);
    const [openTreatmentChatBoxDialog, setOpenTreatmentChatBoxDialog] = useState(false);
    const [messageData, setMessageData] = useState(null);
    const [notification, setNotification] = useState(0);

    socket.on("call-rejected", (res) => {
        setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "Call Rejected By Injector",
        })
    });

    socket.on("new-photo-added", (resp) => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Photo Uploaded",
        })
        getTreatmentPlanDataByPlanId();
    });

    useEffect(() => {
        setisLoading(false)
        // if (treatmentPlanDetails.action === "videoCall") {
        //     getCallCredentialData();
        //     setOpenVideoChat(true)
        // }
        if (treatmentPlanDetails.incomingCall) {
            getCallCredentialData();
            setOpenVideoChat(true)
        } else {
            getPatientHistoryByPatientId()
            createBlankTreatment();
        }
        // if (treatmentPlanDetails.action === "videoCall") {
        //     setOpenVideoChat(true)
        // }
        getTreatmentPlanDataByPlanId()
        if (userDetails.role.includes("PRESCRIBER")) {
            lockedTreatment();
            getBddFormData();
        };
        socket.emit('joinRoom', treatmentPlanDetails.treatmentPlanRequestId);
    }, [treatmentPlanDetails.incomingCall]);

    useEffect(() => {
        const element = document.getElementById("form-section")
        element.scrollIntoView({ behavior: "smooth" });
    }, [viewConsentForm]);

    const getBddFormData = async () => {
        await getBddFormDataByFormId(treatmentPlanDetails.bdd_form_id)
            .then((resp) => {
                if (resp.data.is_assessed) {
                    if (resp.data.assessment_result === "POSITIVE") {
                        // const newTab = window.open("", "_blank")
                        // newTab.document.write(treatmentPlanDetails.bdd_support_image)
                        setViewDoc({ action: true, data: treatmentPlanDetails.bdd_support_image })
                    }
                }
                else {
                    setOpenAssessedForm({ action: true, bddFormData: resp.data })
                };
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getTreatmentPlanDataByPlanId = () => {
        getTreatmentPlanByPlanId(treatmentPlanDetails.treatmentPlanRequestId)
            .then((resp) => {
                setTreatmentPlanData(resp.data)
                if (treatmentPlanDetails.incomingCall) {
                    createBlankTreatment(resp.data.patientId);
                    getPatientHistoryByPatientId(resp.data.patientId);
                }
                const payload = {
                    treatmentPlanRequestId: resp.data.treatmentPlanRequestId,
                    clinicId: clinicDetails.clinicId,
                    orgId: userDetails.orgId,
                    typeOfImage: "before_image",
                    patientId: resp.data.patientId
                };
                const afterPayload = {
                    treatmentPlanRequestId: resp.data.treatmentPlanRequestId,
                    clinicId: clinicDetails.clinicId,
                    orgId: userDetails.orgId,
                    typeOfImage: "after_image",
                    patientId: resp.data.patientId
                }
                getCosmeticImageList(payload);
                getCosmeticAfterImageList(afterPayload);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getCosmeticAfterImageList = (payload) => {
        // setOpenSnackBar({
        //     "action": true,
        //     "type": "info",
        //     "message": "Please wait fetching data",
        // })
        getImageListCosmetic(payload)
            .then((resp) => {
                setAfterImageList(resp.data);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Image list fetch complete",
                })
                // setTimeout(() => {
                //     setOpenSnackBar({
                //         "action": true,
                //         "type": "info",
                //         "message": "Please wait while images are loading",
                //     })
                // }, 3000);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getCosmeticImageList = (payload) => {
        // setOpenSnackBar({
        //     "action": true,
        //     "type": "info",
        //     "message": "Please wait fetching data",
        // })
        getImageListCosmetic(payload)
            .then((resp) => {
                setImageList(resp.data);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Image list fetch complete",
                })
                // setTimeout(() => {
                //     setOpenSnackBar({
                //         "action": true,
                //         "type": "info",
                //         "message": "Please wait while images are loading",
                //     })
                // }, 3000);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const lockedTreatment = async () => {
        await lockTreatmentPlan(treatmentPlanDetails.treatmentPlanRequestId)
    };

    const createBlankTreatment = async (patientId) => {
        let obj = {
            patientId: patientId ? patientId : treatmentPlanDetails.patientId,
            timeStamp: date
        };
        await createBlankTreatmentPlan(obj)
            .then((resp) => {
                setBlankTreatmentData(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    async function getCallCredentialData() {
        await getCallCredential(treatmentPlanDetails.treatmentPlanRequestId)
            .then((resp) => {
                setCredentialData(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getPatientHistoryByPatientId = async (patientId) => {
        await getPatientHistory(patientId ? patientId : treatmentPlanDetails.patientId)
            .then((resp) => {
                setPatientHistory(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const goBack = () => {
        if (userDetails.role.includes("PRESCRIBER")) {
            lockedTreatment();
            navigate("/waiting-list")
        } else {
            navigate("/treatment-queue")
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const startVideoChat = () => {
        getCallCredentialData()
        setOpenVideoChat(true)
    };

    const getTreatmentData = (data) => {
        if (data !== "") {
            setHideShow(false)
        }
        treatmentData.push(data)
        let array = treatmentData.map((ele) => ele.treatment)
        uniqueTreatmentName = array.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    };

    const statusChange = async (value) => {
        setOpenStatusChangeDialog({ action: true, data: value })
    };

    const getCallEndData = async (data) => {
        if (data) {
            setOpenVideoChat(false)
        }
    };

    const comparedImages = async () => {
        const payload = {
            "patientId": treatmentPlanData.patientId,
            "injectorId": userDetails.role.includes("INJECTOR") ? userDetails.userId : "",
            "clinicId": treatmentPlanData.clinicId,
            "linkedTo": treatmentPlanData.treatmentPlanRequestId,
            "addToPortfolio": false,
            "before_image_link": selectedImages[0],
            "after_image_link": selectedImages[1],
        }
        await saveCompareImages(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setSelectedImages([]);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })
    };

    const submitTreatmentPlan = async () => {
        let obj = {
            treatmentPlanRequestId: treatmentPlanDetails.treatmentPlanRequestId,
            patientId: treatmentPlanDetails.patientId,
            injectorId: treatmentPlanDetails.injectorId,
            timeStamp: treatmentPlanDetails.timeStamp,
            targetAreaBefore: treatmentPlanDetails.targetAreaBefore,
            treatmentPlan: treatmentPlanDetails.treatmentPlan,
            clinicId: treatmentPlanDetails.clinicId,
            bdd_form_id: treatmentPlanDetails.bdd_form_id,
            bdd_support_image: treatmentPlanDetails.bdd_support_image
        };
        await saveTreatmentPlan(obj)
            .then((resp) => {
                changePlanStatus({ treatmentPlanRequestId: treatmentPlanDetails.treatmentPlanRequestId, "status": "PENDING" })
                    .then((res) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": "Submit successfully",
                        })
                        setTimeout(() => {
                            navigate("/treatment-queue")
                        }, 2000)
                    })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const viewConsent = () => {
        setViewConsentForm(false)
    };

    const viewPatientProfile = () => {
        setOpenPatientProfile({ action: true, patientData: treatmentPlanDetails })
    };

    const afterImages = () => {
        setOpenAfterImage({ action: true, patientData: treatmentPlanDetails, imageType: "after_image" })
    };

    const beforeImages = () => {
        setOpenAfterImage({ action: true, patientData: treatmentPlanDetails, imageType: "before_image" })
    };

    const downloadConsent = (consentFormId, fileName) => {
        downloadConsentForm(consentFormId)
            .then((res) => {
                const file = new Blob([res.data], { type: "application/pdf" })
                const fileURL = URL.createObjectURL(file)
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const selectBeforeImg = (imageKey) => {
        if (selectedImages.includes(imageKey)) {
            setSelectedImages(selectedImages.filter(key => key !== imageKey));
        } else {
            setSelectedImages([...selectedImages, imageKey]);
        }
    };

    const viewComparison = () => {
        const payload = {
            "patientId": treatmentPlanData.patientId,
        }
        getCompareImageList(payload)
            .then((resp) => {
                setCompareImages(resp.data)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Image list fetch complete",
                })
                setComparedImg(true);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const openChatBox = () => {
        setNotification(0)
        setOpenTreatmentChatBoxDialog(true);
    };

    const getMessageNotification = (message) => {
        setMessageData(message)
        setNotification((prevMessages) => prevMessages + 1)
    };

    return (
        <material.Grid spacing={2}
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <div>
                <div className="row header2">
                    <div className="col-12">
                        <span className="float-end" hidden={openVideoChat}>
                            {/* <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={openChatBox} startIcon={<material.ChatIcon />}>Open Chat Box</material.Button> */}
                            <material.IconButton title='Treatment Chat Box' onClick={openChatBox} sx={{ mr: 1 }}>
                                <material.Badge color='success' badgeContent={messageData?.userId === userDetails.userId ? 0 : notification}>
                                    <material.ChatIcon sx={{ fontSize: 30 }} color='secondary' />
                                </material.Badge>
                            </material.IconButton>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 2 }} size='small' onClick={viewPatientProfile} startIcon={<material.VisibilityIcon />}>View Patient Profile</material.Button>
                            {treatmentPlanDetails.concentFormPresent ? (
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' className='me-2' onClick={viewConsent} disabled={viewConsentForm === false}>View Consent</material.Button>
                            ) : null}
                            {treatmentPlanDetails.status === "DRAFT" ? (
                                <>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' className='ms-2' color='error' startIcon={<material.PriorityHighIcon />} onClick={() => statusChange({ "action": "delete", ...treatmentPlanDetails })}>Reject</material.Button>
                                </>
                            ) : treatmentPlanDetails.status === "PENDING" && userDetails.role.includes("PRESCRIBER") ? (
                                <>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' startIcon={<material.VideoCallIcon />} onClick={() => startVideoChat({ "action": "videoCall" })}>Call</material.Button>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' className='ms-2' color='error' startIcon={<material.PriorityHighIcon />} onClick={() => statusChange({ "action": "delete", ...treatmentPlanDetails })}>Reject</material.Button>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' className='ms-2' color='success' startIcon={<material.DoneIcon />} onClick={() => statusChange({ "action": "approve", ...treatmentPlanDetails })}>Approve</material.Button>
                                </>
                            ) : treatmentPlanDetails.status === "PENDING" && userDetails.role.includes("INJECTOR") ? (
                                <>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' color='error' startIcon={<material.CancelIcon />} onClick={() => statusChange({ "action": "cancel", ...treatmentPlanDetails })}>Cancel</material.Button>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' className='ms-2' startIcon={<material.VideoCallIcon />} onClick={startVideoChat}>Call</material.Button>
                                </>
                            ) : (treatmentPlanDetails.status === "APPROVED" || treatmentPlanDetails.status === "USED") && userDetails.role.includes("INJECTOR") ? (
                                <>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' className='me-2' onClick={beforeImages}>Before Images</material.Button>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' className='me-2' onClick={afterImages}>After Images</material.Button>
                                    <material.Button className='me-2' size="small" variant="contained" sx={{ textTransform: "none" }} startIcon={<material.VisibilityIcon />} onClick={viewComparison}>View Comparison</material.Button>
                                    {selectedImages.length === 2 && (imageList.length && afterImageList.length) ? (
                                        <material.Button className='me-2' size="small" variant="contained" sx={{ textTransform: "none" }} onClick={comparedImages}>Add To Compared Images</material.Button>
                                    ) : null}
                                </>
                            ) : null}
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' className='ms-2' onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>
                <div className='col-12' style={{ marginTop: "70px" }}>
                    <material.Paper sx={{ width: "100%", overflow: 'hidden', p: 2 }}>
                        <div className='d-flex flex-row'>
                            {hideAndShowPatientDetails ? (
                                <material.ArrowDropDownIcon onClick={() => setHideAndShowPatientDetails(false)} sx={{ cursor: "pointer" }} />
                            ) : (
                                <material.ArrowDropUpIcon onClick={() => setHideAndShowPatientDetails(true)} sx={{ cursor: "pointer" }} />
                            )}
                            <h5 className='visit-report-section-heading'>Patient Details</h5>
                        </div>
                        {hideAndShowPatientDetails && (
                            <div className='row ms-2'>
                                <div className='col-lg-4 col-md-12 col-sm-12 mt-2'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Treatment Plan ID</td>
                                                <td className='col-8'>{treatmentPlanDetails.treatmentPlanRequestId.split("_")[treatmentPlanDetails.treatmentPlanRequestId.split("_").length - 1]}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td>Time Stamp</td>
                                                <td>{changeDateAndTimeFormate(date)}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td>Patient Name</td>
                                                <td>{treatmentPlanDetails.patientName}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td>Patient Date of Birth</td>
                                                <td>{changeDateFormate(treatmentPlanDetails.patientDateOfBirth)}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td>Injector Name</td>
                                                <td>{treatmentPlanDetails.injectorName}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td>Clinic Name</td>
                                                <td>{treatmentPlanDetails.clinicName}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td>Clinic Address</td>
                                                <td>{treatmentPlanDetails.clinicAddress}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td>Status</td>
                                                <td>{treatmentPlanDetails.status === "USED" ? "FINALIS" : treatmentPlanDetails.status}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td>Treatment Notes</td>
                                                <td>{treatmentPlanDetails.treatmentNote}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td>Prescriber Notes</td>
                                                <td>{treatmentPlanDetails.prescribesNote}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-lg-8 col-md-12 col-sm-12'>
                                    <div className="row">
                                        <div className="col-6">
                                            <div>
                                                <span><material.Typography variant='h6'>Before Photo</material.Typography></span>
                                            </div>
                                            {imageList.length ? (
                                                <div className="row">
                                                    {imageList.map((ele, i) => (
                                                        <div className='col-4 mt-2' key={i}>
                                                            <material.Paper key={i} elevation={3} sx={{ width: "100%", p: 1, boxShadow: selectedImages.includes(ele) ? "0px 0px 15px 5px rgba(4, 143, 171, 1)" : "" }}>
                                                                <img
                                                                    key={i}
                                                                    src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                                                    height={150} width="100%"
                                                                    style={{ overflow: "hidden", cursor: "pointer" }}
                                                                    onClick={() => selectBeforeImg(ele)}
                                                                />
                                                            </material.Paper>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : ""}
                                        </div>
                                        <div className="col-6">
                                            <div>
                                                <span><material.Typography variant='h6'>After Photo</material.Typography></span>
                                            </div>
                                            {afterImageList.length ? (
                                                <div className="row">
                                                    {afterImageList.map((ele, i) => (
                                                        <div className='col-4 mt-2' key={i}>
                                                            <material.Paper key={i} elevation={3} sx={{ width: "100%", p: 1, boxShadow: selectedImages.includes(ele) ? "0px 0px 15px 5px rgba(4, 143, 171, 1)" : "" }}>
                                                                <img
                                                                    key={i}
                                                                    src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                                                    height={150} width="100%"
                                                                    style={{ overflow: "hidden", cursor: "pointer" }}
                                                                    onClick={() => selectBeforeImg(ele)}
                                                                />
                                                            </material.Paper>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <hr />
                        <div className='d-flex flex-row'>
                            {hideAndShowTreatmentPlan ? (
                                <material.ArrowDropDownIcon onClick={() => setHideAndShowTreatmentPlan(false)} sx={{ cursor: "pointer" }} />
                            ) : (
                                <material.ArrowDropUpIcon onClick={() => setHideAndShowTreatmentPlan(true)} sx={{ cursor: "pointer" }} />
                            )}
                            <h5 className='visit-report-section-heading'>Patient Treatment Plan</h5>
                        </div>
                        {hideAndShowTreatmentPlan && (
                            <div className="row mt-3">
                                <div className="col-12">
                                    <material.TableContainer sx={{ maxHeight: 460 }}>
                                        <material.Table stickyHeader aria-label="sticky table">
                                            <material.TableHead >
                                                <material.TableRow>
                                                    <StyledTableCell >Treatment</StyledTableCell>
                                                    <StyledTableCell >Area</StyledTableCell>
                                                    <StyledTableCell >Product</StyledTableCell>
                                                    <StyledTableCell >Qty</StyledTableCell>
                                                    {treatmentPlanDetails.status === "DRAFT" ? (
                                                        <StyledTableCell>Action</StyledTableCell>
                                                    ) : null}
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {isLoading ? (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={6}>
                                                            <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                                <Skeleton count={10} />
                                                            </SkeletonTheme>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                ) : (
                                                    <>
                                                        {treatmentPlanDetails.treatmentPlan.length ? treatmentPlanDetails.treatmentPlan.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                            <material.TableRow
                                                                key={i}
                                                                sx={{
                                                                    '&:last-child td, &:last-child th': { border: 0 }
                                                                }}
                                                            >
                                                                <material.TableCell sx={{ pt: 3, pb: 3 }} size='small' component="th" scope="row">{row.treatmentName}</material.TableCell>
                                                                <material.TableCell size='small'>{row.treatmentArea}</material.TableCell>
                                                                <material.TableCell size='small'>{row.product}</material.TableCell>
                                                                <material.TableCell size='small'>{row.qty}</material.TableCell>
                                                                {treatmentPlanDetails.status === "DRAFT" ? (
                                                                    <material.TableCell size='small'>
                                                                        <material.IconButton title='Delete Treatment' aria-label="delete" size="large">
                                                                            <material.DeleteIcon color='warning' />
                                                                        </material.IconButton>
                                                                    </material.TableCell>
                                                                ) : null}
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={6}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </>)}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                    <hr />
                                    <material.TablePagination
                                        rowsPerPageOptions={[5, 10, 20]}
                                        component="div"
                                        count={treatmentPlanDetails.treatmentPlan.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </div>
                        )}
                        {comparedImg ? (
                            <div>
                                <ComparedImageDialog
                                    compareImages={compareImages}
                                    setSelectedImages={setSelectedImages}
                                    setComparedImg={setComparedImg}
                                    setCompareImages={setCompareImages}
                                />
                            </div>
                        ) : null}
                        <div hidden={viewConsentForm} id='form-section'>
                            {treatmentPlanDetails?.consentData?.map((consent, i) =>
                                consent.concent_format === "FILE" ? (
                                    <div className='m-3'>
                                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DownloadIcon />} onClick={() => downloadConsent(consent.concent_id, consent.concent_file_name)}>{consent.concent_file_name}</material.Button>
                                    </div>
                                ) : (
                                    <div key={i}>
                                        {consent.concent_type === "Anti-Wrinkle" ? (
                                            <ConsentFormAW consentData={consent.concent_data} openConsentForm={openConsentForm} />
                                        ) : null}
                                        {consent.concent_type === "Dermal Filler" ? (
                                            <ConsentFormDF consentData={consent.concent_data} openConsentForm={openConsentForm} />
                                        ) : null}
                                        {consent.concent_type === "Skin Booster" ? (
                                            <SkinBooster consentData={consent.concent_data} openConsentForm={openConsentForm} />
                                        ) : null}
                                        {consent.concent_type === "Hyalase Treatment" ? (
                                            <HyalaseTreatment consentData={consent.concent_data} openConsentForm={openConsentForm} />
                                        ) : null}
                                        {consent.concent_type === "Local Anaesthetic" ? (
                                            <LocalAnaesthetic
                                                consentData={consent.concent_data}
                                                openConsentForm={openConsentForm}
                                                clinicDetails={clinicDetails}
                                            />
                                        ) : null}
                                        {consent.concent_type === "Threads" ? (
                                            <Threads
                                                consentData={consent.concent_data}
                                                openConsentForm={openConsentForm}
                                                clinicDetails={clinicDetails}
                                            />
                                        ) : null}
                                        {consent.concent_type === "Exomide Scalp" ? (
                                            <ExomideScalp
                                                consentData={consent.concent_data}
                                                openConsentForm={openConsentForm}
                                                clinicDetails={clinicDetails}
                                            />
                                        ) : null}
                                    </div>
                                )
                            )}
                        </div>
                        <div>
                            {blankTreatmentData.concentFormPresent && blankTreatmentData.status === "DRAFT" ? (
                                <span className="float-end mt-5 me-3">
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={submitTreatmentPlan}>Submit</material.Button>
                                </span>
                            ) : null}
                        </div>
                    </material.Paper>
                </div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
                <StatusChangeReasonDialog
                    openStatusChangeDialog={openStatusChangeDialog}
                    setOpenStatusChangeDialog={setOpenStatusChangeDialog}
                />
                {userDetails.role.includes("INJECTOR") ? (
                    <AddTreatmentPlan
                        openTreatmentPlan={openTreatmentPlan}
                        setOpenTreatmentPlan={setOpenTreatmentPlan}
                        getTreatmentData={getTreatmentData}
                    />
                ) : null}
                {/* {!openConsentForm || treatmentPlanDetails.component === "approvalWaiting" || treatmentPlanDetails.action === "videoCall" || treatmentPlanDetails.parentComponent === "waitingRoom" ? (
                    <ConsentForm
                        treatmentPlanDetails={treatmentPlanDetails}
                        openConsentForm={openConsentForm}
                        treatmentData={treatmentData}
                        blankTreatmentData={blankTreatmentData}
                        uniqueTreatmentName={uniqueTreatmentName}
                        uploadImage={uploadImage}
                        component="treatmentPlanDetails"
                    />
                ) : null} */}
                {openVideoChat ? (
                    <div className='mt-5'>
                        <VideoCalling
                            openVideoChat={openVideoChat}
                            setOpenVideoChat={setOpenVideoChat}
                            credentialData={credentialData}
                            getCallEndData={getCallEndData}
                        />
                    </div>
                ) : null}
            </div>
            <AssessedForm
                openAssessedForm={openAssessedForm}
                setOpenAssessedForm={setOpenAssessedForm}
            />
            <AddSupportingDoc
                viewDoc={viewDoc}
                setViewDoc={setViewDoc}
            />
            <ViewPatientProfile
                openPatientProfile={openPatientProfile}
                setOpenPatientProfile={setOpenPatientProfile}
            />
            <AfterImages
                openAfterImage={openAfterImage}
                setOpenAfterImage={setOpenAfterImage}
                getTreatmentPlanDataByPlanId={getTreatmentPlanDataByPlanId}
            />
            <TreatmentChatBox
                setOpenTreatmentChatBoxDialog={setOpenTreatmentChatBoxDialog}
                openTreatmentChatBoxDialog={openTreatmentChatBoxDialog}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
                treatmentPlanDetails={treatmentPlanDetails}
                getMessageNotification={getMessageNotification}
                setNotification={setNotification}
            />
        </material.Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(TreatmentPlanDetails);