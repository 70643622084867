import React, { useState } from 'react';
import { material } from '../../../library/material';
import { saveOnboardingDocument } from '../../../services/on-boarding-service/OnBoardingService';
import Snackbar from '../../toastrmessage/Snackbar';
import { useNavigate, useParams } from 'react-router-dom';

function UploadDocumentForAdmin(props) {

    const { formId } = useParams();
    const navigate = useNavigate();
    const [ahpraRegistration, setAhpraRegistration] = useState(null);
    const [insuranceDetails, setInsuranceDetails] = useState(null);
    const [additional, setAdditional] = useState(null);
    const [firstAid, setFirstAid] = useState(null);
    const [userName, setUserName] = useState("");

    const [errors, setErrors] = useState({
        userName: false,
        ahpraRegistration: false,
        insuranceDetails: false,
        firstAid: false,
        additional: false,
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const handleChangeUserName = (event) => {
        setUserName(event.target.value);
        if (event.target.value.trim()) {
            setErrors((prev) => ({ ...prev, userName: false }));
        }
    };

    const handleChangeAhpraRegistration = (event) => {
        const files = event.target.files;
        const invalidFiles = Array.from(files).filter(file => file.type !== 'application/pdf');

        if (invalidFiles.length > 0) {
            setErrors((prev) => ({ ...prev, ahpraRegistration: true }));
            setOpenSnackBar({
                action: true,
                type: "error",
                message: "Only PDF files are allowed.",
            });
            setAhpraRegistration(null); // Reset the file input
        } else {
            setAhpraRegistration(files);
            setErrors((prev) => ({ ...prev, ahpraRegistration: false }));
        }
    };

    const handleChangeInsuranceDetails = (event) => {
        const files = event.target.files;
        const invalidFiles = Array.from(files).filter(file => file.type !== 'application/pdf');

        if (invalidFiles.length > 0) {
            setErrors((prev) => ({ ...prev, insuranceDetails: true }));
            setOpenSnackBar({
                action: true,
                type: "error",
                message: "Only PDF files are allowed.",
            });
            setInsuranceDetails(null); // Reset the file input
        } else {
            setInsuranceDetails(files);
            setErrors((prev) => ({ ...prev, insuranceDetails: false }));
        }
    };

    const handleChangeFirstAid = (event) => {
        const files = event.target.files;
        const invalidFiles = Array.from(files).filter(file => file.type !== 'application/pdf');

        if (invalidFiles.length > 0) {
            setErrors((prev) => ({ ...prev, firstAid: true }));
            setOpenSnackBar({
                action: true,
                type: "error",
                message: "Only PDF files are allowed.",
            });
            setFirstAid(null); // Reset the file input
        } else {
            setFirstAid(files);
            setErrors((prev) => ({ ...prev, firstAid: false }));
        }
    };

    const handleChangeAdditional = (event) => {
        const files = event.target.files;
        const invalidFiles = Array.from(files).filter(file => file.type !== 'application/pdf');

        if (invalidFiles.length > 0) {
            setErrors((prev) => ({ ...prev, additional: true }));
            setOpenSnackBar({
                action: true,
                type: "error",
                message: "Only PDF files are allowed.",
            });
            setAdditional(null); // Reset the file input
        } else {
            setAdditional(files);
            setErrors((prev) => ({ ...prev, additional: false }));
        }
    };

    const uploadAhpraReg = () => {
        const newErrors = {
            userName: !userName.trim(),
            ahpraRegistration: !ahpraRegistration || ahpraRegistration.length === 0,
        };
        setErrors(newErrors);
        if (Object.values(newErrors).every((error) => !error)) {
            let docType = "AHPRA_REG";
            let formData = new FormData();
            for (const file of ahpraRegistration) {
                formData.append('files', file);
            }
            formData.append('formId', formId);
            formData.append('document_type', docType);
            formData.append('name', userName);
            saveOnboardingDocument(formData)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                })
        }
    };

    const uploadInsurance = () => {
        const newErrors = {
            userName: !userName.trim(),
            insuranceDetails: !insuranceDetails || insuranceDetails.length === 0,
        };
        setErrors(newErrors);
        if (Object.values(newErrors).every((error) => !error)) {
            let docType = "INSURANCE";
            let formData = new FormData();
            for (const file of ahpraRegistration) {
                formData.append('files', file);
            }
            formData.append('formId', formId);
            formData.append('document_type', docType);
            formData.append('name', userName);
            saveOnboardingDocument(formData)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                })
        }
    };

    const uploadFirstAid = () => {
        const newErrors = {
            userName: !userName.trim(),
            firstAid: !firstAid || firstAid.length === 0,
        };
        setErrors(newErrors);
        if (Object.values(newErrors).every((error) => !error)) {
            let docType = "FIRST-AID-CERTIFICATE";
            let formData = new FormData();
            for (const file of ahpraRegistration) {
                formData.append('files', file);
            }
            formData.append('formId', formId);
            formData.append('document_type', docType);
            formData.append('name', userName);
            saveOnboardingDocument(formData)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                })
        }
    };

    const uploadAdditional = () => {
        const newErrors = {
            userName: !userName.trim(),
            additional: !additional || additional.length === 0,
        };
        setErrors(newErrors);
        if (Object.values(newErrors).every((error) => !error)) {
            let docType = "ADDITIONAL";
            let formData = new FormData();
            for (const file of ahpraRegistration) {
                formData.append('files', file);
            }
            formData.append('formId', formId);
            formData.append('document_type', docType);
            formData.append('name', userName);
            saveOnboardingDocument(formData)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                })
        }
    };

    const handleClose = () => {
        navigate("/login")
    };

    return (
        <div>
            <div style={{ display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                <material.Paper elevation={3} sx={{ width: "40%", height: "auto", p: 2 }}>
                    <div className="row">
                        <material.Typography>Upload Supporting Document</material.Typography>
                        <div className='col-12 mt-3'>
                            <material.TextField
                                required
                                fullWidth
                                type='text'
                                label="Name"
                                id="name"
                                value={userName}
                                onChange={handleChangeUserName}
                                error={errors.userName}
                                helperText={errors.userName && "This field is required"}
                            />
                        </div>
                        <div className='col-12 mt-3'>
                            <material.InputLabel>AHPRA Registration</material.InputLabel>
                            <input
                                type="file"
                                multiple
                                onChange={handleChangeAhpraRegistration}
                            />
                            {errors.ahpraRegistration && (
                                <p style={{ color: "red" }}>This field is required</p>
                            )}
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.UploadIcon />} onClick={uploadAhpraReg}>
                                    Upload
                                </material.Button>
                            </span>
                        </div>
                        <div className='col-12 mt-3'>
                            <material.InputLabel>Insurance Details</material.InputLabel>
                            <input
                                type="file"
                                multiple
                                onChange={handleChangeInsuranceDetails}
                            />
                            {errors.insuranceDetails && (
                                <p style={{ color: "red" }}>This field is required</p>
                            )}
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.UploadIcon />} onClick={uploadInsurance}>
                                    Upload
                                </material.Button>
                            </span>
                        </div>
                        <div className='col-12 mt-3'>
                            <material.InputLabel>First Aid Certificate</material.InputLabel>
                            <input
                                type="file"
                                multiple
                                onChange={handleChangeFirstAid}
                            />
                            {errors.firstAid && (
                                <p style={{ color: "red" }}>This field is required</p>
                            )}
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.UploadIcon />} onClick={uploadFirstAid}>
                                    Upload
                                </material.Button>
                            </span>
                        </div>
                        <div className='col-12 mt-3'>
                            <material.InputLabel>Additional</material.InputLabel>
                            <input
                                type="file"
                                multiple
                                onChange={handleChangeAdditional}
                            />
                            {errors.additional && (
                                <p style={{ color: "red" }}>This field is required</p>
                            )}
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.UploadIcon />} onClick={uploadAdditional}>
                                    Upload
                                </material.Button>
                            </span>
                        </div>
                        <div className="col-12 mt-3">
                            <span className='float-end mx-2'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' startIcon={<material.CloseIcon />} onClick={handleClose}>
                                    Close
                                </material.Button>
                            </span>
                        </div>
                    </div>
                </material.Paper>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default UploadDocumentForAdmin;
