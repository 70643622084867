import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getInviteDetails } from '../../services/OrganisationService';
import { material } from '../../library/material';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Snackbar from '../toastrmessage/Snackbar';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { registerByInviteWithClinic } from '../../services/on-boarding-service/OnBoardingService';

const gender = ["MALE", "FEMALE", "TRANSGENDER"]

function AddPrescriberByInviteCode(props) {

    const { register, handleSubmit, reset, control, setValue, watch, getValues, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [isEnableFormField, setIsEnableFormField] = useState(true);
    const navigate = useNavigate();

    const checkInviteCode = async (event) => {
        let obj = { inviteCode: event.target.value };
        await getInviteDetails(obj)
            .then((res) => {
                setIsEnableFormField(false)
                reset({ email: res.data.email, role: res.data.role })
            }).catch((error) => {
                setIsEnableFormField(true)
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const savePrescriberDetails = (formData) => {
        registerByInviteWithClinic(formData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset();
                navigate("/login");
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "",
                })
            })
    };

    return (
        <div className='container-fluid'>
            <form >
                <material.Paper elevation={1} sx={{ p: 4 }}>
                    <div className="row">
                        <material.Typography variant='h6'>Prescriber Details</material.Typography>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.inviteCode?.type === "required"}
                                {...register("inviteCode")}
                                required
                                label="Enter Invite Code"
                                id="inviteCode"
                                variant="standard"
                                type="text"
                                onChange={checkInviteCode}
                                size="small"
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.inviteCode?.type === "required" && 'This field is required'}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Controller
                                name="gender"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <material.Autocomplete
                                        disabled={isEnableFormField}
                                        {...field}
                                        options={gender}
                                        renderInput={(params) => (
                                            <material.TextField
                                                required
                                                margin="dense"
                                                {...params}
                                                variant="standard"
                                                label="Gender"
                                                fullWidth
                                            />
                                        )}
                                        onChange={(event, data) => {
                                            field.onChange(data);
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("AHPRA_Reg_ID", { required: true })}
                                required
                                disabled={isEnableFormField}
                                margin="dense"
                                id="AHPRA_Reg_ID"
                                label="AHPRA Reg ID"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("prescriberGovtId", { required: true })}
                                required
                                disabled={isEnableFormField}
                                margin="dense"
                                id="prescriberGovtId"
                                label="Prescriber Govt ID"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("address")}
                                margin="dense"
                                disabled={isEnableFormField}
                                id="address"
                                label="Address"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                            <Controller
                                name="phone"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <PhoneInput
                                        {...field}
                                        disabled={isEnableFormField}
                                        placeholder="Phone *"
                                        defaultCountry="AU"
                                        enableSearch={true}
                                        value={field.value || ""}
                                        international
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("role", { required: true })}
                                required
                                disabled={isEnableFormField}
                                margin="dense"
                                id="role"
                                label="Role"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("password", { required: true })}
                                required
                                disabled={isEnableFormField}
                                margin="dense"
                                id="password"
                                label="Password"
                                type="password"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("email", { required: true })}
                                required
                                disabled={isEnableFormField}
                                margin="dense"
                                id="userId"
                                label="Email"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("title")}
                                required
                                disabled={isEnableFormField}
                                margin="dense"
                                id="title"
                                label="Title"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("firstName", { required: true })}
                                required
                                disabled={isEnableFormField}
                                margin="dense"
                                id="firstName"
                                label="First Name"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                {...register("lastName", { required: true })}
                                required
                                disabled={isEnableFormField}
                                margin="dense"
                                id="lastName"
                                label="Last Name"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="dateOfBirth"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <material.DatePicker
                                            label="Date of Birth *"
                                            format="DD-MM-YYYY"
                                            value={field.value || null}
                                            onChange={(newValue) => {
                                                field.onChange(moment(newValue?.$d).format("YYYY-MM-DD"));
                                            }}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    color="success"
                                                />
                                            )}
                                            disableFuture
                                            sx={{ width: '100%', mt: 1 }}
                                            slotProps={{ textField: { variant: 'standard', disabled: isEnableFormField } }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-12 mt-3">
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} color='success' disabled={isEnableFormField} onClick={handleSubmit(savePrescriberDetails)}>Save</material.Button>
                            </span>
                        </div>
                    </div>
                </material.Paper>
            </form>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddPrescriberByInviteCode;