import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { getFormDetailsClientSide, getSignedAgreementClientSide, saveAgreement, saveAgreementDetails } from '../../../services/on-boarding-service/OnBoardingService';
import SignaturePad from 'react-signature-canvas';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toastrmessage/Snackbar';

var sigPad = null;

function ConsultantAgreementDialog(props) {

    const { openViewAgreementDialog, setOpenViewAgreementDialog, getChecklistData } = props;
    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getSubmittedData, setGetSubmittedData] = useState(null);
    const [getSignatureAgreementData, setGetSignatureAgreementData] = useState(null);

    useEffect(() => {
        if (openViewAgreementDialog.action) {
            getSubmittedFormData();
            getSignedAgreementData();
        }
    }, [openViewAgreementDialog.action]);

    const getSubmittedFormData = () => {
        getFormDetailsClientSide(openViewAgreementDialog.formSubmittedData?.formId)
            .then((resp) => {
                setGetSubmittedData(resp.data);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message,
                })
            })
    };

    const getSignedAgreementData = () => {
        getSignedAgreementClientSide(openViewAgreementDialog.formSubmittedData?.formId)
            .then((resp) => {
                setGetSignatureAgreementData(resp.data);
                reset(resp.data.data.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message,
                })
            })
    };

    const handleClose = () => {
        setOpenViewAgreementDialog({ action: false, formSubmittedData: null });
    };

    const saveAgreementOwnerSide = (formData) => {
        const filledFormHTML = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>AGREEMENT FOR SCRIPT/TELEHEALTH SERVICES</title>
        </head>
        <body
            style="font-family: Arial, sans-serif; line-height: 1.6; margin: 20px;">

            <h2 style="text-align: center; color: #2C3E50;">AGREEMENT FOR
                SCRIPT/TELEHEALTH SERVICES</h2>
            <p style="text-align: center; font-size: 18px; color: #34495E;">
                This Agreement is made between:
            </p>

            <form>
                <section>
                    <p style="margin-bottom: 20px;">
                        <strong>1. Dr Sana Aesthetics Pty Ltd (ABN: 71 658 378 717,
                            referred to as "the Company"),</strong>
                        a company operating the software platform
                        "Aesthetics Consults"; and
                    </p>
                    <p style="margin-bottom: 20px;">
                        <strong>${getSubmittedData?.title} ${getSubmittedData?.name}</strong> (referred to
                        as "the Consultant"),
                        a registered medical practitioner under AHPRA
                        <strong> ${getSubmittedData?.ahpraId}</strong>
                    </p>
                </section>

                <section>
                    <h4 style="color: #16A085; margin-bottom: 10px;">1.
                        RECITALS</h4>
                    <p style="margin-bottom: 10px;">
                        <strong>A.</strong> "Aesthetics Consults" is a software
                        platform operated by the Company, designed
                        to connect patients with licensed medical practitioners for
                        telehealth consultations and scripting services.
                    </p>
                    <p style="margin-bottom: 10px;">
                        <strong>B.</strong> The Consultant agrees to provide
                        professional scripting and telehealth services using
                        the Aesthetics Consults platform.
                    </p>
                    <p style="margin-bottom: 10px;">
                        <strong>C.</strong> The Consultant acknowledges that patient
                        records will be stored and managed by
                        Aesthetics Consults in compliance with privacy laws, and the
                        Consultant is not independently responsible
                        for the storage or security of these records.
                    </p>
                    <p>
                        <strong>D.</strong> The Consultant acknowledges their sole
                        responsibility for exercising professional
                        judgment in providing medical advice, prescriptions, and
                        follow-up care to ensure patient safety.
                    </p>
                </section>

                <section>
                    <h4 style="color: #16A085; margin-bottom: 10px;">2. TERM</h4>
                    <p style="margin-bottom: 20px;">
                        This Agreement commences on [Insert Start Date, This date we
                        will choose before sending to the consultant]
                        and continues until terminated in accordance with Clause 7.
                    </p>
                </section>

                <section>
                    <h4 style="color: #16A085; margin-bottom: 10px;">3. SCOPE OF
                        SERVICES</h4>
                    <p style="margin-bottom: 10px;"><strong>The Consultant agrees
                            to:</strong></p>
                    <p style="margin-bottom: 10px;">
                        <strong>1. Telehealth Consultations:</strong>
                    </p>
                    <ul style="margin-left: 20px; list-style-type: disc;">
                        <li style="margin-bottom: 5px;">
                            Conduct telehealth consultations to assess patient
                            suitability for treatments, adhering to the
                            AHPRA Code of Conduct and applicable medical standards.
                        </li>
                        <li style="margin-bottom: 5px;">
                            Ensure informed consent is obtained for all treatments
                            and medications.
                        </li>
                    </ul>
                    <p style="margin-bottom: 10px;">
                        <strong>2. Prescription Services:</strong>
                    </p>
                    <ul style="margin-left: 20px; list-style-type: disc;">
                        <li style="margin-bottom: 5px;">
                            Prescribe medication for cosmetic and medical treatments
                            as required under Australian law and regulations.
                        </li>
                        <li style="margin-bottom: 5px;">
                            Ensure sufficient clinical justification exists before
                            prescribing any medication.
                        </li>
                    </ul>
                    <p style="margin-bottom: 10px;">
                        <strong>3. Professional Standards:</strong>
                    </p>
                    <ul style="margin-left: 20px; list-style-type: disc;">
                        <li style="margin-bottom: 5px;">
                            Comply with all relevant laws, regulations, and
                            professional obligations as a registered medical
                            practitioner.
                        </li>
                        <li style="margin-bottom: 5px;">Maintain current AHPRA
                            registration and professional indemnity insurance.</li>
                        <li style="margin-bottom: 5px;">
                            Use professional judgment to determine the
                            appropriateness of telehealth technology for patient
                            consultations.
                        </li>
                    </ul>
                    <p style="margin-bottom: 10px;">
                        <strong>4. Patient Recordkeeping:</strong>
                    </p>
                    <ul>
                        <li>
                            Input accurate and complete clinical notes and
                            prescriptions
                            into the Aesthetics Consults platform for secure
                            storage.
                        </li>
                        <li>
                            Ensure that all records meet regulatory requirements but
                            recognize that Aesthetics Consults will manage data
                            storage
                            and security.
                        </li>
                    </ul>
                    <p style="margin-bottom: 10px;">
                        <strong>5. Communication:</strong>
                    </p>
                    <ul>
                        <li>
                            Provide aftercare instructions and liaise with patients
                            to
                            address complications.
                        </li>
                        <li>
                            Respond promptly to patient inquiries and follow up as
                            clinically appropriate.
                        </li>
                    </ul>
                </section>
                <section>
                    <h4 style="color: #16A085; margin-bottom: 10px;">4.
                        IDENTIFICATION AND REGISTRATION REQUIREMENTS</h4>
                    <p style="margin-bottom: 10px;">
                        To ensure compliance with applicable laws, the Consultant
                        agrees
                        to provide the following:</p>
                    <ul>
                        <li>
                            Full Name as registered with AHPRA;
                        </li>
                        <li>
                            AHPRA Certificate;
                        </li>
                        <li>
                            Evidence of professional indemnity insurance coverage
                            (minimum $5 million per claim);
                        </li>
                        <li>
                            [If applicable] an ABN.
                        </li>
                    </ul>
                    <p style="margin-bottom: 10px;">
                        If the Consultant does not have an ABN, payments will be
                        processed
                        in accordance with the Australian Tax Office's withholding
                        tax
                        requirements.
                    </p>
                </section>
                <section>
                    <h4 style="color: #16A085; margin-bottom: 10px;">5. PAYMENT AND
                        INVOICING</h4>
                    <p style="margin-bottom: 10px;">
                        <strong>1. Rates:</strong> The Consultant will be paid
                        <strong>$15 per completed script</strong> provided through
                        the
                        Aesthetics Consults platform.
                    </p>
                    <p style="margin-bottom: 10px;">
                        <strong>2. Invoicing System:</strong>
                    </p>
                    <ul>
                        <li>
                            The Aesthetics Consults platform will
                            automatically
                            track the number of scripts provided by the
                            Consultant.
                        </li>
                        <li>
                            An invoice will be generated by the platform on
                            behalf of the Consultant at the end of each
                            billing
                            cycle, reflecting the total scripts provided
                            during
                            that period.
                        </li>
                        <li>
                            The Consultant acknowledges and agrees that the
                            generated invoice will be deemed accurate unless
                            a
                            discrepancy is reported within five (5) business
                            days of
                            the invoice being issued.
                        </li>
                    </ul>
                    <p style="margin-bottom: 10px;">
                        <strong>3. Payment Terms:</strong>
                        <ul>
                            <li>
                                Payments will be processed by the Company to
                                the
                                Consultant’s nominated bank account within
                                30 days of
                                the
                                end of each billing cycle.
                            </li>
                            <li>
                                The Consultant must ensure their bank
                                account details
                                are
                                accurate and up to date.
                            </li>
                        </ul>
                        <p style="margin-bottom: 10px;">
                            <strong>4. GST and Tax Obligations:</strong>
                        </p>
                        <ul>
                            <li>Consultants who are GST-registered will have
                                GST
                                included in the invoices generated by the
                                platform.
                                <li>
                                    If the Consultant is not GST-registered,
                                    payments
                                    will be exclusive of GST.
                                </li>
                            </ul>
                            <p style="margin-bottom: 10px;">
                                <strong> 5. Deductions:</strong> If required
                                by law, the Company
                                may
                                withhold amounts from payments, such as
                                withholding
                                tax.
                            </p>
                        </section>
                        <section>
                            <h4 style="color: #16A085; margin-bottom: 10px;">6.
                                PATIENT RECORDS AND PRIVACY</h4>
                            <p style="margin-bottom: 10px;">
                                <strong>1. Management of Records:</strong>
                            </p>
                            <ul>
                                <li>
                                    Patient records, including clinical
                                    notes, prescriptions, and follow-up
                                    details, will be securely stored by
                                    Aesthetics Consults.
                                </li>
                                <li>
                                    The Consultant is required to ensure
                                    accuracy and completeness of
                                    clinical entries but is not
                                    responsible for the storage or
                                    ongoing management of patient data.
                                </li>
                            </ul>
                            <p style="margin-bottom: 10px;">
                                <strong>2. Access to Records:</strong>
                            </p>
                            <ul>
                                <li>
                                    The Consultant will have access
                                    to patient records for lawful
                                    clinical purposes through the
                                    Aesthetics Consults platform.
                                </li>
                                <li>
                                    Patient records remain the
                                    property of the Company, and
                                    access is governed by privacy
                                    laws and the Aesthetics Consults
                                    Privacy Policy.
                                </li>
                            </ul>
                            <p style="margin-bottom: 10px;">
                                <strong>3.Confidentiality:</strong>
                            </p>
                            <ul>
                                <li>
                                    Both parties agree to
                                    maintain the confidentiality
                                    of patient information and
                                    comply with all applicable
                                    privacy laws, including the
                                    Privacy Act 1988 (Cth).
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4 style="color: #16A085; margin-bottom: 10px;">7.
                                INDEPENDENT PROFESSIONAL RESPONSIBILITY</h4>
                            <p style="margin-bottom: 10px;">
                                <strong>1. The Consultant
                                    acknowledges sole
                                    responsibility for:</strong>
                            </p>
                            <ul>
                                <li>
                                    All medical decisions,
                                    prescriptions, and clinical
                                    advice provided to patients.
                                </li>
                                <li>
                                    Ensuring that prescribed
                                    treatments are appropriate
                                    and safe for each patient.
                                </li>
                            </ul>
                            <p style="margin-bottom: 10px;">
                                <strong>2. The Company disclaims
                                    any responsibility for
                                    clinical outcomes or patient
                                    safety, acting solely as a
                                    software
                                    intermediary.</strong>
                            </p>
                        </section>
                        <section>
                            <h4 style="color: #16A085; margin-bottom: 10px;">8.
                                INDEMNITY AND INSURANCE</h4>
                            <p style="margin-bottom: 10px;">
                                <strong>1. Consultant
                                    Indemnity:</strong>
                            </p>
                            <ul>
                                <li>
                                    The Consultant indemnifies
                                    the Company against any
                                    claims, damages, or
                                    liabilities arising from
                                    their clinical actions or
                                    omissions.
                                </li>
                            </ul>
                            <p style="margin-bottom: 10px;">
                                <strong>2. Insurance:</strong>
                            </p>
                            <ul>
                                <li>

                                    The Consultant must
                                    maintain professional
                                    indemnity insurance as
                                    required by AHPRA
                                    registration and provide
                                    proof upon request.
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4 style="color: #16A085; margin-bottom: 10px;">9.
                                TERMINATION</h4>
                            <p style="margin-bottom: 10px;">
                                <strong>This Agreement may
                                    be terminated:</strong>
                            </p>
                            <p style="margin-bottom: 10px;">
                                <strong>1. By either party
                                    with 30 days' written
                                    notice.</strong>
                            </p>
                            <p style="margin-bottom: 10px;">
                                <strong>2. Immediately, if
                                    the Consultant:</strong>
                            </p>
                            <ul>
                                <li>
                                    Loses AHPRA
                                    registration;
                                </li>
                                <li>
                                    Commits misconduct
                                    affecting their ability
                                    to perform services;
                                    <li>
                                        Fails to comply with
                                        the obligations set
                                        out in this
                                        Agreement.
                                    </li>
                                </ul>
                                <p style="margin-bottom: 10px;">
                                    Upon termination, the
                                    Consultant must ensure
                                    continuity of patient
                                    care
                                    until appropriate
                                    referrals
                                    or follow-ups are
                                    arranged.
                                </p>
                            </section>
                            <section>
                                <h4 style="color: #16A085; margin-bottom: 10px;">10.
                                    RELATIONSHIP BETWEEN
                                    THE PARTIES</h4>
                                <p style="margin-bottom: 10px;">
                                    <strong>1.</strong> The
                                    Consultant is an
                                    independent
                                    contractor and not
                                    an employee, agent,
                                    or partner of the
                                    Company.
                                </p>
                                <p style="margin-bottom: 10px;">
                                    <strong>2.</strong> The
                                    Consultant is
                                    responsible for
                                    their own taxes,
                                    superannuation, and
                                    other
                                    employment-related
                                    obligations.
                                </p>
                            </section>
                            <section>
                                <h4 style="color: #16A085; margin-bottom: 10px;">11.
                                    DISPUTE
                                    RESOLUTION</h4>
                                <p style="margin-bottom: 10px;">
                                    <strong>1.</strong>
                                    Disputes will first be
                                    resolved through
                                    negotiation in good
                                    faith.
                                </p>
                                <p style="margin-bottom: 10px;">
                                    <strong>2.</strong> If
                                    unresolved, disputes
                                    will proceed to
                                    mediation before legal
                                    proceedings.
                                </p>
                                <p style="margin-bottom: 10px;">
                                    <strong>Digital
                                        Execution</strong>
                                </p>
                                <p style="margin-bottom: 10px;">
                                    By digitally signing
                                    this Agreement through
                                    the platform, both
                                    parties acknowledge and
                                    agree that such
                                    execution is binding and
                                    has the same legal
                                    effect as a handwritten
                                    signature.
                                </p>
                            </section>
                            <section>
                                <h4 style="color: #16A085; margin-bottom: 10px;">12.
                                    GENERAL
                                    PROVISIONS</h4>
                                <p style="margin-bottom: 10px;">
                                    <strong>1. Governing
                                        Law:</strong> This
                                    Agreement is governed by
                                    the laws of [Insert
                                    Jurisdiction].
                                </p>
                                <p style="margin-bottom: 10px;">
                                    <strong>2.
                                        Amendments:</strong>
                                    Amendments must be in
                                    writing and signed by
                                    both parties.
                                </p>
                                <p style="margin-bottom: 10px;">
                                    <strong>3. Entire
                                        Agreement:</strong>
                                    This document
                                    constitutes the entire
                                    agreement between the
                                    parties.
                                </p>
                            </section>
                            <section>
                                <p style="margin-bottom: 10px;">
                                    <strong>SCHEDULE 1:
                                        CONSULTANCY
                                        FEE</strong>
                                </p>
                                <ul>
                                    <li>
                                        <strong>Rate:</strong>
                                        $15 per completed
                                        script.</li>
                                </ul>
                            </section>

                            <section
                                style="display: flex; justify-content: space-between; gap: 20px;">

                                <div
                                    style="flex: 1; padding: 10px; border: 1px solid #ffffff; border-radius: 8px;">
                                    <p style="margin-bottom: 10px;">
                                        <strong>Execution</strong>
                                    </p>
                                    <p>
                                        Signed for and on behalf of Dr Sana
                                        Aesthetics Pty Ltd (ABN: 71 658 378 717):
                                    </p>
                                    <p>
                                    <strong>
                                        Name: ${formData.ownerName}
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        Signature:
                                    </strong>
                                     <img src="${sigPad.getTrimmedCanvas().toDataURL('image/png')}" alt="Owner Signature" width="400" height="100" />
                                </p>
                                </div>
                                <div style="flex: 1; padding: 10px; border: 1px solid #ffffff; border-radius: 8px;">
                                    <p style="margin-bottom: 10px;">
                                        <strong>Signed by the Consultant:</strong>
                                    </p>
                                    <p>
                                    <strong>
                                        Title: ${getSignatureAgreementData?.data?.data.title}
                                    </strong>
                                </p>
                                    <p>
                                <strong>
                                    Name: ${getSignatureAgreementData?.data?.data.name}
                            </p>
                            <p>
                                <strong>
                                    AHPRA
                                    Registration
                                    No.: ${getSignatureAgreementData?.data?.data.ahpraId}
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    Prescriber Govt ID: ${getSignatureAgreementData?.data?.data.prescriberGovtId}
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    Signature:
                                </strong>
                                    <img
                                        src="${getSignatureAgreementData?.data?.data.prescriberSignature}"
                                         alt="Nurse Signature" width="400" height="100"
                                    />
                            </p>
                                </div>
                            </section>
                        </form>
                    </body>
                </html>`;
        const payload = {
            formId: openViewAgreementDialog.formSubmittedData?.formId,
            htmlContent: filledFormHTML
        }
        if (sigPad !== null) {
            saveAgreement(payload)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    sigPad = null;
                    getChecklistData();
                    reset({});
                    setOpenViewAgreementDialog({ action: false, formSubmittedData: null });
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        }
    };

    return (
        <div>
            <material.Dialog open={openViewAgreementDialog.action} maxWidth="xl" fullWidth>
                <material.DialogTitle>
                    <span className='float-end'>
                        <material.IconButton title='Close' onClick={handleClose}>
                            <material.CloseIcon color='error' />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <h2 style={{ textAlign: "center", color: "#2C3E50" }}>AGREEMENT FOR
                        SCRIPT/TELEHEALTH SERVICES</h2>
                    <p style={{ textAlign: "center", fontSize: "18px", color: "#34495E" }}>
                        This Agreement is made between:
                    </p>

                    <form>
                        <section>
                            <p style={{ marginBottom: "20px" }}>
                                <strong>1. Dr Sana Aesthetics Pty Ltd (ABN: 71 658 378 717,
                                    referred to as "the Company"),</strong>
                                a company operating the software platform
                                "Aesthetics Consults"; and
                            </p>
                            <p style={{ marginBottom: "20px" }}>
                                <strong>{getSubmittedData?.title} {getSubmittedData?.name}</strong> (referred to
                                as "the Consultant"),
                                a registered medical practitioner under AHPRA
                                <strong> {getSubmittedData?.ahpraId}</strong>
                            </p>
                        </section>

                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>1.
                                RECITALS</h4>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>A.</strong> "Aesthetics Consults" is a software
                                platform operated by the Company, designed
                                to connect patients with licensed medical practitioners for
                                telehealth consultations and scripting services.
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>B.</strong> The Consultant agrees to provide
                                professional scripting and telehealth services using
                                the Aesthetics Consults platform.
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>C.</strong> The Consultant acknowledges that patient
                                records will be stored and managed by
                                Aesthetics Consults in compliance with privacy laws, and the
                                Consultant is not independently responsible
                                for the storage or security of these records.
                            </p>
                            <p>
                                <strong>D.</strong> The Consultant acknowledges their sole
                                responsibility for exercising professional
                                judgment in providing medical advice, prescriptions, and
                                follow-up care to ensure patient safety.
                            </p>
                        </section>

                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>2. TERM</h4>
                            <p style={{ marginBottom: "20px" }}>
                                This Agreement commences on [Insert Start Date, This date we
                                will choose before sending to the consultant]
                                and continues until terminated in accordance with Clause 7.
                            </p>
                        </section>

                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>3. SCOPE OF
                                SERVICES</h4>
                            <p style={{ marginBottom: "10px" }}><strong>The Consultant agrees
                                to:</strong></p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>1. Telehealth Consultations:</strong>
                            </p>
                            <ul style={{ marginLeft: "20px" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    Conduct telehealth consultations to assess patient
                                    suitability for treatments, adhering to the
                                    AHPRA Code of Conduct and applicable medical standards.
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    Ensure informed consent is obtained for all treatments
                                    and medications.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>2. Prescription Services:</strong>
                            </p>
                            <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    Prescribe medication for cosmetic and medical treatments
                                    as required under Australian law and regulations.
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    Ensure sufficient clinical justification exists before
                                    prescribing any medication.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>3. Professional Standards:</strong>
                            </p>
                            <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    Comply with all relevant laws, regulations, and
                                    professional obligations as a registered medical
                                    practitioner.
                                </li>
                                <li style={{ marginBottom: "5px" }}>Maintain current AHPRA
                                    registration and professional indemnity insurance.</li>
                                <li style={{ marginBottom: "5px" }}>
                                    Use professional judgment to determine the
                                    appropriateness of telehealth technology for patient
                                    consultations.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>4. Patient Recordkeeping:</strong>
                            </p>
                            <ul>
                                <li>
                                    Input accurate and complete clinical notes and
                                    prescriptions
                                    into the Aesthetics Consults platform for secure
                                    storage.
                                </li>
                                <li>
                                    Ensure that all records meet regulatory requirements but
                                    recognize that Aesthetics Consults will manage data
                                    storage
                                    and security.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>5. Communication:</strong>
                            </p>
                            <ul>
                                <li>
                                    Provide aftercare instructions and liaise with patients
                                    to
                                    address complications.
                                </li>
                                <li>
                                    Respond promptly to patient inquiries and follow up as
                                    clinically appropriate.
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>4.
                                IDENTIFICATION AND REGISTRATION REQUIREMENTS</h4>
                            <p style={{ marginBottom: "10px" }}>
                                To ensure compliance with applicable laws, the Consultant
                                agrees
                                to provide the following:</p>
                            <ul>
                                <li>
                                    Full Name as registered with AHPRA;
                                </li>
                                <li>
                                    AHPRA Certificate;
                                </li>
                                <li>
                                    Evidence of professional indemnity insurance coverage
                                    (minimum $5 million per claim);
                                </li>
                                <li>
                                    [If applicable] an ABN.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                If the Consultant does not have an ABN, payments will be
                                processed
                                in accordance with the Australian Tax Office's withholding
                                tax
                                requirements.
                            </p>
                        </section>
                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>5. PAYMENT AND
                                INVOICING</h4>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>1. Rates:</strong> The Consultant will be paid
                                <strong>$15 per completed script</strong> provided through
                                the
                                Aesthetics Consults platform.
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>2. Invoicing System:</strong>
                            </p>
                            <ul>
                                <li>
                                    The Aesthetics Consults platform will
                                    automatically
                                    track the number of scripts provided by the
                                    Consultant.
                                </li>
                                <li>
                                    An invoice will be generated by the platform on
                                    behalf of the Consultant at the end of each
                                    billing
                                    cycle, reflecting the total scripts provided
                                    during
                                    that period.
                                </li>
                                <li>
                                    The Consultant acknowledges and agrees that the
                                    generated invoice will be deemed accurate unless
                                    a
                                    discrepancy is reported within five (5) business
                                    days of
                                    the invoice being issued.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>3. Payment Terms:</strong>
                                <ul>
                                    <li>
                                        Payments will be processed by the Company to
                                        the
                                        Consultant’s nominated bank account within
                                        30 days of
                                        the
                                        end of each billing cycle.
                                    </li>
                                    <li>
                                        The Consultant must ensure their bank
                                        account details
                                        are
                                        accurate and up to date.
                                    </li>
                                </ul>
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>4. GST and Tax Obligations:</strong>
                            </p>
                            <ul>
                                <li>Consultants who are GST-registered will have
                                    GST
                                    included in the invoices generated by the
                                    platform.
                                </li>
                                <li>
                                    If the Consultant is not GST-registered,
                                    payments
                                    will be exclusive of GST.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong> 5. Deductions:</strong> If required
                                by law, the Company
                                may
                                withhold amounts from payments, such as
                                withholding
                                tax.
                            </p>
                        </section>
                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>6.
                                PATIENT RECORDS AND PRIVACY</h4>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>1. Management of Records:</strong>
                            </p>
                            <ul>
                                <li>
                                    Patient records, including clinical
                                    notes, prescriptions, and follow-up
                                    details, will be securely stored by
                                    Aesthetics Consults.
                                </li>
                                <li>
                                    The Consultant is required to ensure
                                    accuracy and completeness of
                                    clinical entries but is not
                                    responsible for the storage or
                                    ongoing management of patient data.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>2. Access to Records:</strong>
                            </p>
                            <ul>
                                <li>
                                    The Consultant will have access
                                    to patient records for lawful
                                    clinical purposes through the
                                    Aesthetics Consults platform.
                                </li>
                                <li>
                                    Patient records remain the
                                    property of the Company, and
                                    access is governed by privacy
                                    laws and the Aesthetics Consults
                                    Privacy Policy.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>3.Confidentiality:</strong>
                            </p>
                            <ul>
                                <li>
                                    Both parties agree to
                                    maintain the confidentiality
                                    of patient information and
                                    comply with all applicable
                                    privacy laws, including the
                                    Privacy Act 1988 (Cth).
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>7.
                                INDEPENDENT PROFESSIONAL RESPONSIBILITY</h4>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>1. The Consultant
                                    acknowledges sole
                                    responsibility for:</strong>
                            </p>
                            <ul>
                                <li>
                                    All medical decisions,
                                    prescriptions, and clinical
                                    advice provided to patients.
                                </li>
                                <li>
                                    Ensuring that prescribed
                                    treatments are appropriate
                                    and safe for each patient.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>2. The Company disclaims
                                    any responsibility for
                                    clinical outcomes or patient
                                    safety, acting solely as a
                                    software
                                    intermediary.</strong>
                            </p>
                        </section>
                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>8.
                                INDEMNITY AND INSURANCE</h4>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>1. Consultant
                                    Indemnity:</strong>
                            </p>
                            <ul>
                                <li>
                                    The Consultant indemnifies
                                    the Company against any
                                    claims, damages, or
                                    liabilities arising from
                                    their clinical actions or
                                    omissions.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>2. Insurance:</strong>
                            </p>
                            <ul>
                                <li>

                                    The Consultant must
                                    maintain professional
                                    indemnity insurance as
                                    required by AHPRA
                                    registration and provide
                                    proof upon request.
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>9.
                                TERMINATION</h4>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>This Agreement may
                                    be terminated:</strong>
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>1. By either party
                                    with 30 days' written
                                    notice.</strong>
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>2. Immediately, if
                                    the Consultant:</strong>
                            </p>
                            <ul>
                                <li>
                                    Loses AHPRA
                                    registration;
                                </li>
                                <li>
                                    Commits misconduct
                                    affecting their ability
                                    to perform services;
                                </li>
                                <li>
                                    Fails to comply with
                                    the obligations set
                                    out in this
                                    Agreement.
                                </li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>
                                Upon termination, the
                                Consultant must ensure
                                continuity of patient
                                care
                                until appropriate
                                referrals
                                or follow-ups are
                                arranged.
                            </p>
                        </section>
                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>10.
                                RELATIONSHIP BETWEEN
                                THE PARTIES</h4>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>1.</strong> The
                                Consultant is an
                                independent
                                contractor and not
                                an employee, agent,
                                or partner of the
                                Company.
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>2.</strong> The
                                Consultant is
                                responsible for
                                their own taxes,
                                superannuation, and
                                other
                                employment-related
                                obligations.
                            </p>
                        </section>
                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>11.
                                DISPUTE
                                RESOLUTION</h4>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>1.</strong>
                                Disputes will first be
                                resolved through
                                negotiation in good
                                faith.
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>2.</strong> If
                                unresolved, disputes
                                will proceed to
                                mediation before legal
                                proceedings.
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>Digital
                                    Execution</strong>
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                By digitally signing
                                this Agreement through
                                the platform, both
                                parties acknowledge and
                                agree that such
                                execution is binding and
                                has the same legal
                                effect as a handwritten
                                signature.
                            </p>
                        </section>
                        <section>
                            <h4 style={{ color: "#16A085", marginBottom: "10px" }}>12.
                                GENERAL
                                PROVISIONS</h4>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>1. Governing
                                    Law:</strong> This
                                Agreement is governed by
                                the laws of [Insert
                                Jurisdiction].
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>2.
                                    Amendments:</strong>
                                Amendments must be in
                                writing and signed by
                                both parties.
                            </p>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>3. Entire
                                    Agreement:</strong>
                                This document
                                constitutes the entire
                                agreement between the
                                parties.
                            </p>
                        </section>
                        <section>
                            <p style={{ marginBottom: "10px" }}>
                                <strong>SCHEDULE 1:
                                    CONSULTANCY
                                    FEE</strong>
                            </p>
                            <ul>
                                <li>
                                    <strong>Rate:</strong>
                                    $15 per completed
                                    script.</li>
                            </ul>
                        </section>

                        <section style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                            <div style={{ flex: 1, padding: "10px", border: "1px solid #050505", borderRadius: "8px" }}>
                                <p style={{ marginBottom: "10px" }}>
                                    <strong>Execution</strong>
                                </p>
                                <p>
                                    Signed for and on behalf of Dr Sana
                                    Aesthetics Pty Ltd (ABN: 71 658 378 717):
                                </p>
                                <p>
                                    <strong>
                                        Name:
                                        <material.TextField
                                            type='text'
                                            id='name'
                                            variant='standard'
                                            sx={{ mb: 1, ml: 1, width: "30ch" }}
                                            {...register("ownerName", { required: true })}
                                        />
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        Signature:
                                    </strong>
                                    <SignaturePad penColor='black'
                                        canvasProps={{ height: 100, className: 'signaturePad' }}
                                        ref={(ref) => { sigPad = ref }}
                                    />
                                </p>
                            </div>
                            <div style={{ flex: 1, padding: "10px", border: "1px solid #050505", borderRadius: "8px" }}>
                                <p style={{ marginBottom: "10px" }}>
                                    <strong>Signed by the Consultant:</strong>
                                </p>
                                <p>
                                    <strong>
                                        Title:
                                        <material.TextField
                                            type='text'
                                            id='title'
                                            variant='standard'
                                            sx={{ mb: 1, ml: 1, width: "30ch" }}
                                            {...register("title")}
                                        />
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        Name:
                                    </strong>
                                    <material.TextField
                                        InputProps={{ readOnly: true }}
                                        type='text'
                                        id='name'
                                        variant='standard'
                                        sx={{ mb: 1, ml: 1, width: "30ch" }}
                                        {...register("name")}
                                    />
                                </p>
                                <p>
                                    <strong>
                                        AHPRA
                                        Registration
                                        No.:
                                        <material.TextField
                                            type='text'
                                            id='AHPRA_id'
                                            variant='standard'
                                            InputProps={{ readOnly: true }}
                                            sx={{ ml: 1, width: "30ch" }}
                                            {...register("ahpraId")}
                                        />
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        Prescriber Govt ID:
                                        <material.TextField
                                            InputProps={{ readOnly: true }}
                                            type='text'
                                            id='prescriberGovtId'
                                            variant='standard'
                                            sx={{ ml: 1, width: "30ch" }}
                                            {...register("prescriberGovtId")}
                                        />
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        Signature:
                                    </strong>
                                    <img
                                        src={getSignatureAgreementData?.data?.data.prescriberSignature}
                                    />
                                </p>
                            </div>
                        </section>
                    </form>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant='contained' sx={{ textTransform: "none" }} onClick={handleSubmit(saveAgreementOwnerSide)} color='success'>
                        Save
                    </material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ConsultantAgreementDialog;