import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { material } from '../../../library/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveOnboardingDocument } from '../../../services/on-boarding-service/OnBoardingService';
import Snackbar from '../../toastrmessage/Snackbar';


function DocumentUpload(props) {
    const { register } = useForm({ mode: "onTouched" });
    const navigate = useNavigate();
    const location = useLocation();
    const formDetails = location.state?.formDetails;

    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [errors, setErrors] = useState({});
    const [selectedFiles, setSelectedFiles] = useState({});

    const fields = formDetails?.role === "PRESCRIBER"
        ? [
            { name: "ahpraRegistration", label: "AHPRA Registration", docType: "AHPRA_REG" },
            { name: "insuranceDetails", label: "Insurance Details", docType: "INSURANCE" },
            { name: "abn", label: "[If applicable] an ABN", docType: "ABN" },
            { name: "additional", label: "Additional", docType: "ADDITIONAL" },
        ]
        : formDetails?.role === "ADMIN"
            ? [
                { name: "ahpraRegistration", label: "AHPRA Registration", docType: "AHPRA_REG" },
                { name: "insuranceDetails", label: "Insurance Details", docType: "INSURANCE" },
                { name: "firstAid", label: "First Aid Certificate", docType: "FIRST_AID" },
                { name: "additional", label: "Additional", docType: "ADDITIONAL" },
            ]
            : [];

    const validateFile = (files) => {
        if (!files || files.length === 0) {
            return "This field is required.";
        }

        if (!Array.from(files).every((file) => file.type === "application/pdf")) {
            return "Only PDF files are allowed.";
        }
        return null;
    };

    const handleFileChange = (fieldName, files) => {
        const error = validateFile(files);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: error,
        }));

        if (!error) {
            setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                [fieldName]: files,
            }));
        }
    };

    const handleFileUpload = (fieldName) => {
        const files = selectedFiles[fieldName];
        const error = validateFile(files);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: error,
        }));
        if (error) {
            return;
        }
        const field = fields.find((f) => f.name === fieldName);
        const docType = field?.docType;

        const formData = new FormData();
        Array.from(files).forEach((file) => {
            formData.append("files", file);
        });
        formData.append("document_type", docType);
        formData.append("formId", formDetails.formId);

        saveOnboardingDocument(formData)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data,
                });
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data?.message,
                });
            });
    };

    return (
        <div>
            <div style={{ display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: "center", }} >
                <material.Paper elevation={3} sx={{ width: "40%", height: "auto", p: 2 }}>
                    <form>
                        {fields.map((field) => (
                            <div className="row" key={field.name}>
                                <div className="col-9 mt-3">
                                    <span>
                                        <material.InputLabel>{field.label}</material.InputLabel>
                                        <input
                                            type="file"
                                            multiple
                                            {...register(field.name)}
                                            name={field.name}
                                            onChange={(e) => handleFileChange(field.name, e.target.files)}
                                        />
                                        {errors[field.name] && (
                                            <p style={{ color: "red", fontSize: "0.9rem" }}>
                                                {errors[field.name]}
                                            </p>
                                        )}
                                    </span>
                                </div>
                                <div className="col-3 mt-3">
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.UploadIcon />}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleFileUpload(field.name);
                                        }} >
                                        Upload
                                    </material.Button>
                                </div>
                            </div>
                        ))}
                        <div className="col-12 mt-3">
                            <span className="float-end">
                                <material.Button
                                    variant="contained"
                                    color="error"
                                    sx={{ textTransform: "none" }}
                                    onClick={() => navigate("/login")}
                                >
                                    Close
                                </material.Button>
                            </span>
                        </div>
                    </form>
                </material.Paper>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}


export default DocumentUpload;