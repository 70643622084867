import React, { useEffect, useState, useRef } from 'react';
import Webcam from 'react-webcam';
import { material } from '../../../library/material';
import { connect } from 'react-redux';
import { uploadSkinBeforeImage, uploadSkinBeforeImageFile } from '../../../services/SkinTreatmentService';
import Snackbar from '../../toastrmessage/Snackbar';
import SkinTreatmentNote from '../../dialog/SkinTreatmentNote';

function AddAndUploadPhotoDialog(props) {

    const { openPhotoUploadDialog, setOpenPhotoUploadDialog, userData, clinicData, getImageList, callFrom, setOpenConsentForm } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [facingMode, setFacingMode] = useState("environment");
    const [error, setError] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [buttonHideShow, setButtonHideShow] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openPermissionDialog, setOpenPermissionDialog] = useState({ action: false, patientData: null });
    const [clickType, setClickType] = useState(null);
    const [selectFolder, setSelectFolder] = useState([]);

    useEffect(() => {
        if (openPermissionDialog.action && clickType == "click_photo") {
            startCamera()
        }
    }, [facingMode, openPermissionDialog.action == true, clickType == "click_photo"]);

    const startCamera = async () => {
        try {
            if (videoRef.current && videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }

            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: facingMode },
            });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (err) {
            setError("Camera access was denied or not available.");
            console.error("Error accessing camera:", err);
        }
    };

    const handleCloseCamera = async () => {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        videoRef.current.srcObject = null;
    };

    const handleSwitchCamera = async () => {
        setFacingMode((prevMode) => (prevMode == "environment" ? "user" : "environment"));
    };

    const handleCapture = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        if (video && canvas) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageData = canvas.toDataURL("image/png");
            setCapturedImage(imageData);
            savePhoto(imageData)
        }
        handleCloseCamera();
    };

    const addPhoto = (type) => {
        setClickType(type);
        setButtonHideShow(true);
        startCamera();
    };

    const selectPhoto = (type) => {
        setClickType(type);
    };

    const chooseFile = (e) => {
        let fileName = e.target.files;
        setSelectFolder(fileName);
    };

    const cancel = () => {
        setButtonHideShow(false);
        setSelectFolder([]);
        setCapturedImage(null);
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
        if (callFrom === "skin_treatment_details") {
            setOpenPhotoUploadDialog({ action: false, treatmentData: null });
        } else {
            setOpenPhotoUploadDialog({ action: false, treatmentData: null });
            setOpenConsentForm({ action: false, patientData: null });
        }
    };

    const savePhoto = async (image) => {
        startCamera();
        let obj = {
            'image': image,
            'orgId': userDetails.orgId,
            'clinicId': clinicDetails.clinicId,
            'sessionId': openPhotoUploadDialog.treatmentData.skinTreatmentSessionId,
            patientId: openPhotoUploadDialog.treatmentData.patientId,
        }
        await uploadSkinBeforeImage(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getImageList()
                // if (callFrom === "skin_treatment_details") {
                //     getImageList()
                // }
                setButtonHideShow(false);
                setCapturedImage(null);
                setSelectFolder([]);
                // setClickType(null);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const uploadFile = () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait...",
        })
        let data = new FormData();
        for (const file of selectFolder) {
            data.append('images', file);
        }
        data.append('sessionId', openPhotoUploadDialog.treatmentData.skinTreatmentSessionId,);
        data.append('patientId', openPhotoUploadDialog.treatmentData.patientId);
        data.append('orgId', userDetails.orgId);
        data.append('clinicId', clinicDetails.clinicId);
        uploadSkinBeforeImageFile(data)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                if (callFrom === "skin_treatment_details") {
                    getImageList()
                }
                setClickType(null);
                setSelectFolder([]);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const nextFlow = () => {
        setButtonHideShow(false);
        setOpenPermissionDialog({ action: true, patientData: openPhotoUploadDialog.treatmentData });
        setClickType(null);
        setSelectFolder([]);
    };

    return (
        <div>
            <material.Dialog open={openPhotoUploadDialog.action} fullWidth maxWidth="md" hideBackdrop>
                <material.DialogTitle>Add Photo
                    <span hidden={buttonHideShow}>
                        <material.IconButton color='primary' title='Add Photo' onClick={() => addPhoto("click_photo")}>
                            <material.AddAPhotoIcon />
                        </material.IconButton>
                        <material.IconButton color='primary' title='Upload Photo' sx={{ ml: 1 }} onClick={() => selectPhoto("upload_file")}>
                            <material.UploadFileIcon />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                {clickType === "upload_file" ? (
                    <material.DialogContent>
                        <input
                            margin="dense"
                            id="image-upload"
                            label="Choose Photo"
                            accept=".jpg, .jpeg, .png"
                            type="file"
                            onChange={chooseFile}
                            multiple
                        />
                    </material.DialogContent>
                ) : clickType === "click_photo" ? (
                    <material.DialogContent>
                        {error ? (
                            <p style={{ color: "red" }}>{error}</p>
                        ) : (
                            <>
                                {/* {capturedImage ? (
                                    <div style={{ marginTop: "20px" }}>
                                        <span className='d-flex justify-content-center'>
                                            <img
                                                src={capturedImage}
                                                alt="Captured"
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "500px",
                                                    borderRadius: "8px",
                                                    border: "2px solid #ccc",
                                                    marginTop: "10px",
                                                }}
                                            />
                                        </span>
                                    </div>
                                ) : ( */}
                                    <div>
                                        <span className='d-flex justify-content-center'>
                                            <video
                                                ref={videoRef}
                                                autoPlay
                                                playsInline
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "500px",
                                                    borderRadius: "8px",
                                                    border: "2px solid #ccc",
                                                }}
                                            ></video>
                                            <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
                                        </span>
                                        <span className='d-flex justify-content-center mt-2'>
                                            <material.Button startIcon={<material.CameraswitchIcon />} size="small" variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={handleSwitchCamera}>
                                                Switch Camera
                                            </material.Button>
                                            <material.Button startIcon={<material.CameraAltIcon />} size="small" variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={handleCapture}>
                                                Capture
                                            </material.Button>
                                        </span>
                                    </div>
                                {/* )} */}
                            </>
                        )}
                    </material.DialogContent>
                ) : ""}
                <material.DialogActions sx={{ m: 1 }}>
                    <material.Button sx={{ textTransform: "none", mr: 1 }} size='small' color='error' variant="outlined" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {(capturedImage !== null || selectFolder.length) ? (
                        <span>
                            {clickType === "upload_file" ? (
                                <material.Button sx={{ textTransform: "none" }} size='small' variant="outlined" onClick={uploadFile} startIcon={<material.UploadFileIcon />}>Upload File</material.Button>
                            ) : null
                                // (
                                //     <material.Button sx={{ textTransform: "none" }} size='small' variant="outlined" onClick={savePhoto} startIcon={<material.UploadIcon />}>Upload Photo</material.Button>
                                // )
                            }
                        </span>
                    ) : null}
                    <material.Button sx={{ textTransform: "none" }} size='small' variant="outlined" onClick={nextFlow} hidden={callFrom === "skin_treatment_details"}>Next</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <PermissionDialog
                callFrom="Add Photo"
                setOpenConsentForm={setOpenConsentForm}
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
                openPermissionDialog={openPermissionDialog}
                setOpenPermissionDialog={setOpenPermissionDialog}
            />
        </div>
    );
}

const PermissionDialog = (props) => {

    const { setOpenConsentForm, setOpenPhotoUploadDialog, setOpenPermissionDialog, openPermissionDialog } = props;
    const [addSkinTreatmentNots, setAddSkinTreatmentNots] = useState({ action: false, patientData: null });

    const reAssessment = (data) => {
        if (data === "yes") {
            setAddSkinTreatmentNots({ action: true, patientData: openPermissionDialog.patientData });
        } else {
            setOpenPermissionDialog({ action: false, patientData: null });
        };
    };

    return (
        <div>
            <material.Dialog maxWidth="auto" hideBackdrop open={openPermissionDialog.action}>
                <material.DialogContent className='d-flex flex-colum'>
                    Begin your treatment and record the session's settings. For multiple settings, simply press enter to move to the next line.
                    <span>
                        <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} color='error' size="medium" onClick={() => reAssessment("no")} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} size="medium" onClick={() => reAssessment("yes")}>Start</material.Button>
                    </span>
                </material.DialogContent>
            </material.Dialog>
            <SkinTreatmentNote
                addSkinTreatmentNots={addSkinTreatmentNots}
                setAddSkinTreatmentNots={setAddSkinTreatmentNots}
                callFrom="Add Photo"
                setOpenConsentForm={setOpenConsentForm}
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddAndUploadPhotoDialog);