import React, { useEffect, useState } from 'react';
import Navbar from '../../navbar/Navbar';
import { material } from '../../../library/material';
import { getSOPData } from '../../../services/ProtocolService';
import Snackbar from '../../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { useTheme } from '@mui/material';

function ViewProtocol(props) {

    const { userData, clinicData } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const userDetails = userData.authReducer.data;
    const theme = useTheme();
    const [getProtocolData, setProtocolData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);

    localStorage.setItem("menuName", "View Protocol");

    useEffect(() => {
        getProtocol()
    }, []);

    const getProtocol = (payloadData) => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId,
            search: payloadData ? payloadData.search : "",
            limit: payloadData ? payloadData.limit : 10,
            page: payloadData ? payloadData.page : 0
        }
        getSOPData(payload)
            .then((resp) => {
                setProtocolData(resp.data.results);
                setPage(Number(resp.data.currentPage));
                setRowsPerPage(resp.data.limit);
                setTotalElements(resp.data.totalItems);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const searchProtocol = (value) => {
        const payload = {
            search: value,
            limit: 10,
            page: 0,
        }
        getProtocol(payload)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getProtocol(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getProtocol(payload)
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <material.Paper elevation={3} sx={{ p: 3 }}>
                <div className="row">
                    <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="col-12">
                            <span style={{ marginLeft: 5 }}>
                                <material.TextField
                                    fullWidth
                                    variant="standard"
                                    label="Search Protocol"
                                    sx={{ width: "40ch" }}
                                    onChange={(e) => searchProtocol(e.target.value)}
                                />
                            </span>
                        </div>
                        <div className="col-12 mt-3">
                            <material.TableContainer sx={{ maxHeight: "70vh" }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead>
                                        <material.TableRow>
                                            <StyledTableCell>Treatment Name</StyledTableCell>
                                            <StyledTableCell sx={{ width: "80%" }}>Protocol Details</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {getProtocolData.length ? getProtocolData.map((item, k) => (
                                            <material.TableRow key={k}>
                                                <material.TableCell>{item.forTreatment}</material.TableCell>
                                                <material.TableCell>{<div dangerouslySetInnerHTML={{ __html: item.sopDetails }} />}</material.TableCell>
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow>
                                                <material.TableCell colSpan={10}>
                                                    <h6 className="d-flex justify-content-center text-danger fw-bold">
                                                        No data found
                                                    </h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={totalElements}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </material.Grid>
                </div>
            </material.Paper>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(ViewProtocol);