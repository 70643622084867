import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { getDocumentList } from '../../../services/on-boarding-service/OnBoardingService';
import Snackbar from '../../toastrmessage/Snackbar';
import ViewPdf from './ViewPdf';

function ViewUploadedDocument(props) {

    const { openViewUploadedDocument, setOpenViewUploadedDocument } = props;
    const [documentList, setDocumentList] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openViewPdf, setOpenViewPdf] = useState({ action: false, pdfName: null });

    useEffect(() => {
        if (openViewUploadedDocument.action) {
            getUploadDocumentList()
        }
    }, [openViewUploadedDocument.action]);

    const getUploadDocumentList = () => {
        getDocumentList(openViewUploadedDocument?.formSubmittedData?.formId)
            .then((resp) => {
                setDocumentList(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "",
                })
            })
    };

    const handleClick = (document) => {
        setOpenViewPdf({ action: true, pdfName: document })
    };

    const handleClose = () => {
        setOpenViewUploadedDocument({ action: false, formSubmittedData: null })
    };

    return (
        <div>
            <material.Dialog open={openViewUploadedDocument.action} fullWidth maxWidth="lg">
                <material.DialogTitle>View Document List
                    <span className='float-end'>
                        <material.IconButton title='Close' onClick={handleClose}>
                            <material.CloseIcon color='error' />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        {documentList.length ? documentList.map((document, k) => (
                            <div className="col-3 mt-2" key={k}>
                                <material.Card sx={{ height: 150, boxShadow: "0px 0px 15px 5px rgba(72, 198, 223, 0.3)" }}>
                                    <material.CardContent sx={{ height: 150 }}>
                                        <material.CardActionArea onClick={() => handleClick(document)} sx={{ height: 120 }}>
                                            <material.Typography sx={{ width: 250, wordWrap: "break-word" }}>{document.split("/").pop()}</material.Typography>
                                        </material.CardActionArea>
                                    </material.CardContent>
                                </material.Card>
                            </div>
                        )) : null}
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ViewPdf
                openViewPdf={openViewPdf}
                setOpenViewPdf={setOpenViewPdf}
                callForm="view_upload_document"
            />
        </div>
    );
}

export default ViewUploadedDocument;