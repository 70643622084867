import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../../../navbar/Navbar';
import { material } from '../../../../library/material';
import { useTheme } from '@mui/material';
import { connect } from 'react-redux';
import { getAllPAtients } from '../../../../services/PatientService';
import { StyledTableCell } from '../../../../shared/TableHeaderStyle';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import ViewCurrentOfferList from '../../clinic-config/corrent-offers/ViewCurrentOfferList';
import { getPackageInvoiceByPatientId, sendPackageInvoice, viewPackageInvoice } from '../../../../services/PackageConfigurationService';
import Snackbar from '../../../toastrmessage/Snackbar';
import moment from 'moment';

function PackageInvoice(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    localStorage.setItem("menuName", "Package Invoice")
    const theme = useTheme();
    const [patientsData, setPatientsData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [invoiceData, setInvoiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openViewCurrentOfferList, setOpenViewCurrentOfferList] = useState(false);
    const [patientName, setPatientName] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const iframeRef = useRef(null);

    useEffect(() => {
        getAllPatientListByClinicId()
    }, []);

    useEffect(() => {
        if (iframeRef.current && selectedInvoice) {
            const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(selectedInvoice);
            iframeDoc.close();
        }
    }, [selectedInvoice]);

    const getAllPatientListByClinicId = async () => {
        await getAllPAtients(clinicDetails.clinicId)
            .then((res) => {
                const name = res.data?.map((x) => x.name);
                setPatientName(name);
                setPatientsData(res.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const selectPatient = (e, value) => {
        const getPatientId = patientsData?.filter((x) => x.name == value)[0];
        getInvoiceByPatientId(getPatientId.patientId);
        setSelectedInvoice(null);
    };

    const getInvoiceByPatientId = (patientId) => {
        getPackageInvoiceByPatientId(patientId)
            .then((resp) => {
                setInvoiceData(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const viewCurrentOffer = () => {
        setOpenViewCurrentOfferList(true)
    };

    const sendInvoice = (rowData) => {
        sendPackageInvoice(rowData.invoiceId)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const viewInvoice = (rowData) => {
        viewPackageInvoice(rowData.invoiceId)
            .then((resp) => {
                // const newTab = window.open("", "_blank")
                // newTab.document.write(resp.data)
                setSelectedInvoice(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const handlePrintClick = () => {
        if (iframeRef.current) {
            // Get the iframe document and print it
            iframeRef.current.contentWindow.focus();
            iframeRef.current.contentWindow.print();
        }
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className='row'>
                <div className='col-4'>
                    <material.Autocomplete
                        id="name"
                        margin="dense"
                        onChange={selectPatient}
                        options={patientName}
                        renderInput={(params) => <material.TextField {...params} required variant="standard" label="Patient Name"
                        />}
                    />
                </div>
                <div className="col-8">
                    <span className='float-end '>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} className='me-2' onClick={viewCurrentOffer} startIcon={<material.VisibilityIcon />}>View Current Offer</material.Button>
                    </span>
                </div>
                <div className="col-6">
                    <material.Paper className='p-4 pb-5 mt-3' elevation={3}>
                        <material.TableContainer sx={{ maxHeight: 585 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Invoice Id</StyledTableCell>
                                        <StyledTableCell>Package Name</StyledTableCell>
                                        <StyledTableCell>Created At</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {invoiceData.length ? invoiceData.map((rowData, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell>{rowData.invoiceId}</material.TableCell>
                                                    <material.TableCell>{rowData.packageName}</material.TableCell>
                                                    <material.TableCell>{rowData.createdAt ? moment(rowData.createdAt).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell>
                                                        <material.IconButton title='View Invoice' aria-label="create" size="small" onClick={() => viewInvoice(rowData)}>
                                                            <material.VisibilityIcon color='secondary' sx={{ fontSize: 20 }} />
                                                        </material.IconButton>
                                                        <material.IconButton title='Send Invoice' aria-label="create" size="small" onClick={() => sendInvoice(rowData)}>
                                                            <material.SendIcon color='primary' sx={{ fontSize: 20 }} />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                    </material.Paper>
                </div>
                <div className="col-6">
                    <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                        {selectedInvoice ? (
                            <div>
                                <iframe
                                    ref={iframeRef}
                                    title="Invoice Viewer"
                                    style={{
                                        width: "100%",
                                        height: "70vh",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                        backgroundColor: "#f9f9f9",
                                    }}
                                ></iframe>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handlePrintClick}>
                                    Print
                                </material.Button>
                            </div>
                        ) : (
                            <p>Select an invoice to view its details.</p>
                        )}
                    </material.Paper>
                </div>
            </div>
            <ViewCurrentOfferList
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                openViewCurrentOfferList={openViewCurrentOfferList}
                setOpenViewCurrentOfferList={setOpenViewCurrentOfferList}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(PackageInvoice);